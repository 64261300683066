import * as React from 'react'
import './styles.scss'

export interface AppViewProps {
  column?: boolean
  gray?: boolean
  children?: React.ReactNode
}

export const AppView: React.FC<AppViewProps> = (props: AppViewProps) => (
  <div className={`app-view ${props.column && 'column'} ${props.gray && 'bg-light'}`}>
    {props.children}
  </div>
)

interface AppViewWrapperProps {
  children?: React.ReactNode
}

export const AppViewWrapper: React.FC = (props: AppViewWrapperProps) => (
  <div className='app-view-wrapper'>{props.children}</div>
)

export interface AppMainViewProps {
  id?: string
  innerRef?: React.Ref<any>
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const AppMainView = (props: AppMainViewProps) => {
  const mainStyle = {
    paddingBottom: '3.5rem', // to accomodate the Hubspot Chatbot icon which is bigger than our previous one
    ...props.style,
  }

  return (
    <div ref={props.innerRef} id='app-main-view' className={props.className} style={mainStyle}>
      {props.children}
    </div>
  )
}
