import * as React from 'react'
import { Select } from 'antd-v4'
import { SelectProps, SelectValue } from 'antd-v4/lib/select'
import { filterOptionV4 } from '../../utilities/helpers'

export const BlazeSelectFilter = <T extends SelectValue>(
  props: React.PropsWithChildren<SelectProps<T>>,
) => {
  const { children, style } = props

  return (
    <Select<T>
      className='antd-override-select'
      dropdownMatchSelectWidth={false}
      filterOption={filterOptionV4}
      maxTagCount={0}
      maxTagTextLength={16}
      showSearch
      showArrow
      style={{ ...style }}
      dropdownStyle={{ maxWidth: 600 }}
      {...props}
    >
      {children}
    </Select>
  )
}
