import * as React from 'react'
import { POSStore } from '../../../stores/POSStore'
import { Routes } from '../../../constants/Routes'
import { API } from '@getgreenline/homi-shared'
import { Form, Input, Icon, Button, message, Row, Col, Tooltip } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { useEffect, useState } from 'react'
import { parseErrorMsg } from '../../../utilities/helpers'
import { setRootAuthToken } from '../../../utilities/environment'

const DEVICE_ID = 'deviceId'
const PASSWORD = 'password'

export const LoginFormPOS = (props: FormComponentProps) => {
  const { getFieldDecorator } = props.form

  const [isLoading, setIsLoading] = useState(false)
  const [authPOSDeviceName, setAuthPOSDeviceName] = useState<string>()

  useEffect(() => {
    checkExistingSession()
  }, [])

  const checkExistingSession = async () => {
    const deviceAuthToken = window.localStorage.getItem('deviceAuthToken')

    if (deviceAuthToken) {
      setRootAuthToken(deviceAuthToken)
      const device = await API.getCurrentDevice()
      setAuthPOSDeviceName(device.deviceId)
    }
  }

  const deviceLogin = async (deviceId: string, password: string) => {
    setIsLoading(true)
    try {
      const device = await POSStore.loginDevice(deviceId, password)

      // Better to use react router programmatic routing
      window.location.href = Routes.POS_SALE()
    } catch (error) {
      setIsLoading(false)
      message.error(parseErrorMsg(error) || 'There was an error logging in')
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { form } = props

    form.validateFields((err, values) => {
      // Error states already handled here
      if (!err) {
        const deviceId: string = form.getFieldValue(DEVICE_ID)
        const password: string = form.getFieldValue(PASSWORD)
        deviceLogin(deviceId, password)
      }
    })
  }

  return (
    <Form onSubmit={handleSubmit}>
      {authPOSDeviceName && (
        <Form.Item>
          <Button
            size='large'
            onClick={() => (window.location.href = Routes.POS_SALE())}
            style={{
              color: 'rgba(0,0,0,.65)',
              borderColor: '#d9d9d9',
            }}
            className='continue-session-btn'
          >
            <Icon type='user' />
            <span className='mr-4'>{authPOSDeviceName}</span>
            <div>
              <em className='small'>continue active session</em>
            </div>
          </Button>
        </Form.Item>
      )}

      {/* Used to prevent auto complete form the browser */}
      <input style={{ display: 'none' }} type='text' name='preventAutocomplete' />
      <input style={{ display: 'none' }} type='password' name='preventAutocomplete' />

      <Form.Item style={{ marginBottom: '1rem' }}>
        {getFieldDecorator(DEVICE_ID, {
          rules: [{ required: true, message: 'Please enter your device id' }],
        })(
          <Input
            size='large'
            prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder='Device ID'
            extra='Hello'
          />,
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator(PASSWORD, {
          rules: [{ required: true, message: 'Please enter your password' }],
        })(
          <Input.Password
            size='large'
            prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder='Password'
          />,
        )}
      </Form.Item>
      <Form.Item>
        <Row type='flex' justify='space-between'>
          <Col>
            <Tooltip title='This can be changed in Dashboard > Settings > POS > Device - change password'>
              <span className='btn-link'>Forgot device password?</span>
            </Tooltip>
          </Col>
          <Col>
            <Button type='primary' htmlType='submit' disabled={isLoading} loading={isLoading}>
              Log in
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
}

export const WrappedLoginFormPOS = Form.create<FormComponentProps>()(LoginFormPOS)
