import React, { FC } from 'react'
import { SingleSpaParcel } from './SingleSpaParcel'
import { mfeConfigs } from '../../utilities/mfe'
import { uploadPaymentOptionImage } from '../../utilities/helpers'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { LocalStorage } from '../../utilities/LocalStorage'
import { useEvents } from '../../utilities/hooks/useEvents'

interface IAdminPaymentPageProps {
  currentCompanyStore: CurrentCompanyStore
}

const _AdminPaymentPage: FC<IAdminPaymentPageProps> = ({ currentCompanyStore }) => {
  useEvents({ currentCompanyStore })

  return (
    <SingleSpaParcel
      basename='/admin/v2/payments'
      config={mfeConfigs.LOAD_PAYMENT_PAGE}
      authToken={LocalStorage.getAuthToken}
      mode='admin'
      platform='pos'
      services={{
        imageUploadService: {
          upload: async (file: any) => {
            const uploadUrlDetails = await currentCompanyStore.getSignedAdminImageUploadPublicUrl()
            return await uploadPaymentOptionImage(file, uploadUrlDetails)
          },
        },
      }}
    />
  )
}

export const AdminPaymentPage = inject('currentCompanyStore')(observer(_AdminPaymentPage))
