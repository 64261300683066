import * as React from 'react'
import { observer } from 'mobx-react'
import { RegisterModel } from './RegisterModel'
import { BLAZE_COMPANY } from '../../constants/general'

interface Props {
  registerModel: RegisterModel
}

@observer
export class Email extends React.Component<Props, {}> {
  componentDidMount() {
    this.focusInput()
  }

  focusInput() {
    const inputs = document.getElementsByTagName('input')
    if (inputs.length > 0) {
      inputs[0].focus()
    }
  }

  render() {
    const registerModel = this.props.registerModel

    return (
      <div className='animated fadeInRight'>
        <h3>Welcome to {BLAZE_COMPANY}!</h3>
        <p className='text-muted'>Let's get your account set up.</p>
        <div className='form-group'>
          <div className='form-group'>
            <input
              type='text'
              className='form-control form-control-lg'
              placeholder='Your name'
              value={registerModel.name}
              onChange={(e) => (registerModel.name = e.target.value)}
            />
          </div>
          <input
            type='email'
            className='form-control form-control-lg'
            placeholder='Your email'
            value={registerModel.email}
            onChange={(e) => (registerModel.email = e.target.value)}
          />
        </div>
      </div>
    )
  }
}
