import { IReactionDisposer, reaction } from 'mobx'
import { amplify } from '../../../appsync/graphql/api'
import { ConnectionState } from '@aws-amplify/api-graphql'
import { IAmplifyConnectionState } from '../../../appsync/amplify/interfaces'
import { BlazepayConstants } from '@getgreenline/payments'

export class AppSyncEventService {
  protected setDisposer(
    disposer: IReactionDisposer | undefined,
    effect: (arg: IAmplifyConnectionState) => void,
  ) {
    disposer?.()
    disposer = reaction(() => amplify.connectionState, effect)
    return disposer
  }

  protected parseMerchantId(merchantId: string) {
    const [system, companyId, locationId] = merchantId.split('-')
    return {
      system,
      companyId: parseInt(companyId),
      locationId: parseInt(locationId),
    }
  }

  protected justConnected(connectionState: IAmplifyConnectionState) {
    return (
      connectionState.previous !== ConnectionState.Connected &&
      connectionState.current === ConnectionState.Connected
    )
  }

  protected async fetchRecentData(
    connectionState: IAmplifyConnectionState,
    keepDataUpToDate?: VoidFunction,
  ) {
    console.log(`appsync state: ${connectionState.previous} -> ${connectionState.current}`)

    if (this.justConnected(connectionState)) {
      await keepDataUpToDate?.()
    }
  }

  protected deserializeData<T>(data: any | any[]): T {
    if (Array.isArray(data)) {
      return data.map((item) => this.deserializeDataItem(item)) as T
    } else {
      return this.deserializeDataItem(data)
    }
  }

  private deserializeDataItem<T>(item: any): T {
    if (!item['type']?.endsWith(BlazepayConstants.EVENT_SUFFIX)) return item

    if (item['attributes'].extraContent) {
      item['attributes'].extraContent = JSON.parse(item['attributes'].extraContent)
    }

    return item
  }
}
