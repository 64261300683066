export enum OrderedExportDataFields {
  AMOUNT = 'Amount',
  BRAND = 'Brand',
  AVERAGE_CART = 'Average cart',
  CATEGORY = 'Category',
  COST = 'Cost',
  DATE = 'Date',
  DEPOSITS = 'Deposits',
  DISCOUNTS = 'Discounts',
  GIFT_CARD_DISCOUNTS = 'Gift card discounts',
  GIFT_CARD_REFUNDS = 'Gift card refunds',
  GIFT_CARDS = 'Gift cards',
  GROSS_SALES = 'Gross sales',
  LOCATION = 'Location',
  MARGIN = 'Margin',
  NET_SALES = 'Net sales',
  NUMBER_OF_SALES = 'Number of sales',
  ONLINE_ORDER_DISCREPANCIES = 'Online order discrepancies',
  PARENT_CATEGORY = 'Parent category',
  PARENT_PRODUCT = 'Parent product',
  PENNY_ADJUSTMENT = 'Penny adjustment',
  PRODUCT = 'Product',
  PROFIT = 'Profit',
  QUANTITY = 'Quantity',
  REFUNDS = 'Refunds',
  SKU = 'SKU',
  TAXES = 'Taxes',
  TOTAL = 'Total',
  VENDOR = 'Vendor',
}
