import * as React from 'react'
import { DragDropWrapper } from './DragDropWrapper'
import { DropResult, ResponderProvided } from 'react-beautiful-dnd'
import { observer } from 'mobx-react'

interface IRequiredArrayItem {
  indexOrder: number
}

interface Props<T extends IRequiredArrayItem> {
  array: T[]
  children(props: T, index: number): JSX.Element
  onAfterDrop: (orderedArray: T[]) => void
}

export const DragDrop = observer(<T extends IRequiredArrayItem>(props: Props<T>) => {
  const { array, onAfterDrop, children } = props

  // https://dev.to/milandhar/drag-and-drop-table-with-react-beautiful-dnd-54ad
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { destination, source, reason } = result

    if (!destination || reason === 'CANCEL') {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const reorderedAvailablePermissions: T[] = array

    const sourcePermission = reorderedAvailablePermissions[source.index]

    reorderedAvailablePermissions.splice(source.index, 1)
    reorderedAvailablePermissions.splice(destination.index, 0, sourcePermission)

    if (source.index < destination.index) {
      for (let i = source.index; i <= destination.index; i++) {
        reorderedAvailablePermissions[i].indexOrder = i
      }
    } else {
      for (let i = destination.index; i <= source.index; i++) {
        reorderedAvailablePermissions[i].indexOrder = i
      }
    }

    onAfterDrop(reorderedAvailablePermissions)
  }

  return (
    <DragDropWrapper onDragEnd={onDragEnd}>
      {array.map((item, index) => children(item, index))}
    </DragDropWrapper>
  )
})
