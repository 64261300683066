import React, { ReactElement, useState } from 'react'
import { ILocation, API, IUpdateLocation, ICompany } from '@getgreenline/homi-shared'
import { Input, Button, message, Row, Col } from 'antd'

interface Props {
  company: ICompany
  location: ILocation
}

function HeadsetLocation({ location, company }: Props): ReactElement {
  const [apiKey, setApiKey] = useState(location.headsetApiKey || '')
  const [dataKey, setDataKey] = useState(location.headsetDataKey || '')
  const [isLoading, setIsLoading] = useState(false)

  const save = async () => {
    try {
      setIsLoading(true)
      const update: IUpdateLocation = {
        id: location.id,
        headsetDataKey: dataKey,
        headsetApiKey: apiKey,
      }
      const updatedLocation = await API.updateLocation(company.id, location.id, update, false)
      setApiKey(updatedLocation.headsetApiKey || '')
      setDataKey(updatedLocation.headsetDataKey || '')
      message.success(`Successfully updated ${location.name}`)
    } catch (error) {
      message.error('Unable to update')
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='mb-2' key={location.id}>
      <div>{location.name}</div>
      <Row gutter={16}>
        <Col span={10}>
          <Input
            onChange={(event) => setApiKey(event.target.value)}
            addonBefore='API Key'
            disabled={isLoading}
            value={apiKey}
            placeholder='ThisThenThat'
          />
        </Col>
        <Col span={10}>
          <Input
            onChange={(event) => setDataKey(event.target.value)}
            addonBefore='Data Key'
            disabled={isLoading}
            value={dataKey}
            placeholder='123456'
          />
        </Col>
        <Col span={4}>
          <Button loading={isLoading} disabled={isLoading} onClick={save}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default HeadsetLocation
