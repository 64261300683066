import React, { FC, useEffect, useMemo, useState } from 'react'
import { ICompany } from '@getgreenline/homi-shared'
import * as ocs from '@getgreenline/ocs'
import { Alert, Button, Table, Dropdown, Menu } from 'antd-v4'
import { DatePicker } from 'antd' // v4 datepicker is straight up broken
import Title from 'antd-v4/lib/typography/Title'
import { ColumnsType } from 'antd-v4/lib/table'
import { DownOutlined } from '@ant-design/icons'
import Endpoints from '../../../../constants/Endpoints'
import { parseErrorMsg } from '../../../../utilities/helpers'
import { dayjs } from '../../../../utilities/dayjs'
import { usePaginatedQuery } from '../../../../utilities/hooks/usePaginatedQuery'

interface ITableDataSource {
  key: number
  date: string
  type: string
  status: string
  ocsResponse: string
}

type TTableColumns = ColumnsType<ITableDataSource>

interface IProps {
  company: ICompany
  crsaHashKeyMap: Map<string, string>
}

export const OcsSubmissionAttemptsTable: FC<IProps> = ({ company, crsaHashKeyMap }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number | undefined>(1)
  const [selectedCRSA, setSelectedCRSA] = useState<string>(crsaHashKeyMap.keys().next().value)
  const [selectedType, setSelectedType] = useState<
    | ocs.OCSReportEntryType.SUBMISSION_EVENT_ATTEMPT
    | ocs.OCSReportEntryType.SUBMISSION_POSITION_ATTEMPT
  >(ocs.OCSReportEntryType.SUBMISSION_EVENT_ATTEMPT)
  const [selectedDate, setSelectedDate] = useState<string | undefined>()
  const [submissionAttempts, setSubmissionAttempts] = useState<
    ocs.IGetSubmissionAttemptResponseContract['items']
  >([])
  const hashKey = crsaHashKeyMap.get(selectedCRSA)
  if (!selectedCRSA || !hashKey) return null
  const pageSize = 5
  const pagesToFetch = 10
  const dataSource: ITableDataSource[] = useMemo(() => {
    return submissionAttempts.map(({ type, status, ocsResponse, rangeEnd }, i) => ({
      key: i,
      date:
        selectedType === ocs.OCSReportEntryType.SUBMISSION_EVENT_ATTEMPT
          ? dayjs(rangeEnd).utc().format('MMM DD, YYYY h:00a - h:mma')
          : dayjs(rangeEnd).utc().format('MMM DD, YYYY'),
      type: type.replace(/_/g, ' '),
      status,
      ocsResponse:
        typeof ocsResponse.data === 'string' ? ocsResponse.data : ocsResponse.data?.referenceId,
    }))
  }, [submissionAttempts])

  const columns: TTableColumns = [
    {
      title: 'Inventory Submission Range (UTC)',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'OCS Response',
      dataIndex: 'ocsResponse',
      key: 'ocsResponse',
      ellipsis: true,
    },
  ]

  const getSubmissionAttemptsPaginated = usePaginatedQuery(({ exclusiveStartKeySk }) =>
    ocs.getSubmissionAttempts(
      {
        gateway: Endpoints.AUTHZ_GATEWAY_URL,
        tenantId: `${company.id}`,
        hashKey,
      },
      {
        type: selectedType,
        rangeEnd: selectedDate,
        limit: pageSize * pagesToFetch,
        exclusiveStartKeySk,
      },
    ),
  )

  const fetchSubmissionAttempts = async (shouldForceRefresh?: boolean) => {
    setIsLoading(true)
    try {
      const res = await getSubmissionAttemptsPaginated({
        currentPage,
        pageSize,
        shouldForceRefresh,
      })
      if (!res) return
      setSubmissionAttempts(shouldForceRefresh ? res.items : [...submissionAttempts, ...res.items])
      return res
    } catch (error) {
      console.error(error)
      setErrorMessage(
        parseErrorMsg(error) || 'An error occurred while fetching OCS submission attempts',
      )
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setErrorMessage(null)
    fetchSubmissionAttempts(true)
  }, [selectedCRSA, selectedType, selectedDate])

  useEffect(() => {
    if (currentPage === 1) return
    fetchSubmissionAttempts()
  }, [currentPage])

  return (
    <div className='background-shadow p-4'>
      <Title level={4}>OCS Submission Attempts Log</Title>
      <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            {Array.from(crsaHashKeyMap.keys()).map((crsa) => (
              <Menu.Item
                key={crsa}
                onClick={() => setSelectedCRSA(crsa)}
                hidden={selectedCRSA === crsa}
              >
                {crsa.replace('CRSA', 'CRSA ')}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Button>
          {selectedCRSA.replace('CRSA', 'CRSA ')}
          <DownOutlined />
        </Button>
      </Dropdown>
      <Dropdown
        className='m-2 w-50'
        trigger={['click']}
        overlay={
          <Menu>
            <Menu.Item
              key={ocs.OCSReportEntryType.SUBMISSION_EVENT_ATTEMPT}
              onClick={() => setSelectedType(ocs.OCSReportEntryType.SUBMISSION_EVENT_ATTEMPT)}
              hidden={selectedType === ocs.OCSReportEntryType.SUBMISSION_EVENT_ATTEMPT}
            >
              submission event attempt
            </Menu.Item>
            <Menu.Item
              key={ocs.OCSReportEntryType.SUBMISSION_POSITION_ATTEMPT}
              onClick={() => setSelectedType(ocs.OCSReportEntryType.SUBMISSION_POSITION_ATTEMPT)}
              hidden={selectedType === ocs.OCSReportEntryType.SUBMISSION_POSITION_ATTEMPT}
            >
              submission position attempt
            </Menu.Item>
          </Menu>
        }
      >
        <Button>
          {selectedType.replace(/_/g, ' ')}
          <DownOutlined />
        </Button>
      </Dropdown>
      <DatePicker
        onChange={(date) => setSelectedDate(date ? date.format('YYYY-MM-DD') : undefined)}
        disabledDate={(current) => !!current && current.toDate() > new Date()}
      />
      <Table
        loading={isLoading}
        dataSource={dataSource}
        columns={columns}
        onChange={({ current }) => setCurrentPage(current)}
        pagination={{
          position: ['topRight', 'bottomRight'],
          pageSize,
          showSizeChanger: false,
        }}
        style={{ zIndex: 0 }}
      ></Table>
      {errorMessage && (
        <Alert message={errorMessage} type='error' showIcon={true} closable={true} />
      )}
    </div>
  )
}
