import * as React from 'react'
import { Divider } from 'antd'
import { ProvinceProductModels } from '@getgreenline/products'

interface Props {
  updateProducts: ProvinceProductModels.IProductDiff[]
}

export function ProvinceProductsUpdated({ updateProducts }: Props) {
  return (
    <div>
      <h1>Updated products ({updateProducts.length})</h1>

      {updateProducts.map((product) => (
        <div className='mt-1' key={product.product.sku}>
          {`${product.product.masterProductName} - ${product.product.name}`}

          {product.diff.map((diff) => (
            <div className='mt-1' key={diff.fieldName}>
              <div className='ml-1' style={{ flex: 1 }}>
                Field name: {diff.fieldName}
              </div>
              <div className='ml-1' style={{ flex: 1 }}>
                Old value: {diff.oldValue}
              </div>
              <div className='ml-1' style={{ flex: 1 }}>
                New value: {diff.newValue}
              </div>
            </div>
          ))}
          <Divider />
        </div>
      ))}
    </div>
  )
}
