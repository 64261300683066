import * as React from 'react'
import { AppView, AppMainView } from '../../../components/AppView'
import { Row } from 'antd-v4'
import { BlazeButtons } from './BlazeButtons'

export const StyleGuidelines = () => {
  return (
    <AppView column>
      <AppMainView className='background-white'>
        <Row gutter={12}>
          <BlazeButtons />
        </Row>
      </AppMainView>
    </AppView>
  )
}
