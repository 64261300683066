import React, { useState } from 'react'
import { UploadFile } from 'antd/lib/upload/interface'
import { Modal, Select } from 'antd'

import { GlobalCatalogUploadDropzone } from './GlobalCatalogUploadDropzone'
import { CompaniesModels } from '@getgreenline/companies'

const { Option } = Select

interface Props {
  provinces: { name: string; value: CompaniesModels.Province }[]
  onModalClose: () => void
  onFileDrop: (selectedProvince: CompaniesModels.Province, file: UploadFile) => void
}

export function GlobalCatalogUploadModal({ provinces, onModalClose, onFileDrop }: Props) {
  const [province, setProvince] = useState<CompaniesModels.Province | null>(null)

  return (
    <Modal
      title='Upload Change Set'
      visible={true}
      maskClosable={false}
      width={620}
      onCancel={onModalClose}
    >
      {!province ? (
        <div>
          <Select<CompaniesModels.Province>
            placeholder='Select a province'
            onChange={(value) => setProvince(value)}
            style={{ width: '50%' }}
          >
            {provinces.map((province, index) => (
              <Option key={`${province.value}-${index}`} value={`${province.value}`}>
                {province.name}
              </Option>
            ))}
          </Select>
        </div>
      ) : (
        <GlobalCatalogUploadDropzone onDrop={(file) => onFileDrop(province, file)} />
      )}
    </Modal>
  )
}
