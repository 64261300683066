import { IQuota } from '@getgreenline/marketing'
import { THEME_COLORS } from '@getgreenline/shared'
import { Col, Progress, Row, Typography } from 'antd-v4'
import React, { FC, ReactNode } from 'react'

interface ICardProps {
  title: string
  content: string | number | ReactNode
}

const InfoCard: FC<ICardProps> = ({ title, content }: ICardProps) => {
  return (
    <Col
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '120px',
        marginRight: '12px',
      }}
    >
      <Row
        style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {['string', 'number'].includes(typeof content) ? (
          <Typography
            style={{
              fontSize: '28px',
              color: THEME_COLORS.MONO.mono55,
            }}
          >
            {content}
          </Typography>
        ) : (
          content
        )}
      </Row>
      <Row>
        <Typography style={{ marginTop: '20px', color: THEME_COLORS.MONO.mono55 }}>
          {title}
        </Typography>
      </Row>
    </Col>
  )
}

interface IProps {
  smsQuota: IQuota
}

export const InfoCards: FC<IProps> = ({ smsQuota }: IProps) => {
  const smsUsage =
    (smsQuota.monthlyReplenishRate - smsQuota.quantity) / smsQuota.monthlyReplenishRate
  const smsUsagePercentage = +(smsUsage * 100).toFixed(1)
  return (
    <>
      <InfoCard
        title='SMS usage'
        content={<Progress type='circle' percent={smsUsagePercentage} width={56} status='normal' />}
      />
      <InfoCard title='Remaining messages' content={smsQuota.quantity} />
      <InfoCard title='Replenish rate' content={smsQuota.monthlyReplenishRate} />
    </>
  )
}
