import React from 'react'
import { Result } from 'antd'
import { PermissionNames } from '@getgreenline/homi-shared'

interface NoPermissionResultProps {
  permissionName?: PermissionNames
  extra?: React.ReactNode
}

export function NoPermissionsResult(props: NoPermissionResultProps) {
  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      <Result
        status='403'
        title='No permissions'
        subTitle={
          props.permissionName ? (
            <div>
              <span>Your account does not have the "{props.permissionName}" permission.</span>
              <br />
              <span>Please ask your administrator for access.</span>
            </div>
          ) : (
            'You do not have permissions to access this page'
          )
        }
        extra={props.extra}
      />
    </div>
  )
}
