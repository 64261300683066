import React from 'react'
import { PageHeader, Button } from 'antd'
import { browserHistory } from 'react-router'
import { sentryWrapper } from '../../../utilities/logging'

export function ProvinceProductsHeader() {
  return (
    <PageHeader
      title='Province products'
      onBack={() => browserHistory.push('admin')}
      extra={[
        <Button
          key='search'
          type='primary'
          icon='search'
          onClick={() => browserHistory.push('/admin/provinceProducts/search')}
        >
          Search
        </Button>,
        <Button
          key='export'
          type='primary'
          icon='export'
          onClick={() => browserHistory.push('/admin/provinceProducts/export')}
        >
          Export
        </Button>,
        <Button
          key='import'
          type='primary'
          icon='import'
          onClick={() => browserHistory.push('/admin/provinceProducts/import')}
        >
          Import
        </Button>,
        <Button
          key='sentry-test'
          hidden
          onClick={() => {
            sentryWrapper.captureException('sentry test', {
              tags: { extraTag: 'extraTag' },
              extra: { extraExtra: 'extraExtra' },
            })
          }}
        >
          Test Sentry
        </Button>,
      ]}
    />
  )
}
