import * as React from 'react'
import './Deck.scss'

export class Deck extends React.Component<{}, {}> {
  render() {
    return (
      <div id='deck'>
        <iframe
          src='https://docs.google.com/presentation/d/1egsqyHR6dTZbtU_lGQj84LS1e1iT_aIGHL3jMk8KOr4/embed?start=true&loop=false&delayms=60000'
          width='100%'
          height='720'
          name='deck'
        ></iframe>
      </div>
    )
  }
}
