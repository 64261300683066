import * as React from 'react'
import { Divider } from 'antd'
import { ProvinceProductModels } from '@getgreenline/products'

interface Props {
  productsToAdd: ProvinceProductModels.IProvinceProductContract[]
  title: string
}

export function ProvinceProductsList({ title, productsToAdd }: Props) {
  return (
    <div>
      <h1>
        {title} ({productsToAdd.length})
      </h1>

      {productsToAdd.map((product, index) => (
        <div key={product.sku}>
          {Object.keys(product).map((key, secondIndex) => (
            <div style={{ flex: 1 }} key={`${index}${secondIndex}`}>
              {key}: {product[key]}
            </div>
          ))}
          <Divider />
        </div>
      ))}
    </div>
  )
}
