import * as React from 'react'
import { Typography, Row, Col } from 'antd-v4'
import { BlazeButton, TBlazeButtonType } from '../../../components/CustomButtons/BlazeButton'
import { Table } from 'antd'
import { ButtonProps } from 'antd/lib/button'

const dataSource = ({
  buttonName,
  blazeButtonType,
  buttonProps,
}: {
  buttonName?: string
  blazeButtonType?: TBlazeButtonType
  buttonProps: ButtonProps
}) => {
  return [
    {
      buttonName,
      normal: (
        <BlazeButton
          blazeButtonType={blazeButtonType}
          buttonProps={buttonProps}
          titleProps={{
            title: 'Button Copy',
          }}
        />
      ),
      disabled: (
        <BlazeButton
          blazeButtonType={blazeButtonType}
          buttonProps={{
            ...buttonProps,
            disabled: true,
          }}
          titleProps={{
            title: 'Button Copy',
          }}
        />
      ),
      loading: (
        <BlazeButton
          blazeButtonType={blazeButtonType}
          buttonProps={{
            ...buttonProps,
            loading: true,
          }}
          titleProps={{
            title: 'Button Copy',
          }}
        />
      ),
    },
  ]
}

export const BlazeButtons = () => {
  const titleLevel = 3
  const colSpan = 8

  const typeColumn = { title: 'Type', dataIndex: 'buttonName', key: 'buttonName' }

  const columns = [
    { title: 'Normal', dataIndex: 'normal', key: 'normal' },
    { title: 'Disabled', dataIndex: 'disabled', key: 'disabled' },
    { title: 'Loading', dataIndex: 'loading', key: 'loading' },
  ]

  return (
    <>
      <Row>
        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Filled Buttons (large)</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonName: 'Retail',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'retail',
              }),
              ...dataSource({
                buttonName: 'Dispatch',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonName: 'BlazePay',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonName: 'Manufacturing',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonName: 'Grow',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonName: 'Distro',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonName: 'Danger',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonName: 'Black',
                buttonProps: {
                  size: 'large',
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white w-100'}
            columns={[typeColumn, ...columns]}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>

        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Outlined Buttons (large) </Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'default',
                },
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  ghost: true,
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>

        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Text Buttons (large)</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'retail',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'large',
                  type: 'link',
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Filled Buttons</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonName: 'Retail',
                buttonProps: {},
                blazeButtonType: 'retail',
              }),
              ...dataSource({
                buttonName: 'Dispatch',
                buttonProps: {},
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonName: 'BlazePay',
                buttonProps: {},
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonName: 'Manufacturing',
                buttonProps: {},
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonName: 'Grow',
                buttonProps: {},
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonName: 'Distro',
                buttonProps: {},
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonName: 'Danger',
                buttonProps: {},
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonName: 'Black',
                buttonProps: {},
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={[typeColumn, ...columns]}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>

        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Outlined Buttons</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonProps: {
                  type: 'default',
                },
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonProps: {
                  ghost: true,
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>

        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Text Buttons</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'retail',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Filled Buttons (small)</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonName: 'Retail',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'retail',
              }),
              ...dataSource({
                buttonName: 'Dispatch',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonName: 'BlazePay',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonName: 'Manufacturing',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonName: 'Grow',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonName: 'Distro',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonName: 'Danger',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonName: 'Black',
                buttonProps: {
                  size: 'small',
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={[typeColumn, ...columns]}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>

        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Outlined Buttons (small)</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonProps: {
                  type: 'default',
                  size: 'small',
                },
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonProps: {
                  size: 'small',
                  ghost: true,
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>

        <Col span={colSpan}>
          <Typography.Title level={titleLevel}>Text Buttons (small)</Typography.Title>

          <Table
            dataSource={[
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'retail',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'dispatch',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'blazepay',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'manufacturing',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'grow',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'distro',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'danger',
              }),
              ...dataSource({
                buttonProps: {
                  type: 'link',
                  size: 'small',
                },
                blazeButtonType: 'black',
              }),
            ]}
            className='no-background-table show-horizontal-scrollbar'
            rowClassName={() => 'background-white'}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
          />
        </Col>
      </Row>
    </>
  )
}
