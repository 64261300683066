import * as React from 'react'

interface Props {
  centValue?: number | null
  className?: string
  style?: React.CSSProperties
}

export class Currency extends React.Component<Props> {
  get dollarValue(): number | null {
    const { centValue } = this.props

    if (centValue === null || centValue === undefined) {
      return null
    }

    return centValue / 100
  }

  get isDollarValueNegative(): boolean {
    if (this.dollarValue === null) {
      return false
    }

    return this.dollarValue < 0
  }

  get stringDollar(): string {
    if (this.dollarValue === null) {
      return '-'
    }

    let positiveStringDollarAmount = Math.abs(this.dollarValue).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })

    positiveStringDollarAmount = `$${positiveStringDollarAmount}`

    return this.isDollarValueNegative
      ? `-${positiveStringDollarAmount}`
      : positiveStringDollarAmount
  }

  render() {
    return (
      <span className={this.props.className} style={this.props.style}>
        {this.stringDollar}
      </span>
    )
  }
}
