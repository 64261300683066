import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types'
import { message } from 'antd-v4'

// https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/context/
class SentryWrapper {
  captureException = (message: string, context?: CaptureContext) => {
    Sentry.captureException(new Error(message), context)
  }
}

export function captureError(
  errorMessage: string,
  error: any,
  notifyUser = true,
  timeout?: number,
) {
  sentryWrapper.captureException(error)

  notifyUser && message.error(errorMessage, timeout)
}

export const sentryWrapper = new SentryWrapper()
