export enum ExternalLinks {
  FORM_ONLINE_ORDERING_WAITLIST = 'https://forms.gle/JcW6amsY23w2Qy3k8',
  SMS_SIGNUP = 'https://docs.google.com/forms/d/e/1FAIpQLSfynw5YGCZr8Hb08JZLJoUSLQG6HAk2-R4YCSgANnBs6sHGYw/viewform',
  GREENLINE_CAPITAL = 'https://getgreenline.co/greenline-capital/',
  BLAZEPAY_DASHBOARD = 'https://meetings.hubspot.com/blaze-canada/blazepay-meeting-round-robin',
}

export enum HelpLinks {
  ADDING_FIRST_INVENTORY = 'https://help.blaze.me/can/home/adding-your-first-inventory-blaze-help-center',
  ADDING_INVENTORY_VIA_AUDIT = 'https://help.blaze.me/can/home/adding-inventory-via-an-audit',
  AUDITS = 'https://help.blaze.me/can/home/inventory-audits',
  BIRCHMOUNT_GIFT_CARDS = 'https://help.blaze.me/can/home/birchmount-gift-cards',
  DASHBOARD_BARCODE_VALIDATION = 'https://help.blaze.me/can/home/what-are-the-benefits',
  DASHBOARD_LOG_IN = 'https://help.blaze.me/can/home/what-is-the-dashboard',
  ECOM = 'https://help.blaze.me/can/home/blaze-ecom-getting-started',
  EMPLOYEES = 'https://help.blaze.me/can/home/how-to-add-employees',
  ENDING_SHIFT = 'https://help.blaze.me/can/home/log-off-not-counting-float-1',
  HELP_CENTER = 'https://help.blaze.me/can/home',
  INTEGRATIONS = 'https://help.blaze.me/can/home/integrations',
  LEAFLY = 'https://help.blaze.me/can/home/leafly-integration',
  LOT_TRACKING = 'https://help.blaze.me/can/home/should-you-be-lot-tracking',
  LOYALTY = 'https://help.blaze.me/can/home/what-can-blaze-loyalty-do',
  MAKING_FIRST_SALES = 'https://help.blaze.me/can/home/step-1',
  MERRCO_PAYSTONE_GIFT_CARDS = 'https://help.blaze.me/can/home/merrco-paystone-gift-cards',
  MERRCO_TERMINAL_SETUP = 'https://help.blaze.me/can/home/what-payment-terminals-are-supported',
  MONERIS_TERMINAL_SETUP = 'https://help.blaze.me/can/home/how-to-get-started-1',
  OUTREACH_SMS = 'https://help.blaze.me/can/home/sms-messaging',
  POS_BARCODE_VALIDATION = 'https://help.blaze.me/can/home/pos-tablet-parked-sale-barcode-validation',
  POS_HARDWARE_SETUP = 'https://help.blaze.me/can/home/pos-hardware-setup-blaze-help-center',
  PRODUCTS_ADD_EDIT = 'https://help.blaze.me/can/home/products-add-and-edit',
  PRODUCTS_ADD_FROM_DB = 'https://help.blaze.me/can/home/add-from-product-database',
  PRODUCTS_BULK_UPDATE_PRICE = 'https://help.blaze.me/can/home/how-to-export-your-product-list-for-pricing-updates',
  PRODUCTS_BRANDS_VENDORS = 'https://help.blaze.me/can/home/products-brands-vendors',
  PRODUCTS_CATEGORIES = 'https://help.blaze.me/can/home/how-to-add-product-categories',
  PRODUCTS_DISCOUNTS = 'https://help.blaze.me/can/home/products-discounts',
  PURCHASE_ORDERS = 'https://help.blaze.me/can/home/creating-a-purchase-order',
  PURCHASE_ORDERS_IMPORT_OPTIONS = 'https://help.blaze.me/can/home/video-tutorial-resources',
  QUICKBOOKS = 'https://help.blaze.me/can/home/quickbooks-online-integration',
  REPORTS_ADVANCED = 'https://help.blaze.me/can/home/what-are-advanced-reports',
  REPORTS_COMPLIANCE = 'https://help.blaze.me/can/home/how-do-i-generate-a-compliance-report',
  REPORTS_TERMINOLOGY = 'https://help.blaze.me/can/home/reports-financial-terminology-blaze-help-center',
  SETTINGS = 'https://help.blaze.me/can/home/settings-general',
  STARTING_SHIFT = 'https://help.blaze.me/can/home/how-do-i-start-a-shift-1',
  TRANSFERS = 'https://help.blaze.me/can/home/how-to-transfer-inventory',
  WEEDMAPS = 'https://help.blaze.me/can/home/weedmaps-integration',
  WHOLESALE_COST = 'https://help.blaze.me/can/home/last-cost-default',
  WOOCOMMERCE = 'https://help.blaze.me/can/home/woocommerce-integration',
  WORLDPAY_TERMINAL_SETUP = 'https://help.blaze.me/can/home/how-do-i-get-started-with-worldpay',
}
