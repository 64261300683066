import React, { useState } from 'react'
import { Input, Button, Drawer, Alert } from 'antd'
import Markdown from 'react-markdown'

interface Props {
  company: any
  updateCompany: (updateObject: any) => void
  isVisible: boolean
  onClose: () => void
}

export const BannerMessageDrawer = ({ company, updateCompany, isVisible, onClose }: Props) => {
  const [bannerMessage, setBannerMessage] = useState<string | null | undefined>(
    company.bannerMessage,
  )

  return (
    <Drawer title='Banner message' width={600} visible={isVisible} onClose={onClose}>
      <p>
        Try to keep these short as they will be displayed as a banner across the company's
        dashboard.
      </p>
      <p>
        You can use{' '}
        <a href='https://www.markdownguide.org/cheat-sheet/' target='_blank' rel='noreferrer'>
          markdown
        </a>{' '}
        to add links
      </p>
      {!!bannerMessage && (
        <Alert
          type='error'
          banner
          className='banner-message'
          message={<Markdown source={bannerMessage || undefined} />}
        />
      )}

      <Input.TextArea
        className='mt-5'
        rows={5}
        value={bannerMessage || undefined}
        onChange={(e) => setBannerMessage(e.target.value)}
      />
      <Button className='mt-2' onClick={() => updateCompany({ bannerMessage })}>
        Save
      </Button>
    </Drawer>
  )
}
