import { ICompany } from '@getgreenline/homi-shared'
import { createPhoneNumber, getQuota, IQuota, updateQuota } from '@getgreenline/marketing'
import { Alert, Button, Form, Input, Row, Spin } from 'antd-v4'
import { AlertProps } from 'antd-v4/lib/alert'
import axios from 'axios'
import { inject } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import ENDPOINTS from '../../../../constants/Endpoints'
import { CurrentUserStore } from '../../../../stores/CurrentUserStore'
import { InfoCards } from './InfoCards'

interface ISmsFormValues {
  addQuantity: number
  updateReplenishRate: number
}

interface IInjectedProps {
  currentUserStore?: CurrentUserStore
}

interface IProps extends IInjectedProps {
  company: ICompany
}

const _SmsQuotaWidget: FC<IProps> = ({ company, currentUserStore }: IProps) => {
  const [form] = Form.useForm()
  const [smsQuota, setSmsQuota] = useState<IQuota | null>(null)
  const [feedbackAlert, setFeedbackAlert] = useState<AlertProps | null>(null)
  const [isWaitingInitialFetch, setIsWaitingInitialFetch] = useState(true)
  const [isWaitingResponse, setIsWaitingResponse] = useState(false)
  const [showInfoCards, setShowInfoCards] = useState(true)

  useEffect(() => {
    const execute = async () => {
      if (!company) return
      setSmsQuota(null)
      setFeedbackAlert(null)
      try {
        const quota = await getQuota(ENDPOINTS.AUTHZ_GATEWAY_URL, company.id)
        setShowInfoCards(true)
        setSmsQuota(quota)
      } catch (error) {
        if (!(axios.isAxiosError(error) && error.response?.status === 404)) {
          setShowInfoCards(false)
          setFeedbackAlert({
            type: 'error',
            message: 'There was a problem on checking the SMS for this customer.',
          })
        }
      }
      setIsWaitingInitialFetch(false)
    }
    execute()
  }, [company])

  useEffect(() => {
    const formValues: ISmsFormValues = {
      addQuantity: smsQuota?.quantity ?? 500,
      updateReplenishRate: smsQuota?.monthlyReplenishRate ?? 500,
    }
    form.setFieldsValue(formValues)
  }, [form, smsQuota])

  const handleConfirm = async (formValues: ISmsFormValues) => {
    if (!company.id || !currentUserStore?.currentUser?.id) return
    setIsWaitingResponse(true)
    try {
      if (smsQuota) {
        const updatedQuota = await updateQuota(ENDPOINTS.AUTHZ_GATEWAY_URL, company.id, {
          userId: currentUserStore.currentUser.id,
          changeQuantity: formValues.addQuantity,
          monthlyReplenishRate: formValues.updateReplenishRate,
        })
        setSmsQuota(updatedQuota)
        setFeedbackAlert({
          type: 'success',
          message: 'SMS quota was successfully updated',
        })
      } else {
        const createdPhoneNumber = await createPhoneNumber(
          ENDPOINTS.AUTHZ_GATEWAY_URL,
          company.id,
          {
            userId: currentUserStore.currentUser.id,
            quantity: formValues.addQuantity,
          },
        )
        setSmsQuota({
          createDate: createdPhoneNumber.createDate,
          updateDate: createdPhoneNumber.updateDate,
          updatedBy: createdPhoneNumber.updatedBy,
          quantity: formValues.addQuantity,
          monthlyReplenishRate: formValues.addQuantity,
        })
        setFeedbackAlert({
          type: 'success',
          message: `SMS was successfully enabled with ${formValues.addQuantity} messages`,
        })
      }
    } catch (error) {
      setFeedbackAlert({
        type: 'error',
        message: `There was a problem on ${
          smsQuota ? 'updating the quota' : 'enabling SMS'
        } for this customer`,
      })
    }
    setIsWaitingResponse(false)
  }

  return (
    <div className='background-shadow p-4'>
      <h5 className='text-muted'>SMS Outreach</h5>
      <Form id='sms-quota' form={form} onFinish={handleConfirm}>
        {feedbackAlert ? (
          <Alert
            {...feedbackAlert}
            style={{ margin: '16px 0 24px 0' }}
            showIcon
            closable
            onClose={() => setFeedbackAlert(null)}
          />
        ) : null}
        {showInfoCards ? (
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: feedbackAlert ? '0px' : '24px',
              marginBottom: '24px',
            }}
          >
            {isWaitingInitialFetch || isWaitingResponse ? (
              <Spin />
            ) : smsQuota ? (
              <InfoCards smsQuota={smsQuota} />
            ) : (
              <Alert
                message='SMS is not enabled'
                description='This company does not have SMS enabled, to enable just set the initial quantity and click on the button below'
                type='info'
                showIcon
              />
            )}
          </Row>
        ) : null}
        <Row style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Item
            name='addQuantity'
            label='Add quantity'
            style={{ flex: 1, marginRight: '32px' }}
            rules={[{ required: true, message: 'Please enter the quantity to add' }]}
            initialValue={smsQuota?.quantity ?? 500}
          >
            <Input type='number' />
          </Form.Item>
          <Form.Item
            name='updateReplenishRate'
            label='Update replenish rate'
            rules={[{ required: !!smsQuota, message: 'Please enter the replenish rate' }]}
            style={{ flex: 1 }}
            initialValue={smsQuota?.monthlyReplenishRate ?? 500}
          >
            <Input type='number' disabled={!smsQuota} />
          </Form.Item>
        </Row>
        <Form.Item>
          <Button type='primary' htmlType='submit' loading={isWaitingResponse}>
            {smsQuota ? 'Update SMS quota' : 'Enable SMS with selected quantity'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export const SmsQuotaWidget = inject('currentUserStore')(_SmsQuotaWidget)
