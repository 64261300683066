import { ResourcesConfig } from 'aws-amplify'

export const amplifyConfig: ResourcesConfig = {
  API: {
    GraphQL: {
      endpoint: `${process.env.REACT_APP_APPSYNC_API_ENDPOINT}`,
      region: `${process.env.REACT_APP_APPSYNC_API_REGION}`,
      defaultAuthMode: 'lambda',
    },
  },
}
