import React, { Component } from 'react'
import { InputNumber } from 'antd'
import { GrPrice } from '../../../../../../utilities/helpers'
import { InputNumberProps } from 'antd/lib/input-number'
import { isNumber } from 'lodash'

interface Props extends Omit<InputNumberProps, 'value'> {
  className?: string
  isEditable: boolean
  value: number | null
  onBlur?: () => void
}

export class AntAuditInputNumber extends Component<Props> {
  render() {
    const value = isNumber(this.props.value) ? this.props.value : undefined

    switch (true) {
      case this.props.isEditable:
        return <InputNumber {...this.props} value={value} />
      case !value:
        return <div className={this.props.className}>-</div>
      default:
        return (
          <div className={this.props.className}>
            {GrPrice.convertDecimalString(value || 0, 0, 2)}
          </div>
        )
    }
  }
}
