import * as React from 'react'
import { Alert } from 'reactstrap'

interface StateProps {
  status?: 'danger' | 'success' | 'info'
  message?: string
}

export class FormError extends React.Component<React.PropsWithChildren<StateProps>, {}> {
  render() {
    if (this.props.message) {
      return (
        <div className='mt-1 mb-1'>
          <Alert color={this.props.status || 'danger'}>
            <div className='d-flex flex-row align-items-center'>
              <div>{this.props.message}</div>
              <div className='ml-3'>{this.props.children}</div>
            </div>
          </Alert>
        </div>
      )
    } else {
      return <span />
    }
  }
}
