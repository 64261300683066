import * as React from 'react'
import {
  DragDropContext,
  Droppable,
  DragUpdate,
  ResponderProvided,
  DropResult,
} from 'react-beautiful-dnd'

interface Props {
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void
}

interface PlaceHolderProps {
  clientX?: number
  clientY?: number
  clientHeight?: number
  clientWidth?: number
}

const getDraggedDom = (draggableId: string) => {
  const queryAttr = 'data-rbd-drag-handle-draggable-id'
  const domQuery = `[${queryAttr}='${draggableId}']`

  return document.querySelector(domQuery)
}

export const DragDropWrapper = (props: React.PropsWithChildren<Props>) => {
  const { onDragEnd, children } = props
  const [placeholderProps, setPlaceholderProps] = React.useState<PlaceHolderProps>({})

  const onDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      return
    }

    const draggedDOM = getDraggedDom(event.draggableId)

    if (!draggedDOM || !draggedDOM.parentNode) {
      return
    }

    const destinationIndex = event.destination.index
    const sourceIndex = event.source.index

    const childrenArray = [...(draggedDOM.parentNode.children as any)]
    const movedItem = childrenArray[sourceIndex]
    childrenArray.splice(sourceIndex, 1)

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ]

    const paddingTop = parseFloat(window.getComputedStyle(draggedDOM.parentNode as any).paddingTop)
    const height = updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
      const style = curr.currentStyle || window.getComputedStyle(curr)
      const marginBottom = parseFloat(style.marginBottom)
      return total + curr.clientHeight + marginBottom
    }, 0)

    setPlaceholderProps({
      clientHeight: draggedDOM.clientHeight,
      clientWidth: draggedDOM.clientWidth,
      clientY: paddingTop + height,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode as any).paddingLeft),
    })
  }

  return (
    <DragDropContext
      onDragEnd={(result: DropResult, provided: ResponderProvided) => {
        setPlaceholderProps({})
        onDragEnd(result, provided)
      }}
      onDragUpdate={onDragUpdate}
    >
      <Droppable droppableId='column-1'>
        {(provided, snapshot) => (
          <div
            className='source border rounded pt-1 pb-1 pl-3 pr-3'
            style={{ position: 'relative' }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}

            {provided.placeholder}
            {Object.keys(placeholderProps).length > 0 && snapshot.isDraggingOver && (
              <div
                className='placeholder'
                style={{
                  top: placeholderProps.clientY,
                  left: placeholderProps.clientX,
                  height: placeholderProps.clientHeight,
                  width: placeholderProps.clientWidth,
                  position: 'absolute',
                  backgroundColor: 'var(--gray)',
                  borderRadius: '3px',
                }}
              />
            )}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
