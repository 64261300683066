import * as React from 'react'
import './SmartImage.scss'
import BrokenImageFallback from '../../src/images/broken-image-fallback-gr.svg'
import ImagePlaceholder from '../../src/images/img-placeholder.svg'
import { Icon, Tooltip } from 'antd'
import { parseCloudinaryQuality } from '@getgreenline/shared'

interface Props {
  src: string | null
  alt?: string
  width?: number
  height?: number
  className?: string
  element?: 'image' | 'div' // Defaults to image
  customStyles?: React.CSSProperties
}

interface State {
  processedSrc: string
  isTooltipVisible: boolean
  backgroundColor: 'transparent' | 'var(--light)'
}

// Can receive any image url
// If it's a Cloudinary url, transform based on the passed-in width and height
export class SmartImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    const parsedImage = props.src ? this.parseImage(props.src) : ImagePlaceholder

    this.state = {
      processedSrc: parsedImage,
      isTooltipVisible: false,
      backgroundColor: props.src ? 'transparent' : 'var(--light)',
    }
  }

  componentDidUpdate = async (prevProps: Props) => {
    if (prevProps.src !== this.props.src) {
      this.setState({
        processedSrc: this.props.src ? this.parseImage(this.props.src) : ImagePlaceholder,
      })
    }
  }

  parseImage = (src: string) => {
    if (this.isCloudinary) {
      const parsedSrcQualitySize = this.parseCustomSizeUrl(src)
      return parsedSrcQualitySize
    }
    return src
  }

  parseCustomSizeUrl = (src: string) => {
    const { width, height } = this.props
    let dimensionsToApply: string[]
    if (this.isCloudinary && (width || height)) {
      const dimensionsQuery = `${width ? 'w_' + width : ''}${width && height ? ',' : ''}${
        height ? 'h_' + height : ''
      }`
      dimensionsToApply = [dimensionsQuery]
      return parseCloudinaryQuality(src, dimensionsToApply)
    }
    return src
  }

  renderFallbackImage = () => {
    this.setState({
      processedSrc: BrokenImageFallback,
      isTooltipVisible: true,
      backgroundColor: 'var(--light)',
    })
  }

  get isCloudinary(): boolean {
    return this.props.src ? this.props.src.includes('cloudinary.com') : false
  }

  get image(): JSX.Element {
    const { processedSrc, isTooltipVisible, backgroundColor } = this.state
    const { width, height, element, className, alt, customStyles } = this.props

    const img =
      !element || element === 'image' ? (
        <img
          className={
            className ? `img-thumbnail smart-image ${className}` : 'img-thumbnail smart-image'
          }
          src={processedSrc}
          onError={this.renderFallbackImage}
          alt={alt}
          style={{
            width: width ? `${width}px` : undefined,
            height: height ? `${height}px` : undefined,
            backgroundColor,
            ...customStyles,
          }}
        />
      ) : (
        <img
          src={processedSrc}
          onError={this.renderFallbackImage}
          alt={alt}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: width ? `${width}px` : '100%',
            height: height ? `${height}px` : '100%',
            backgroundColor,
          }}
        />
      )

    return (
      <div
        style={{
          position: 'relative',
          width: 'fit-content',
        }}
      >
        {img}
        {isTooltipVisible && (
          <Tooltip title='Image link broken'>
            <Icon
              type='exclamation-circle'
              className='cursor-pointer'
              style={{
                position: 'absolute',
                top: 'calc(3px + .21vw)',
                right: 'calc(3px + .21vw)',
                fontSize: 'clamp(7px, 0.6vw, 14.4px)',
                color: 'var(--orange)',
              }}
            />
          </Tooltip>
        )}
      </div>
    )
  }

  render() {
    return this.image
  }
}
