import { AxiosError } from 'axios'
import {
  axios,
  CashLogType,
  IInventorySearchFilter,
  overwriteLambdaAPIUrl as legacyOverwriteLambdaAPIUrl,
} from '@getgreenline/homi-shared'
import { WeedmapApi } from '@getgreenline/weedmaps'
import { overwriteLambdaAPIUrl } from '@getgreenline/shared'
import { ONLambdaOneClickApi } from '@getgreenline/compliance'
import { jsonToQueryString } from '../utilities/helpers'
import { setRootApiUrl } from '../utilities/environment'
import { LocalStorage } from '../utilities/LocalStorage'
import { WebhooksApi } from '@getgreenline/webhooks'
import * as auth from '../utilities/auth'

// NOTE: This file is now deprecated, try to use to API.ts module instead

const RootApi = process.env.REACT_APP_ROOT_API_URL as string
const storedApiUrl = LocalStorage.getOverrideAPIUrl()

if ((window as any).Cypress) {
  setRootApiUrl('https://staging-api.getgreenline.co')
} else {
  setRootApiUrl(storedApiUrl || RootApi)
}

if (process.env.REACT_APP_PROVINCE_PRODUCTS_URL) {
  legacyOverwriteLambdaAPIUrl(process.env.REACT_APP_PROVINCE_PRODUCTS_URL)
  overwriteLambdaAPIUrl(process.env.REACT_APP_PROVINCE_PRODUCTS_URL)
}

if (process.env.REACT_APP_WEEDMAPS_API_URL) {
  WeedmapApi.overwriteWeedmapsAPIUrl(process.env.REACT_APP_WEEDMAPS_API_URL)
}

if (process.env.REACT_APP_ON_COMPLIANCE_REPORT_URL) {
  ONLambdaOneClickApi.overwriteONComplianceReportAPIUrl(
    process.env.REACT_APP_ON_COMPLIANCE_REPORT_URL,
  )
}

if (process.env.REACT_APP_PROVINCE_PRODUCTS_URL) {
  overwriteLambdaAPIUrl(process.env.REACT_APP_PROVINCE_PRODUCTS_URL)
}

if (process.env.REACT_APP_WEBHOOKS_SERVICE_URL) {
  WebhooksApi.overwriteWebhooksAPIUrl(process.env.REACT_APP_WEBHOOKS_SERVICE_URL)
}

if (process.env.REACT_APP_WEBHOOKS_SERVICE_API_KEY) {
  WebhooksApi.overwriteWebhooksAPIKey(process.env.REACT_APP_WEBHOOKS_SERVICE_API_KEY)
}

const PrintingApi = process.env.REACT_APP_ROOT_PRINTING_URL as string
const AUTHZ_GATEWAY_URL = process.env.REACT_APP_AUTHZ_GATEWAY_URL as string
const PLATFORM_GATEWAY_URL = process.env.REACT_APP_PLATFORM_URL as string

axios.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response) {
      const { request, status } = error.response
      const responseURL = request.responseURL || ''

      /**
       * We don't want customers logged out if we get 401 from an external API response
       */
      const isGrResponse = responseURL.includes(RootApi)
      const isLoginResponse = responseURL.includes('/login') || responseURL.includes('/loginV2')
      const isUnauthorized = status === 401

      if (isGrResponse && isUnauthorized && !isLoginResponse) {
        await auth.signOut()
        const currentUrl = window.location.href
        if (currentUrl.includes('/pos')) {
          await LocalStorage.removeDeviceToken()
          window.location.href = '/'
        } else if (currentUrl.includes('/company/')) {
          await LocalStorage.removeAuthToken()
          window.location.href = '/'
        }
      }
    }

    return Promise.reject(error)
  },
)

export default {
  ROOT_API_URL: RootApi,
  PRINTING_API_URL: PrintingApi,
  GET_PING: `/ping`,
  CONTACT_FORM: `/api/v1/contactForm`,
  GET_PDF_LABEL: (url: string, pageWidth: number, pageHeight: number) =>
    `/api/v1/labels?width=${pageWidth}&height=${pageHeight}&url=${encodeURIComponent(url)}`,
  GET_PDF_LABEL_V2: `/api/v2/labels`,

  VERIFY_EMAIL: (token: string) => `/api/v1/user/verifyEmail?token=${token}`,
  FORGOT_PASSWORD: `/api/v1/forgotPassword`,
  RESET_PASSWORD: `/api/v1/resetPassword`,
  PATCH_ME: `/api/v1/user/me`,
  GET_SIGNED_IMAGE_UPLOAD_URL: (companyId: number, fileExtension: string, contentType: string) =>
    `/api/v1/company/${companyId}/images/signed?fileExtension=${fileExtension}&contentType=${contentType}`,
  GET_SIGNED_TEMP_FILE_UPLOAD_URL: (
    companyId: number,
    fileExtension: string,
    contentType: string,
  ) =>
    `/api/v1/company/${companyId}/tempFile/signed?fileExtension=${fileExtension}&contentType=${contentType}`,
  GET_SIGNED_IMAGE_UPLOAD_PUBLIC_URL: (companyId: number) =>
    `/api/v1/company/${companyId}/images/public/signed`,
  GET_SIGNED_ADMIN_IMAGE_UPLOAD_PUBLIC_URL: () => `/api/v1/admin/images/public/signed`,
  GET_SIGNED_IMAGE_URL: (companyId: number, fileName: string) =>
    `/api/v1/company/${companyId}/images?fileName=${fileName}`,
  EDIT_DEVICE: (companyId: number, deviceId: string) =>
    `/api/v1/company/${companyId}/user/device/${deviceId}`,
  GET_INVENTORY_PRODUCTS: (companyId: number) => `/api/v1/company/${companyId}/inventory/products`,
  GET_INVENTORY_BY_MULTIPLE_LOCATIONS: (
    companyId: number,
    locationIds: number[],
    productIds?: string[],
    searchFilters?: IInventorySearchFilter,
  ) => {
    let url = `/api/v1/company/${companyId}/inventory?locationIds=[${locationIds}]&`
    if (productIds) {
      url += `&productIds=${JSON.stringify(productIds)}`
    }
    if (searchFilters) {
      url += `&${jsonToQueryString(searchFilters)}`
    }
    return url
  },
  GET_CUSTOMER_HISTORICAL_PAYMENTS: (companyId: number, customerId: string) =>
    `/api/v1/company/${companyId}/customer/${customerId}/payments`,

  GET_LOCATION: (companyId: number, locationId: number) =>
    `/api/v1/company/${companyId}/location/${locationId}`,
  UPDATE_BULK_INVENTORY: (companyId: number, locationId: number) =>
    `/api/v1/company/${companyId}/location/${locationId}/inventory/bulk`,
  UPDATE_BULK_INVENTORY_TRANSFER: (
    companyId: number,
    fromLocationId: number,
    toLocationId: number,
  ) =>
    `/api/v1/company/${companyId}/inventory/transfer?fromLocationId=${fromLocationId}&toLocationId=${toLocationId}`,
  GET_LOCATION_PAYMENTS: (companyId: number, locationId: number, queryString: string) => {
    const url = `/api/v1/company/${companyId}/location/${locationId}/payments?${queryString}`
    return url
  },
  ADD_PAYMENT: (companyId: number, locationId: number) => {
    const url = `/api/v1/company/${companyId}/location/${locationId}/payments?`
    return url
  },
  REFUND_PAYMENT: (companyId: number, locationId: number, paymentId: string) =>
    `/api/v1/company/${companyId}/location/${locationId}/payments/${paymentId}/refund`,
  IN_PROGRESS_PAYMENT: (companyId: number, locationId: number, paymentId: string) =>
    `/api/v1/company/${companyId}/location/${locationId}/payments/${paymentId}/inProgress`,
  COMPLETE_PAYMENT: (companyId: number, locationId: number, paymentId: string) =>
    `/api/v1/company/${companyId}/location/${locationId}/payments/${paymentId}/complete`,
  CANCEL_PAYMENT: (companyId: number, locationId: number, paymentId: string) =>
    `/api/v1/company/${companyId}/location/${locationId}/payments/${paymentId}/cancel`,
  GET_DEVICE_CASH_REGISTER_LOGS: (
    companyId: number,
    deviceId: string | undefined,
    type: CashLogType | null,
    employeeIds: string[],
  ) => {
    let url = `/api/v1/company/${companyId}/eventLogs/deviceCashRegister?`
    if (deviceId) {
      url += `deviceId=${deviceId}&`
    }
    if (type) {
      url += `type=${type}&`
    }
    if (employeeIds.length > 0) {
      url += `employeeIds=${JSON.stringify(employeeIds)}&`
    }
    return url
  },
  GET_GRAPH_REPORT: (
    companyId: number,
    startDate: Date,
    endDate: Date,
    groupBy: string,
    locationId?: number,
  ) => {
    let url = `/api/v1/company/${companyId}/reports/graph?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&groupBy=${groupBy}`
    if (locationId) {
      url += `&locationIds=[${locationId}]`
    }
    return url
  },
  GET_SUPPLIERS: (companyId: number) => `/api/v1/company/${companyId}/suppliers`,
  ADD_SUPPLIER: (companyId: number) => `/api/v1/company/${companyId}/supplier`,
  UPDATE_SUPPLIER: (companyId: number, supplierId: number) =>
    `/api/v1/company/${companyId}/supplier/${supplierId}`,
  SYNC_WOOCOMMERCE_PRODUCT: (companyId: number, productId: string) =>
    `/api/v1/company/${companyId}/woocommerce/product/${productId}/productSync`,
  SYNC_WOOCOMMERCE_INVENTORY: (companyId: number, productId: string) =>
    `/api/v1/company/${companyId}/woocommerce/product/${productId}/inventorySync`,
  DELETE_WOOCOMMERCE_PRODUCT: (companyId: number, productId: string) =>
    `/api/v1/company/${companyId}/woocommerce/product/${productId}`,
  ADD_CONVERSION: (companyId: number) => `/api/v1/company/${companyId}/conversion`,
  UPDATE_CONVERSION: (companyId: number, conversionId: string) =>
    `/api/v1/company/${companyId}/conversion/${conversionId}`,
  GET_CONVERSION_BY_ID: (companyId: number, conversionId: string) =>
    `/api/v1/company/${companyId}/conversion/${conversionId}`,
  GET_INVENTORY_AUDITS: (companyId: number) => `/api/v1/company/${companyId}/inventoryAudits`,
  ADD_INVENTORY_AUDIT: (companyId: number) => `/api/v1/company/${companyId}/inventoryAudits`,
  UPDATE_INVENTORY_AUDIT: (companyId: number, inventoryAuditId: string) =>
    `/api/v1/company/${companyId}/inventoryAudits/${inventoryAuditId}`,
  SYNC_ECOMMERCE_PRODUCTS: (tenantId: string, retailerId: string) =>
    `${AUTHZ_GATEWAY_URL}/ecomm/tenants/${tenantId}/retailers/${retailerId}/sync`,
  CREATE_DIGITAL_SIGNAGE_DEVICE: (tenantId: string, retailerId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/retailers/${retailerId}/device`,
  UPDATE_DIGITAL_SIGNAGE_DEVICE: (tenantId: string, retailerId: string, deviceId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/retailers/${retailerId}/device/${deviceId}`,
  DELETE_DIGITAL_SIGNAGE_DEVICE: (tenantId: string, retailerId: string, deviceId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/retailers/${retailerId}/device/${deviceId}`,
  GET_DIGITAL_SIGNAGE_DEVICES: (tenantId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/devices`,
  CREATE_SIGNAGE: (tenantId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/signages`,
  GET_SIGNAGES: (tenantId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/signages`,
  DELETE_SIGNAGE: (tenantId: string, signageId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/signages/${signageId}`,
  AUTHZ_GATEWAY_URL,
  UPDATE_SIGNAGE: (tenantId: string, signageId: string) =>
    `${AUTHZ_GATEWAY_URL}/digital-signage/tenants/${tenantId}/signages/${signageId}`,
  MIGRATE_USER_PASSWORD: '/api/v2/migrate-user-password',
  GET_COMPANY_TAGS: (companyId: number) => `/api/v1/company/${companyId}/tags`,
  PLATFORM_GATEWAY_URL,
  FEATURE_FLAG_STATUS: (
    companyId: number,
    featureId: string,
    userId?: string,
    locationId?: number,
  ) =>
    `${PLATFORM_GATEWAY_URL}/feature/api/v1/merchants/blc-${companyId}${
      locationId ? `-${locationId}` : ''
    }/features/${featureId}${userId ? `?userId=${userId}` : ''}`,
  GET_GLOBAL_PRODUCTS_UPLOAD_SIGNED_URL: (selectedProvince: string) =>
    `${PLATFORM_GATEWAY_URL}/global-products/api/v1/${selectedProvince.toLowerCase()}/uploadUrl`,
}
