import { setAPIUrl, setAuthToken } from '@getgreenline/homi-shared'
import { setAPIUrl as setSharedApiUrl, setSharedAuthToken } from '@getgreenline/shared'

export const setRootApiUrl = (url: string) => {
  setAPIUrl(url)
  setSharedApiUrl(url)
}

export const setRootAuthToken = (token: string) => {
  setAuthToken(`bearer ${token}`)
  setSharedAuthToken(`bearer ${token}`)
}
