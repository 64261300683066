import { observable, action } from 'mobx'
import { AxiosResponse } from 'axios'
import Endpoints from '../constants/Endpoints'
import { axios } from '@getgreenline/homi-shared'
import { Track, TrackEvent } from '../Track'

export enum ConnectionStatus {
  CONNECTING = 'Connecting',
  CONNECTED = 'Connected',
  DISCONNECTED = 'Disconnected',
}

enum DisconnectionReasons {
  NO_INTERNET = 'No internet',
  NO_SERVER = 'No server',
}

interface DisconnectionEventItem {
  reason: DisconnectionReasons
  offlineTime?: Date
  errorMessage?: string
}

class InternetStore {
  private intervalInMs = 60000

  offlineTrackedEventKey = 'offlineEvents'

  @observable
  isInternetConnected = window.navigator.onLine

  @observable
  timer: NodeJS.Timer

  constructor() {
    this.timer = setInterval(this.sendTrackedDisconnectionWhenOnline, this.intervalInMs)
  }

  @action
  protected setIsInternetConnected = () => {
    this.isInternetConnected = window.navigator.onLine
  }

  @action
  protected trackDisconnectionWhenOffline = () => {
    const item: object =
      this.eventsInLocalStorage === null ? {} : JSON.parse(this.eventsInLocalStorage)

    const data: DisconnectionEventItem = {
      reason: DisconnectionReasons.NO_INTERNET,
      offlineTime: new Date(),
    }

    item[TrackEvent.DASHBOARD_SERVER_DISCONNECTED] = data

    window.localStorage.setItem(this.offlineTrackedEventKey, JSON.stringify(item))
  }

  @action
  protected sendTrackedDisconnectionWhenOnline = () => {
    if (this.eventsInLocalStorage !== null) {
      const parsedItem = JSON.parse(this.eventsInLocalStorage)

      Object.keys(parsedItem).forEach((key) => {
        const value = parsedItem[key]
        Track.track(key, value)
      })

      window.localStorage.removeItem(this.offlineTrackedEventKey)
    }
  }

  protected get eventsInLocalStorage() {
    return window.localStorage.getItem(this.offlineTrackedEventKey)
  }
}

export class ServerStore extends InternetStore {
  @observable
  isConnectedToServer: ConnectionStatus = ConnectionStatus.CONNECTING

  @observable
  commit?: string

  constructor() {
    super()

    axios.interceptors.response.use(
      (response) => {
        return this.handleNetworkInterception(response)
      },
      (error: any) => {
        return this.handleNetworkError(error)
      },
    )
  }

  @action
  private setIsConnectedToServer(status: ConnectionStatus) {
    this.isConnectedToServer = status
  }

  @action
  private handleNetworkInterception(response: AxiosResponse): AxiosResponse {
    if (response.status === 200) {
      this.isConnectedToServer = ConnectionStatus.CONNECTED
    }

    return response
  }

  @action
  private handleNetworkError(error: any) {
    if (!error.response && !(error.message === 'timeout of 1000ms exceeded')) {
      // Server disconnected
      this.isConnectedToServer = ConnectionStatus.DISCONNECTED

      this.setIsInternetConnected()

      if (this.isInternetConnected) {
        const item: DisconnectionEventItem = {
          reason: DisconnectionReasons.NO_SERVER,
          errorMessage: error.message,
        }

        Track.track(TrackEvent.DASHBOARD_SERVER_DISCONNECTED, item)
      } else {
        this.trackDisconnectionWhenOffline()
      }
    }

    return Promise.reject(error)
  }

  @action
  connectToServer() {
    axios
      .get(Endpoints.GET_PING)
      .then((response) => {
        this.setIsConnectedToServer(ConnectionStatus.CONNECTED)
        this.commit = response.data.commit
      })
      .catch((error) => {
        this.isConnectedToServer = ConnectionStatus.DISCONNECTED
      })
  }

  @action
  reconnect() {
    this.setIsConnectedToServer(ConnectionStatus.CONNECTING)
    axios.get(Endpoints.GET_PING)
  }
}
