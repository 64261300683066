import * as React from 'react'
import { Helmet } from 'react-helmet'
import { WrappedLoginFormDashboard } from './LoginFormDashboard'
import { useState, useEffect } from 'react'
import { Row, Col, Form, Icon, Radio } from 'antd'
import { WrappedLoginFormPOS } from './LoginFormPOS'
import './login.scss'
import { animated, useTransition } from 'react-spring'

import bgImage from '../../../images/login/login-bg-FIKA.jpg'
import { COLORS } from '../../../constants/Colors'

export const Login = () => {
  const [showDashboardLogin, setShowDashboardLogin] = useState(true)

  useEffect(() => {
    // Adding class for the page to allow of custom CSS
    document.body.classList.add('login-page')
  }, [])

  const transitions = useTransition(showDashboardLogin, null, {
    from: { position: 'absolute', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name='page-loaded' content='true' />
      </Helmet>

      {
        // Checking if chrome
        !window.hasOwnProperty('chrome') && !window.navigator.userAgent.match('CriOS') && (
          <div className='alert alert-warning text-center mb-0'>
            <div className='fa fa-exclamation-triangle mr-2'></div>
            We recommend you use{' '}
            <a href='https://www.google.com/chrome/' target='_blank' rel='noreferrer'>
              Google Chrome
            </a>{' '}
            for the best and most secure browsing experience
          </div>
        )
      }

      <Row
        style={{
          height: '100%',
          width: '100%',
          background: `linear-gradient(0deg, rgba(38, 50, 56, 0.7), rgba(38, 50, 56, 0.7)), 
           url(${bgImage}) no-repeat center center fixed`,
          backgroundSize: 'cover',
        }}
        type='flex'
        justify='center'
        align='middle'
      >
        <Col
          style={{
            position: 'relative',
            boxShadow: 'rgba(0, 0, 0, 0.3) 4px 4px 16px',
            borderRadius: '10px',
            overflow: 'hidden',
          }}
        >
          <Row type='flex'>
            <Col
              xs={24}
              lg={12}
              style={{
                transition: 'background 0.5s cubic-bezier(.25,.1,.25,1)',
                alignSelf: 'stretch',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundColor: COLORS.BRAND.black,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src={require('../../../images/logos/Greenline_darkBG-trans-wht-text.svg').default}
                style={{ width: '100%', position: 'relative' }}
              />
            </Col>
            <Col
              xs={24}
              lg={12}
              style={{ backgroundColor: COLORS.MONO.monoFf }}
              className='login-form'
            >
              <Form.Item>
                <Radio.Group
                  size='large'
                  onChange={(e) => setShowDashboardLogin(e.target.value === 'dashboard')}
                  defaultValue='dashboard'
                  style={{ display: 'flex' }}
                >
                  <Radio.Button value='dashboard' style={{ flex: 1, textAlign: 'center' }}>
                    <Icon style={{ marginRight: '0.5rem' }} type='dashboard' /> Dashboard
                  </Radio.Button>
                  <Radio.Button value='pos' style={{ flex: 1, textAlign: 'center' }}>
                    POS
                    <Icon style={{ marginLeft: '0.5rem' }} type='shopping' />
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <div style={{ position: 'relative' }}>
                {transitions.map(({ item, key, props }) =>
                  item ? (
                    <animated.div key={key} style={{ ...props, width: '100%' }}>
                      <WrappedLoginFormDashboard />
                    </animated.div>
                  ) : (
                    <animated.div key={key} style={{ ...props, width: '100%' }}>
                      <WrappedLoginFormPOS />
                    </animated.div>
                  ),
                )}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}
