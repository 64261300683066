import { observable, action, computed } from 'mobx'
import { ICompany, API, IDeleteSupplier, Supplier, CreateSupplier } from '@getgreenline/homi-shared'

export class SupplierStore {
  @observable company: ICompany
  @observable suppliers?: Supplier[]

  constructor(company: ICompany) {
    this.company = company
  }

  @action
  getSuppliers(fetchLimit?: number, fetchOffset?: number) {
    return API.getSuppliers(this.company.id).then((response) => {
      const suppliers: Supplier[] = response
      this.suppliers = suppliers
      return suppliers
    })
  }

  @action
  addSupplier(createObject: CreateSupplier) {
    return API.addSupplier(this.company.id, createObject).then((response) => {
      const supplier = response
      this.getSuppliers()
      return supplier
    })
  }

  @action
  updateSupplier(updateObject: CreateSupplier) {
    return API.updateSupplier(this.company.id, updateObject.id!, updateObject).then((response) => {
      const supplier = response
      this.getSuppliers()
      return supplier
    })
  }

  @action
  async deleteSupplier(supplierId: number, deleteObject: IDeleteSupplier) {
    await API.deleteSupplier(this.company.id, supplierId, deleteObject)
  }

  @computed
  get suppliersWithName() {
    return this.suppliers ? this.suppliers.filter((supplier) => supplier.name) : []
  }
}
