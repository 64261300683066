import React, { Component } from 'react'
import { Select, message, Button, Alert, Modal, Icon } from 'antd'
import { API, ICompanyDefinition } from '@getgreenline/homi-shared'
import { CompaniesApi } from '@getgreenline/companies'
import { parseErrorMsg } from '../../../utilities/helpers'
import { productApi } from '@getgreenline/products'

interface Props {
  companyId: number
}

interface State {
  companies: ICompanyDefinition[]
  sourceCompanyId?: number
  isMigrating: boolean
  migrationStatus?: TMigrationStatus
  errorMessage?: string
  showModal: boolean
}

type TMigrationStatus = 'success' | 'error'

export class CompanyMigrate extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      companies: [],
      isMigrating: false,
      showModal: false,
    }
  }

  componentDidMount() {
    this.loadCompanies()
  }

  loadCompanies = async () => {
    try {
      const companies = await API.getAdminCompanies()
      this.setState({ companies: companies.reverse() })
    } catch (error) {
      message.error(parseErrorMsg(error))
    }
  }

  validateMigration = async (companyId: number, sourceCompanyId: number | undefined) => {
    this.setState({ migrationStatus: undefined })

    try {
      const products = (await productApi.getProducts(companyId)).products
      const categories = await API.getCategories(companyId)
      const categoriesWithoutSystemFees = categories.filter(
        (categories) => categories.categoryType !== 'systemFees',
      )
      const vendors = await API.getSuppliers(companyId)

      const companyData = await Promise.all([
        ...products,
        ...categoriesWithoutSystemFees,
        ...vendors,
      ])

      if (companyData.length > 0) {
        return this.setState({
          migrationStatus: 'error',
          errorMessage:
            'You cannot migrate data into an existing company. This feature is limited to new companies only.',
        })
      }

      window.onbeforeunload = function () {
        return true
      }

      this.setState({ isMigrating: true })

      if (!sourceCompanyId) {
        return
      }

      await CompaniesApi.migrateCompany(companyId, sourceCompanyId)
      this.setState({ migrationStatus: 'success' })
    } catch (error) {
      this.setState({
        migrationStatus: 'error',
        errorMessage: parseErrorMsg(error) || 'An error occurred while migrating company data',
      })
    } finally {
      this.setState({ isMigrating: false })
      window.onbeforeunload = null
    }
  }

  getCompanyName = (companyId: number) => {
    const { companies } = this.state
    const source = companies.find((company) => company.id === companyId)
    if (!source) {
      return
    }
    return source.name
  }

  render() {
    const { companyId } = this.props
    const { companies, sourceCompanyId, isMigrating, migrationStatus, errorMessage, showModal } =
      this.state

    return (
      <div className='background-shadow p-4'>
        <h5 className='text-muted'>Migrate company data</h5>
        <ul>
          <li>Products</li>
          <li>Vendors</li>
          <li>Categories</li>
          <li>Tax groups</li>
        </ul>

        {migrationStatus === 'success' && sourceCompanyId && (
          <Alert
            type='success'
            message='Success'
            description={`Successfully migrated data from ${sourceCompanyId}: ${this.getCompanyName(
              sourceCompanyId,
            )}`}
            showIcon
          />
        )}

        {migrationStatus === 'error' && (
          <Alert
            type='error'
            message='Error'
            description={errorMessage}
            className='mb-4'
            showIcon
          />
        )}

        {migrationStatus !== 'success' && (
          <>
            <Select<number | undefined>
              className='gr-filter-item gr-filter-gutter'
              style={{ width: '50%' }}
              showSearch
              showArrow
              allowClear
              placeholder='Select a source company'
              value={sourceCompanyId}
              disabled={isMigrating}
              onChange={(companyId) => {
                this.setState({ sourceCompanyId: companyId })
              }}
            >
              {companies.map((company) => (
                <Select.Option key={company.id} value={company.id}>
                  {company.id}: {company.name}
                </Select.Option>
              ))}
            </Select>

            <Button
              type='primary'
              disabled={!sourceCompanyId}
              onClick={() => this.setState({ showModal: true })}
              loading={isMigrating}
            >
              {isMigrating ? 'Migrating...' : 'Migrate'}
            </Button>
          </>
        )}

        <Modal
          title='Company migration'
          visible={showModal}
          onOk={() => {
            this.setState({ showModal: false })
            this.validateMigration(companyId, sourceCompanyId)
          }}
          onCancel={() => this.setState({ showModal: false })}
        >
          <div className='d-flex flex-column justify-content-center'>
            <Alert
              message={`${sourceCompanyId}: ${this.getCompanyName(sourceCompanyId!)}`}
              type='warning'
              className='mb-2'
              style={{ margin: '0 auto' }}
            />
            <Icon type='arrow-down' style={{ fontSize: 25 }} className='mb-2' />
            <Alert
              message={`${companyId}: ${this.getCompanyName(companyId)}`}
              type='error'
              className='mb-2'
              style={{ margin: '0 auto' }}
            />
            <p style={{ textAlign: 'center' }}>
              You are about to migrate products, vendors, categories, and tax groups into a new
              company. <b>Would you like to continue?</b>
            </p>
          </div>
        </Modal>
      </div>
    )
  }
}
