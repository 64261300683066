import React from 'react'
import { AdminDevelopmentSettings } from './AdminDevelopmentSettings'
import { Row, Col } from 'antd-v4'
import { AdminDevelopmentTools } from './AdminDevelopmentTools'

export const AdminDevelopment = () => {
  return (
    <Row gutter={16}>
      <Col span={16} style={{ marginTop: 24 }}>
        <AdminDevelopmentSettings />
        <AdminDevelopmentTools />
      </Col>
    </Row>
  )
}
