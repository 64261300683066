import { IShift, ITransaction, CreatePayment } from '@getgreenline/homi-shared'
import { BlazepayEnums } from '@getgreenline/payments'
import { PaymentEventService } from './EventServices/PaymentEventService'
import { PaymentOptionEventService } from './EventServices/PaymentOptionEventService'
import { RefundEventService } from './EventServices/RefundEventService'

export class PaymentServiceStore {
  readonly merchantId: string
  readonly terminalId: string

  /**
   * we have no use for payment options at this point.
   * uncomment this when payment options are required
   */
  paymentOption: PaymentOptionEventService
  payment: PaymentEventService
  refund: RefundEventService

  constructor(companyId: number, locationId: number, terminalId: string) {
    this.merchantId = this.setMerchantId(companyId, locationId)
    this.terminalId = terminalId

    this.paymentOption = new PaymentOptionEventService(this.merchantId, this.terminalId)
    this.payment = new PaymentEventService(this.merchantId, this.terminalId)
    this.refund = new RefundEventService(this.merchantId, this.terminalId)

    this.init()
  }

  init() {
    this.paymentOption.subscribe()
  }

  private setMerchantId(companyId: number, locationId: number) {
    return `blc-${companyId}-${locationId}`
  }

  unsubscribeAll() {
    this.paymentOption.unsubscribe()
    this.payment.subscription?.unsubscribe()
    this.refund.subscription?.unsubscribe()
  }

  async processTransaction(
    payment: CreatePayment,
    provider: BlazepayEnums.Provider,
    transaction: ITransaction,
    currentShift?: IShift | null,
  ) {
    if (!currentShift?.currentUserId) {
      throw new Error('Shift is not found')
    }

    if (transaction.amount > 0) {
      return this.payment.processSaleTransaction(
        payment,
        provider,
        transaction,
        currentShift.currentUserId,
      )
    }

    return this.refund.processRefundTransaction(
      payment,
      provider,
      transaction,
      currentShift.currentUserId,
    )
  }
}
