import { ITransaction } from '@getgreenline/homi-shared'
import { BlazepayEnums, BlazepayPaymentModels, BlazepayRefundModels } from '@getgreenline/payments'

export const refundConverter = {
  serializeForRefund: ({
    paymentRes,
    refundRes,
    deviceId,
    currentUserId,
    reason,
    transaction,
  }: {
    paymentRes?: BlazepayPaymentModels.IPaymentData<
      BlazepayEnums.RequestType.PAYMENTS | BlazepayEnums.EventRequestType.PAYMENT_EVENTS
    >
    refundRes?: BlazepayRefundModels.IRefundData
    deviceId: string
    currentUserId: string
    reason: string | null
    transaction: ITransaction
  }) => {
    if (paymentRes) {
      const transactionAmount = Math.abs(transaction.amount)
      if (transactionAmount == null) throw new Error('Original transaction amount is not found')

      return {
        deviceId,
        transactionAmount,
        currentShiftId: currentUserId,
        paymentId: paymentRes.id,
        reason,
      }
    } else if (refundRes) {
      return {
        deviceId,
        transactionAmount: refundRes.attributes.requestedAmount,
        currentShiftId: currentUserId,
        paymentId: refundRes.attributes.paymentId,
        reason,
      }
    } else {
      throw new Error('Original payment not found. Please use a different refund method.')
    }
  },
}
