import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { POSStore } from '../../stores/POSStore'
import { CurrencyInput } from '../../components/CurrencyInput'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { ReceiptModal } from './Receipt/ReceiptModal'
import { notification, Input, Row, Col } from 'antd'
import { browserHistory } from 'react-router'
import { Routes } from '../../constants/Routes'
import { BlazeButton } from '../../components/CustomButtons/BlazeButton'
import ButtonGroup from 'antd/lib/button/button-group'
import { CashCounterModal } from './CashCount/CashCounterModal'
import { InfoCircleFilled, LockFilled } from '@ant-design/icons'
import { THEME_COLORS } from '@getgreenline/shared'
import Logo from '../../images/logos/Greenline_darkBG-trans-green-text.svg'
import ArrowBack from '../../images/logout_arrow.svg'
import { HelpLinkMenu } from '../../components/GrDropdown/HelpLinkMenu/HelpLinkMenu'
import { COLORS } from '../../constants/Colors'
import { HelpLinks } from '../../constants/ExternalLinks'
import { ShiftModels } from '@getgreenline/shifts'

interface Props {
  posStore?: POSStore
  currentCompanyStore?: CurrentCompanyStore
  location: any
}

interface State {
  startCash: number
  notes: string
  passcode: string
  isSaving?: boolean
  showNotes?: boolean
  isReceiptModalVisible: boolean
  lastShiftId?: number
  cashCounterObject: ShiftModels.CreateCashCount
  isCashCounterModalVisible: boolean
}

const MAX_FLOAT_VALUE = 999999

@inject('posStore', 'currentCompanyStore')
@observer
export class ShiftLogin extends React.Component<Props, State> {
  company = this.props.currentCompanyStore!.company!

  device = this.props.posStore!.device!

  onboarding: boolean

  constructor(props: Props) {
    super(props)
    this.onboarding = false
    this.state = {
      startCash: 0,
      isSaving: false,
      notes: '',
      passcode: '',
      showNotes: false,
      isReceiptModalVisible: false,
      lastShiftId: undefined,
      cashCounterObject: new ShiftModels.CreateCashCount(),
      isCashCounterModalVisible: false,
    }
  }

  componentDidMount() {
    const queryObject = this.props.location.query
    const lastShiftId = queryObject.lastShiftId

    if (queryObject.onboarding) {
      this.onboarding = true

      notification.info({
        message: 'Welcome onboard!',
        description:
          'By default your passcode is 1234. If you have changed it in the dashboard, feel free to change it.',
        duration: 0,
      })

      this.setState({ passcode: '1234', startCash: 30000 })
    }

    if (lastShiftId !== undefined) {
      this.setIsReceiptModalVisible(true)
      this.setLastShiftId(lastShiftId)
    }

    browserHistory.push(Routes.POS_SALE())
  }

  setIsReceiptModalVisible = (isReceiptModalVisible: boolean) =>
    this.setState({ isReceiptModalVisible })

  setLastShiftId = (lastShiftId: number) => this.setState({ lastShiftId })

  save() {
    this.setState({ isSaving: true })
    this.props
      .posStore!.createShift({
        registerPasscode: this.state.passcode,
        startCash: Math.abs(this.state.startCash),
        notes: this.state.notes,
      })
      .then(() => {
        this.props.posStore!.trackHoursWithPasscode(this.state.passcode!)
      })
      .catch((error) => {
        notification.error({
          message: 'Error',
          description: error.response.data.message || 'There was an error starting your shift',
          duration: 0,
        })

        this.setState({ isSaving: false })
      })
  }

  onSuccess = async () => {
    this.setState({ startCash: this.state.cashCounterObject.totalCashCount })
    this.setState({ isCashCounterModalVisible: false })
  }

  get isSignInButtonDisabled(): boolean {
    return !this.state.passcode
  }

  get signInButtonTitle(): string {
    if (!this.state.passcode) {
      return 'Passcode required'
    }

    return 'Start Shift'
  }

  render() {
    const { isSaving, startCash, passcode, showNotes, notes, isReceiptModalVisible, lastShiftId } =
      this.state

    return (
      <div id='pos-login'>
        <Row style={styles.mainRow} type='flex' justify='center' align='middle'>
          <Row type='flex' gutter={[48, 16]}>
            <Col xs={18} sm={18} md={10} lg={12} xxl={12} style={styles.mainColLeft}>
              <Row>
                <Row type='flex' className='mb-4'>
                  <img className='w-100' src={Logo} />
                </Row>
                <Row type='flex' style={styles.deviceInfo} justify='end' className='mt-4'>
                  <Row>
                    <Row>
                      <Row>
                        <span className='mb-2'>Location:</span>
                      </Row>
                      <Row>
                        <span>{this.props.posStore?.location.name}</span>
                      </Row>
                    </Row>
                    <Row className='mt-4'>
                      <Row>
                        <span className='mb-2'>Device ID:</span>
                      </Row>
                      <Row>
                        <span>{this.device.deviceId}</span>
                      </Row>
                    </Row>
                  </Row>
                </Row>
              </Row>
            </Col>
            <Col
              xs={20}
              sm={20}
              md={10}
              lg={12}
              xxl={12}
              className='login-form'
              style={styles.leftCol}
            >
              <form className='form'>
                <div className='form-group'>
                  <label>Starting float</label>
                  <div className='d-flex'>
                    <CurrencyInput
                      id='shift-login-input'
                      className='form-control w-75'
                      centValue={startCash}
                      maxValue={MAX_FLOAT_VALUE}
                      onlyPositiveNumber
                      onChange={(value) => {
                        this.setState({ startCash: value })
                        this.state.cashCounterObject.setCashCount('default')
                      }}
                      style={styles.startFloatInput}
                    />
                    <button
                      className='btn btn-light w-50'
                      onClick={(e) => {
                        e.preventDefault()
                        this.setState({ isCashCounterModalVisible: true })
                      }}
                      style={styles.calcButton}
                    >
                      Calculator
                    </button>
                  </div>
                  {this.state.startCash ? (
                    <div className='mt-2 mb-4' style={styles.startCash}>
                      <InfoCircleFilled className='mr-2 mb-2' />
                      <small>Calculator erases if Starting Float is manually updated</small>
                    </div>
                  ) : null}

                  {this.onboarding && (
                    <Popover
                      className='background-shadow'
                      placement='left'
                      isOpen={true}
                      target='shift-login-input'
                    >
                      <PopoverHeader>Start your shift</PopoverHeader>
                      <PopoverBody>
                        A starting float is required. For instance, type: $300.00
                      </PopoverBody>
                    </Popover>
                  )}
                </div>
                {showNotes ? (
                  <div className='form-group'>
                    <label>
                      Notes{' '}
                      <a
                        style={styles.addNotes}
                        onClick={() => this.setState({ notes: '', showNotes: false })}
                      >
                        Hide
                      </a>
                    </label>
                    <Input
                      id='shift-login-input'
                      value={notes}
                      onChange={(e) => this.setState({ notes: e.target.value })}
                      style={styles.addNotesInput}
                    />
                  </div>
                ) : (
                  <div className='mt-2 mb-3'>
                    <a style={styles.addNotes} onClick={() => this.setState({ showNotes: true })}>
                      Add notes (optional)
                    </a>
                  </div>
                )}

                <div className='form-group'>
                  <label>Your passcode</label>
                  <Input
                    className='mb-4'
                    id='shift-login-input'
                    type='password'
                    value={passcode}
                    onChange={(e) => this.setState({ passcode: e.target.value })}
                    placeholder='Enter Passcode'
                    prefix={<LockFilled color={THEME_COLORS.BRAND.blackAlt} />}
                    style={styles.passcodeInput}
                  />
                </div>

                <ButtonGroup style={styles.buttonGroup}>
                  <BlazeButton
                    buttonProps={{
                      className: 'w-100',
                      size: 'large',
                      loading: isSaving,
                      disabled: this.isSignInButtonDisabled,
                      style: this.isSignInButtonDisabled
                        ? styles.startShiftButtonDisabled
                        : styles.startShiftButtonEnabled,
                      onClick: () => this.save(),
                    }}
                    titleProps={{ title: this.signInButtonTitle }}
                  />

                  <BlazeButton
                    buttonProps={{
                      className: 'w-100',
                      size: 'large',
                      disabled: this.state.lastShiftId === undefined,
                      onClick: () => this.setIsReceiptModalVisible(true),
                      style:
                        this.state.lastShiftId === undefined
                          ? styles.lastShiftButtonDisabled
                          : styles.lastShiftButtonEnabled,
                    }}
                    titleProps={{ title: 'View last shift' }}
                  />
                </ButtonGroup>
              </form>
              <div className='float-right mt-4'>
                <HelpLinkMenu
                  style={styles.help}
                  helpLinkMenus={[
                    {
                      href: HelpLinks.STARTING_SHIFT,
                      title: 'Starting a shift',
                    },
                    {
                      href: HelpLinks.ENDING_SHIFT,
                      title: 'Ending a shift',
                    },
                  ]}
                />
              </div>
            </Col>
            <Row className='mt-4 w-100'>
              <Col
                xs={{ span: 20, offset: 0 }}
                sm={{ span: 20, offset: 0 }}
                md={{ span: 12, offset: 9 }}
                lg={{ span: 10, offset: 12 }}
              >
                <div style={styles.logout} className='w-100 text-center'>
                  <img src={ArrowBack} />
                  <span onClick={this.props.posStore!.logout}>Logout of this device</span>
                </div>
              </Col>
            </Row>
          </Row>
        </Row>

        {isReceiptModalVisible && lastShiftId !== undefined && (
          <ReceiptModal
            lastShiftId={lastShiftId}
            visible={isReceiptModalVisible}
            setIsReceiptModalVisible={this.setIsReceiptModalVisible}
          />
        )}

        <CashCounterModal
          titlesFor={{
            modal: 'Start Float Count',
          }}
          visible={this.state.isCashCounterModalVisible}
          onSuccess={this.onSuccess}
          cashCountObject={this.state.cashCounterObject}
          closable={false}
        />
      </div>
    )
  }
}

const styles: { [key: string]: React.CSSProperties } = {
  deviceInfo: { color: THEME_COLORS.MONO.monoFf },
  mainRow: {
    height: '100%',
  },
  mainColLeft: {
    alignSelf: 'stretch',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftCol: {
    backgroundColor: THEME_COLORS.MONO.monoFf,
    padding: '24px 23px',
    borderRadius: 10,
    color: THEME_COLORS.BRAND.blackAlt,
    height: '100%',
    minWidth: 'fit-content',
  },
  startFloatInput: {
    fontSize: 14,
    height: 40,
    borderRadius: '4px',
    border: `1px solid ${COLORS.borderGreyC4} !important`,
    backgroundColor: THEME_COLORS.MONO.monoee,
    color: THEME_COLORS.BRAND.blackAlt,
  },
  calcButton: {
    color: THEME_COLORS.BRAND.retailAlt,
    border: 'none',
    background: 'none',
    fontSize: 14,
  },
  startCash: {
    color: THEME_COLORS.BRAND.blackAlt,
  },
  addNotes: { color: THEME_COLORS.BRAND.retailAlt, fontSize: 12 },
  addNotesInput: {
    fontSize: 14,
    height: 40,
    borderRadius: '4px',
    border: `1px solid ${COLORS.borderGreyC4} !important`,
    color: THEME_COLORS.BRAND.blackAlt,
  },
  passcodeInput: {
    fontSize: 14,
    height: 40,
    borderRadius: '4px',
    border: `1px solid ${COLORS.borderGreyC4} !important`,
    color: THEME_COLORS.BRAND.blackAlt,
  },
  buttonGroup: { display: 'flex', flexDirection: 'row' },
  startShiftButtonDisabled: {
    color: THEME_COLORS.MONO.mono99,
    border: `1px solid ${COLORS.borderGreyC4}`,
    backgroundColor: THEME_COLORS.MONO.monodd,
    borderRadius: 4,
    gap: 10,
    fontSize: 14,
  },
  startShiftButtonEnabled: {
    color: THEME_COLORS.MONO.monoFf,
    border: `1px solid ${THEME_COLORS.BRAND.retailAlt}`,
    backgroundColor: THEME_COLORS.BRAND.retailAlt,
    borderRadius: 4,
    gap: 10,
    fontSize: 14,
  },
  lastShiftButtonEnabled: {
    backgroundColor: THEME_COLORS.MONO.monoFf,
    border: `1px solid ${COLORS.borderGreyC4} !important`,
    borderRadius: 4,
    gap: 10,
    color: THEME_COLORS.BRAND.blackAlt,
    fontSize: 14,
  },
  lastShiftButtonDisabled: {
    color: THEME_COLORS.MONO.mono99,
    border: `1px solid ${COLORS.borderGreyC4}`,
    backgroundColor: THEME_COLORS.MONO.monodd,
    borderRadius: 4,
    gap: 10,
    fontSize: 14,
  },
  logout: {
    color: THEME_COLORS.MONO.monoFa,
    backgroundColor: THEME_COLORS.BRAND.black,
    fontSize: 16,
    cursor: 'pointer',
    width: '100%',
    height: '100%',
  },
  help: {
    border: 1,
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: THEME_COLORS.BRAND.blackAlt,
    color: THEME_COLORS.BRAND.blackAlt,
    fontSize: 14,
  },
}
