import * as React from 'react'
import * as Tooltip from 'react-tooltip'
import { API, IUserResponse } from '@getgreenline/homi-shared'
import { LoadingAnimation } from '../../../components/LoadingAnimation'

interface Props {
  companyId: number
}

interface State {
  users?: IUserResponse[]
}

export class CompanyUsers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.refreshData()
  }

  async refreshData() {
    const { companyId } = this.props
    const users = await API.getCompanyUsers(companyId)
    this.setState({
      users: users,
    })
  }

  render() {
    const { users } = this.state

    if (!users) {
      return <LoadingAnimation />
    }

    return (
      <div className='background-shadow p-4'>
        <h5 className='text-muted'>
          <span className='mr-2'>Employees</span>
        </h5>
        <br />
        <table className='table table-hover table-sm'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>ID</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className='text-muted'>{user.id}</td>
                  <td className='text-right'>
                    <span
                      className='fa fa-bars text-muted'
                      style={{ cursor: 'pointer' }}
                      data-tip
                      data-for={'user-' + user.id}
                    />
                    <Tooltip id={'user-' + user.id} place='right' effect='solid'>
                      <pre className='text-left text-white'>{JSON.stringify(user, null, 2)}</pre>
                    </Tooltip>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
