import {
  CreatePayment,
  ICreateTransaction,
  IPayment,
  ITransaction,
  PaymentTypeIds,
} from '@getgreenline/homi-shared'
import { PaymentOptionsEnabled } from '../../../../../utilities/LocalStorage'

export const getActivatedPaymentOptions = (paymentOptionsEnabled: PaymentOptionsEnabled) => {
  return Object.entries(paymentOptionsEnabled).reduce((acc, cur) => {
    const key = cur[0] as keyof PaymentOptionsEnabled
    const value = cur[1]

    if (value) {
      acc.push(PaymentTypeIds[key])
    }

    return acc
  }, [] as PaymentTypeIds[])
}

export const isEcomPaymentServiceTransaction = (
  transaction: ITransaction | ICreateTransaction,
): boolean => {
  if (!transaction.metadata) {
    return false
  }

  const parsedMetadata = JSON.parse(transaction.metadata)

  return 'paymentOptionId' in parsedMetadata
}

export const shouldEnableOnlinePaymentOption = ({
  payment,
  originalPayment,
}: {
  payment: CreatePayment
  originalPayment?: IPayment
}): boolean => {
  if (!originalPayment) {
    return false
  }

  const ecomTrxsFromOriginalPayment = originalPayment.transactions.filter((transaction) =>
    isEcomPaymentServiceTransaction(transaction),
  )

  if (ecomTrxsFromOriginalPayment.length === 0) {
    return false
  }

  const currentBlazpayTrxs = payment.transactions.filter(
    (transaction) => transaction.paymentTypeId === PaymentTypeIds.online,
  )
  return currentBlazpayTrxs.length < ecomTrxsFromOriginalPayment.length
}
