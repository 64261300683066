import { action, computed, observable } from 'mobx'
import {
  WorldpayGlobalModels,
  WorldpayLaneModels,
  WorldpayTerminalAPI,
} from '@getgreenline/payments'

import { captureError } from '../utilities/logging'
import { parseErrorMsg } from '../utilities/helpers'
import { message } from 'antd-v4'

export const MAX_WORLDPAY_RETRIES = 60

const TERMINALS_LOCAL_STORAGE_KEY = 'worldpayTerminals'
const SELECTED_TERMINAL_LOCAL_STORAGE_KEY = 'selectedWorldpayTerminal'

export class WorldpayStore {
  @observable isSendingCreateRequests = false
  @observable hasSuccessfullyCreated = false
  @observable terminalList: WorldpayLaneModels.Internal.ITerminalSetupResponseContract[] = []
  @observable selectedTerminal:
    | WorldpayLaneModels.Internal.ITerminalSetupResponseContract
    | undefined = undefined

  worldpayInterval: ReturnType<typeof setInterval> | undefined
  worldpayRetries = 0
  tenantId: string
  retailerId: string
  createConnectionMessageDisplayed: boolean

  constructor(companyId: number, locationId: number) {
    this.tenantId = companyId.toString()
    this.retailerId = locationId.toString()
    this.createConnectionMessageDisplayed = false
  }

  @action
  setIsSendingCreateRequests = (isSendingCreateRequests: boolean) => {
    this.isSendingCreateRequests = isSendingCreateRequests
  }

  @action
  setHasSuccessfullyCreated = (hasSuccessfullyCreated: boolean) => {
    this.hasSuccessfullyCreated = hasSuccessfullyCreated

    if (hasSuccessfullyCreated && !this.createConnectionMessageDisplayed) {
      this.createConnectionMessageDisplayed = true
      message.success('Successfully created the connection!')
    }
  }

  @action
  setTerminalList = (
    newTerminalList: WorldpayLaneModels.Internal.ITerminalSetupResponseContract[],
  ) => {
    this.terminalList = newTerminalList
  }

  @action setSelectedTerminal = (
    terminal?: WorldpayLaneModels.Internal.ITerminalSetupResponseContract,
  ) => {
    this.selectedTerminal = terminal

    if (terminal) {
      localStorage.setItem(SELECTED_TERMINAL_LOCAL_STORAGE_KEY, JSON.stringify(terminal))
    } else {
      localStorage.removeItem(SELECTED_TERMINAL_LOCAL_STORAGE_KEY)
    }
  }

  incrementWorldpayRetries = () => {
    if (this.worldpayRetries >= MAX_WORLDPAY_RETRIES) {
      this.cancelWorldpayConnectionCheck()
      return
    }

    this.worldpayRetries++
  }

  cancelWorldpayConnectionCheck = () => {
    this.worldpayRetries = 0
    this.setIsSendingCreateRequests(false)

    if (this.worldpayInterval) {
      this.worldpayInterval = undefined
    }
  }

  @action
  getLatestTerminals = async () => {
    try {
      const params = {
        tenantId: this.tenantId,
        retailerId: this.retailerId,
      }

      const { data: terminals } = await WorldpayTerminalAPI.getTerminals(params)
      this.terminalList = terminals

      localStorage.setItem(TERMINALS_LOCAL_STORAGE_KEY, JSON.stringify(terminals))

      return terminals
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while retrieving the terminal list')
    }
  }

  editTerminal = async (
    connectionId: string,
    payload: { language: WorldpayGlobalModels.Enums.Language },
  ) => {
    try {
      const params = {
        tenantId: this.tenantId,
        retailerId: this.retailerId,
        connectionId,
        payload,
      }
      await WorldpayTerminalAPI.updateTerminalInfo(params)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while editing a terminal')
    }
  }

  @action
  deleteTerminal = async (connectionId: string) => {
    try {
      const params = {
        tenantId: this.tenantId,
        retailerId: this.retailerId,
        connectionId,
      }
      const { data: terminals } = await WorldpayTerminalAPI.unpairTerminal(params)
      this.setTerminalList(terminals)

      localStorage.setItem(TERMINALS_LOCAL_STORAGE_KEY, JSON.stringify(terminals))

      return terminals
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while deleting a terminal')
    }
  }

  createTerminal = async (payload: {
    activationCode: string
    laneId: number
    terminalId: string
    processor: WorldpayLaneModels.Enums.Processor
    description?: string
  }) => {
    this.createConnectionMessageDisplayed = false
    this.setHasSuccessfullyCreated(false)
    this.setIsSendingCreateRequests(true)

    try {
      const params = {
        tenantId: this.tenantId,
        retailerId: this.retailerId,
        payload,
      }

      const response = await WorldpayTerminalAPI.asyncPairTerminal(params)

      await this.checkWorldpayConnectionCreated({
        connectionId: response.data.connectionId,
        operationRetryInSec: response.data.operationRetryInSec,
      })
    } catch (error) {
      if (!this.createConnectionMessageDisplayed) {
        this.createConnectionMessageDisplayed = true
        message.error(
          parseErrorMsg(error) || 'An error occured while creating a connection to a terminal',
        )
      }
      this.setIsSendingCreateRequests(false)
    }
  }

  checkWorldpayConnectionCreated = async ({
    connectionId,
    operationRetryInSec,
  }: WorldpayLaneModels.Internal.ITerminalAsyncResponseContract) => {
    if (this.worldpayInterval) return

    this.worldpayInterval = setInterval(async () => {
      this.processNewTerminalState(connectionId)
      this.incrementWorldpayRetries()
    }, operationRetryInSec * 1000)
  }

  processNewTerminalState = async (connectionId: string) => {
    try {
      const response = await this.getLatestTerminalInformation(connectionId)
      const responseNotReady = WorldpayGlobalModels.Enums.WorldpayResponseStatuses.RESPONSE_READY

      if (response.statusCode === responseNotReady) {
        clearInterval(this.worldpayInterval!)
        this.setHasSuccessfullyCreated(true)
        this.cancelWorldpayConnectionCheck()
        this.getLatestTerminals()
      }
    } catch (error) {
      clearInterval(this.worldpayInterval!)
      this.cancelWorldpayConnectionCheck()
      const errorMessage = parseErrorMsg(error) || 'Could not retrieve terminal information!'
      captureError(errorMessage, error)
      message.error(errorMessage)
    }
  }

  getLatestTerminalInformation = async (connectionId: string) => {
    const response = await WorldpayTerminalAPI.getTerminal({
      tenantId: this.tenantId,
      retailerId: this.retailerId,
      connectionId,
    })
    return response
  }

  refreshTerminal = async (connectionId: string) => {
    try {
      const response = await this.getLatestTerminalInformation(connectionId)

      if (
        response.statusCode === WorldpayGlobalModels.Enums.WorldpayResponseStatuses.RESPONSE_READY
      ) {
        const terminalInformation =
          response.data as WorldpayLaneModels.Internal.ITerminalSetupResponseContract
        const updatedTerminalList = this.terminalList.map((terminal) => {
          if (terminal.connectionId === terminalInformation.connectionId) return terminalInformation
          return terminal
        })
        this.setTerminalList(updatedTerminalList)
      }
    } catch (error) {
      const errorMessage = parseErrorMsg(error) || 'Could not get refresh terminal!'
      captureError(errorMessage, error)
      message.error(errorMessage)
    }
  }

  checkWorldpayTerminalStatus = async (
    terminal: WorldpayLaneModels.Internal.ITerminalSetupResponseContract,
  ) => {
    const { connectionId } = terminal

    const params = {
      connectionId,
      tenantId: this.tenantId,
      retailerId: this.retailerId,
    }

    const response = await WorldpayTerminalAPI.getConnectionStatus(params)

    return response.data.status
  }

  @action
  setCachedConnectionsAndSelectedTerminal = () => {
    this.terminalList = this.cachedTerminals
    this.selectedTerminal = this.cachedSelectedTerminal
  }

  @computed
  private get cachedTerminals(): WorldpayLaneModels.Internal.ITerminalSetupResponseContract[] {
    const connections = localStorage.getItem(TERMINALS_LOCAL_STORAGE_KEY)

    return connections ? JSON.parse(connections) : []
  }

  @computed
  private get cachedSelectedTerminal(): WorldpayLaneModels.Internal.ITerminalSetupResponseContract {
    const connections = localStorage.getItem(SELECTED_TERMINAL_LOCAL_STORAGE_KEY)

    return connections ? JSON.parse(connections) : undefined
  }
}
