const hasProperty = <T extends unknown>(
  error: unknown,
  property: string,
): error is { [key: string]: T } => {
  return typeof error === 'object' && error !== null && property in error
}

export const getErrorName = (error: unknown) => {
  if (error instanceof Error || hasProperty(error, 'name')) return error.name
  return 'Error'
}

export const errorIsType = (error: unknown, type: string) => getErrorName(error) === type

export interface IAxiosError {
  response?: {
    data?: {
      message?: string
    }
  }
}

export const isAxiosError = (error: unknown): error is IAxiosError =>
  hasProperty<{ [key: string]: unknown }>(error, 'response') &&
  hasProperty(error.reponse, 'data') &&
  hasProperty<string | undefined>(error.response.data, 'message')

export const getErrorMessage = (error: unknown): string =>
  (isAxiosError(error) && error?.response?.data?.message) ||
  (hasProperty<string | undefined>(error, 'message') && error.message) ||
  `${error}`
