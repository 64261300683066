import React, { useState, useEffect, PropsWithChildren } from 'react'
import { ProvinceEnum } from '@getgreenline/homi-shared'
import { GrRegex, parseErrorMsg } from '../../utilities/helpers'
import { Button, Layout, Icon, Tag, message, Input, Row, Select } from 'antd'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import '../Dashboard/index.scss'
import { browserHistory } from 'react-router'
import { IBaseCursorPaginationFilter, ICursorPagination } from '@getgreenline/shared'
import { CompaniesApi } from '@getgreenline/companies'
import {
  CustomTablePaginationV2,
  TPaginationPosition,
} from '../../components/CustomTablePaginationV2'
import { BlazeButton } from '../../components/CustomButtons/BlazeButton'
import { ICompany, ICompanyFilter } from '@getgreenline/companies/build/models'
import * as ocs from '@getgreenline/ocs'
import Endpoints from '../../constants/Endpoints'

interface Props {
  currentCompanyStore?: CurrentCompanyStore
}

export const AdminCompaniesV2: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const LIMIT = 8
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [mode, setMode] = useState<'paid' | 'free'>('paid')
  const [pagination, setPagination] = useState<ICursorPagination>({
    nextCursor: null,
    prevCursor: null,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [appliedQuery, setAppliedQuery] = useState('')
  const [selectedProvince, setSelectedProvince] = useState<ProvinceEnum | undefined>(undefined)
  const [appliedSelectedProvince, setAppliedSelectedProvince] =
    useState<ProvinceEnum | undefined>(undefined)
  const [paginationReset, setPaginationReset] = useState(false)

  useEffect(() => {
    refreshData()
  }, [])

  const refreshData = async (
    cursor?: string | null,
    filter?: string,
    province?: ProvinceEnum | undefined,
  ) => {
    try {
      setIsLoading(true)
      const payload: ICompanyFilter & IBaseCursorPaginationFilter = {
        limit: LIMIT,
        province,
      }
      if (cursor) payload.cursor = cursor
      if (!filter) filter = query
      if (Number.isInteger(Number(filter))) {
        payload.id = filter
        payload.limit = 1
      } else if (
        GrRegex.isValidCRSA(filter) &&
        [ProvinceEnum.ON].includes(province || ProvinceEnum.ON)
      ) {
        const { items: retailers } = await ocs.getRetailers(Endpoints.AUTHZ_GATEWAY_URL, {
          retailerCRSA: filter,
        })
        if (!retailers.length) return
        payload.id = retailers[0].tenantId
        payload.limit = 1
      } else {
        payload.name = filter
      }
      const { data, pagination } = await CompaniesApi.getCursorPaginatedCompanies(payload)
      setPagination(pagination)
      setCompanies(data)
      return data
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while retrieving the customer list')
    } finally {
      setIsLoading(false)
    }
  }

  const applyFiltersOnPageChange = async (cursor?: string | null) => {
    await refreshData(cursor, appliedQuery, appliedSelectedProvince)
  }

  const onSearchByEnterKey = () => {
    const isQueryExist = query.trim().length > 0

    setAppliedQuery(query)
    setAppliedSelectedProvince(selectedProvince)
    setPaginationReset(true)

    if (isQueryExist) {
      refreshData('', query, selectedProvince)
    } else {
      message.warn(`Please type company's ID or name`)
    }
  }

  const provinces = [
    {
      text: ProvinceEnum.AB,
      value: ProvinceEnum.AB,
    },
    {
      text: ProvinceEnum.BC,
      value: ProvinceEnum.BC,
    },
    {
      text: ProvinceEnum.MB,
      value: ProvinceEnum.MB,
    },
    {
      text: ProvinceEnum.ON,
      value: ProvinceEnum.ON,
    },
    {
      text: ProvinceEnum.SK,
      value: ProvinceEnum.SK,
    },
    {
      text: ProvinceEnum.NU,
      value: ProvinceEnum.NU,
    },
    {
      text: ProvinceEnum.NT,
      value: ProvinceEnum.NT,
    },
  ]

  const setPaginationFirstPage = (paginationReset: boolean) => {
    setPaginationReset(paginationReset)
  }

  let companiesToDisplay: ICompany[] = companies || []
  if (mode === 'paid') {
    companiesToDisplay = companiesToDisplay.filter((company) => company.isPaid)
  } else if (mode === 'free') {
    companiesToDisplay = companiesToDisplay.filter((company) => !company.isPaid)
  }

  return (
    <>
      <Layout>
        <Layout.Sider
          className='background-shadow'
          width={300}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            background: '#fff',
            left: 0,
          }}
        >
          <div style={{ padding: 10, height: '90%', overflowY: 'scroll' }}>
            <div className='d-flex row ml-1'>
              <Button
                key='paid'
                type={mode === 'paid' ? 'primary' : 'default'}
                onClick={() => setMode('paid')}
                style={{ marginRight: 5 }}
              >
                Paid
              </Button>
              <Button
                key='free'
                type={mode === 'free' ? 'primary' : 'default'}
                onClick={() => setMode('free')}
                style={{ marginRight: 5 }}
              >
                Free
              </Button>
            </div>
            <div className='d-flex row ml-1 mt-2'>
              <Input
                className='gr-filter-item gr-filter-gutter'
                style={{ width: '90%' }}
                placeholder='Search by ID, name, or license (ON)'
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onPressEnter={onSearchByEnterKey}
              />

              <Select<ProvinceEnum | undefined>
                className='gr-filter-item gr-filter-gutter'
                style={{ width: '40%' }}
                placeholder='Province'
                allowClear
                dropdownMatchSelectWidth={false}
                value={selectedProvince}
                onChange={(value) => setSelectedProvince(value)}
              >
                {provinces.map((statusOption, index) => (
                  <Select.Option key={index} value={statusOption.value}>
                    {statusOption.text}
                  </Select.Option>
                ))}
              </Select>

              <Row className='gr-filter-gutter'>
                <BlazeButton
                  buttonProps={{
                    type: 'primary',
                    loading: isLoading,
                    onClick: () => {
                      refreshData('', query, selectedProvince)
                      setAppliedQuery(query)
                      setAppliedSelectedProvince(selectedProvince)
                      setPaginationReset(true)
                    },
                  }}
                  titleProps={{ title: 'Apply' }}
                />

                <BlazeButton
                  buttonProps={{
                    className: 'text-secondary',
                    type: 'link',
                    onClick: async () => {
                      setQuery('')
                      setAppliedQuery('')
                      setSelectedProvince(undefined)
                      setAppliedSelectedProvince(undefined)
                      setPaginationReset(true)
                      await refreshData(null, '', undefined)
                    },
                  }}
                  titleProps={{ title: 'Reset' }}
                />
              </Row>
            </div>
            <CustomTablePaginationV2
              hideRowOptions={true}
              changePage={(cursor) => applyFiltersOnPageChange(cursor)}
              data={companies || []}
              paginationPosition={TPaginationPosition.BOTTOM}
              pagination={pagination}
              paginationReset={paginationReset}
              setPaginationFirstPage={setPaginationFirstPage}
              limit={LIMIT}
            >
              <div>
                {companiesToDisplay.map((company) => {
                  return (
                    <div
                      key={company.id}
                      className={`${!!company.bannerMessage && 'text-danger'}`}
                      style={{ paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}
                      onClick={() => {
                        browserHistory.push(`/admin/v2/companies/${company.id}`)
                      }}
                    >
                      <>
                        <div>
                          {!!company.bannerMessage && <Icon type='warning' />} {company.id}:{' '}
                          {company.name}
                        </div>
                        <div>
                          <Tag>{company.province || '-'}</Tag>
                          {company.auditCompleteMode === 'replace' ? (
                            <Tag color='orange'>Audit - replace</Tag>
                          ) : (
                            <Tag color='green'>Audit - relative</Tag>
                          )}
                          {company.inventoryCostOption === 'lastCost' ? (
                            <Tag color='red'>Last cost</Tag>
                          ) : (
                            <Tag color='green'>Weighted average</Tag>
                          )}
                        </div>
                      </>
                    </div>
                  )
                })}
              </div>
            </CustomTablePaginationV2>
          </div>
        </Layout.Sider>
        <Layout style={{ marginLeft: 300, borderLeft: '1px solid', backgroundColor: 'white' }}>
          {children}
        </Layout>
      </Layout>
    </>
  )
}
