import { IReactionDisposer, observable, action, computed } from 'mobx'
import { Subscription } from 'rxjs'
import { appsyncApi } from '../../../appsync/graphql/api'
import { AppSyncEventService } from './AppSyncEventService'
import {
  IAppSyncCallbacks,
  ISubscriptionCallbacks,
} from '../../../appsync/graphql/interfaces/subscription'
import { IEventService } from './interfaces'
import {
  BlazepayAPI,
  BlazepayConfigModels,
  BlazepayEnums,
  BlazepayPaymentModels,
  BlazepayPaymentOptionModels,
} from '@getgreenline/payments'
import { captureError } from '../../../utilities/logging'
import { parseErrorMsg } from '../../../utilities/helpers'
import { ConfigEventService } from './ConfigEventService'
import { LocalStorage } from '../../../utilities/LocalStorage'
import { PaymentTypeIds } from '@getgreenline/homi-shared'
import { TerminalEventService } from './TerminalEventService'
import { paymentNameFormatter } from '@getgreenline/shared'

export class PaymentOptionEventService extends AppSyncEventService implements IEventService {
  subscription?: Subscription
  private disposer?: IReactionDisposer

  @observable paymentOptions =
    observable.map<
      BlazepayEnums.Provider,
      BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData
    >()

  @observable ecomPaymentOptions =
    observable.map<
      BlazepayEnums.Provider,
      BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData
    >()

  config: ConfigEventService
  terminal: TerminalEventService

  constructor(private merchantId: string, private terminalId: string) {
    super()
    this.config = new ConfigEventService(this.merchantId, this.syncPaymentOptions)
    this.terminal = new TerminalEventService(
      this.merchantId,
      this.terminalId,
      this.syncPaymentOptions,
    )
  }

  isTurnedOn(provider: BlazepayEnums.Provider) {
    const paymentOption = this.paymentOptions.get(provider)
    const config = this.config.configs.get(provider)
    const terminal = this.terminal.terminals.get(provider)
    if (!paymentOption || !config || !terminal) return false

    const isPosAvailable = paymentOption.attributes.posAvailable
    const isApproved = config.attributes.status === BlazepayConfigModels.ConfigStatus.APPROVED
    const posEnabled = !!config.attributes.posEnabled
    const isTerminalAttached = !terminal.attributes.isDeleted

    return isPosAvailable && isApproved && posEnabled && isTerminalAttached
  }

  formatPaymentOptionName(paymentTypeId: PaymentTypeIds) {
    const defaultPaymentName = paymentNameFormatter(paymentTypeId)
    const provider = BlazepayPaymentModels.getProvider(paymentTypeId)
    if (!provider) return defaultPaymentName

    const paymentOption = this.paymentOptions.get(provider)
    if (!paymentOption) return defaultPaymentName

    return paymentOption.attributes.posDisplayName || defaultPaymentName
  }

  getMinMaxChargeAmount({
    type,
    isRefund,
    paymentTypeId,
  }: {
    type: 'min' | 'max'
    isRefund: boolean
    paymentTypeId: PaymentTypeIds
  }): number | null {
    if (isRefund) return null

    const provider = BlazepayPaymentModels.getProvider(paymentTypeId)
    if (!provider) return null

    const paymentOption = this.paymentOptions.get(provider)
    if (!paymentOption) return null

    return type === 'min'
      ? paymentOption.attributes.minimumChargeAmount
      : paymentOption.attributes.maximumChargeAmount
  }

  getPaymentOptionIcon(paymentTypeId: PaymentTypeIds) {
    const provider = BlazepayPaymentModels.getProvider(paymentTypeId)
    if (!provider) return

    const paymentOption = this.paymentOptions.get(provider)
    return paymentOption?.attributes.iconUrl || undefined
  }

  private appSyncCallback(): IAppSyncCallbacks<BlazepayPaymentOptionModels.IPaymentOptionSubscriptionResponseContract> {
    return {
      next: ({ data }) => {
        this.mapResources(data.updatedPaymentOption.data)
        this.syncPaymentOptions()
      },
      error: (error) => {
        captureError(parseErrorMsg(error) || 'payment_option_event.error', error, false)
      },
    }
  }

  subscribe<T>(props?: ISubscriptionCallbacks<T>) {
    this.reactToAppSyncConnectionChange()
    this.subscription = appsyncApi
      .subscribeFor(this.teardown)
      .paymentOptionEvents(this.appSyncCallback())

    this.config.subscribe()
    this.terminal.subscribe()

    return props?.after()
  }

  unsubscribe() {
    this.subscription?.unsubscribe()
    this.config.subscription?.unsubscribe()
    this.terminal.subscription?.unsubscribe()
  }

  private teardown = () => {
    this.disposer?.()
  }

  private reactToAppSyncConnectionChange() {
    this.disposer = super.setDisposer(this.disposer, async (connectionState) => {
      await super.fetchRecentData(connectionState, this.keepDataUpToDate)
    })
  }

  private keepDataUpToDate = async () => {
    const paymentOptionsRes = await BlazepayAPI.getPaymentOptions({ merchantId: this.merchantId })

    this.mapResources(paymentOptionsRes.data)

    this.syncPaymentOptions()
  }

  private syncPaymentOptions = () => {
    const options = LocalStorage.getPaymentOptions()

    Object.keys(options).forEach((optionKey) => {
      const paymentTypeId = PaymentTypeIds[optionKey]
      const provider = BlazepayPaymentModels.getProvider(PaymentTypeIds[optionKey])
      if (!provider) return
      LocalStorage.setPaymentOption(paymentTypeId, this.isTurnedOn(provider))
    })
  }

  @action
  mapResources<T extends BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData>(data: T | T[]) {
    const deserializedData = super.deserializeData<T>(data)

    if (Array.isArray(deserializedData)) {
      deserializedData.forEach((item) => this.mapSingleSource(item))
    } else {
      this.mapSingleSource(deserializedData)
    }
  }

  @action
  private mapSingleSource<T extends BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData>(
    item: T,
  ) {
    const { availableCountries, ecomAvailable, posAvailable, provider } = item.attributes

    if (availableCountries.includes(BlazepayEnums.CountryCode.CA)) {
      posAvailable ? this.paymentOptions.set(provider, item) : this.paymentOptions.delete(provider)
      ecomAvailable
        ? this.ecomPaymentOptions.set(provider, item)
        : this.ecomPaymentOptions.delete(provider)
    } else {
      this.paymentOptions.delete(provider)
      this.ecomPaymentOptions.delete(provider)
    }
  }

  @computed
  get ecomPaymentOptionsArray() {
    return Array.from(this.ecomPaymentOptions.values())
  }
}
