import * as React from 'react'
import { API, ICreateExternalSource } from '@getgreenline/homi-shared'
import { Card, Empty, Row, Col, Tag, PageHeader, Icon, message, Tooltip, Drawer } from 'antd'
import { useEffect, useState } from 'react'
import { useClipboard } from 'use-clipboard-copy'
import { browserHistory } from 'react-router'
import Title from 'antd/lib/typography/Title'
import ExternalSourceForm from './ExternalSourceForm'
import { BlazeButton } from '../../../components/CustomButtons/BlazeButton'
import { PermissionDrawer } from './PermissionDrawer'
import { AdminExternalSourceStore } from './AdminExternalSourceStore'
import { parseErrorMsg } from '../../../utilities/helpers'

const adminExternalSourceStore = new AdminExternalSourceStore()

export const AdminExternalSources = () => {
  useEffect(() => {
    ;(async () => {
      await getExternalSources()
      await getAllExternalPermissions()
    })()
  }, [])

  const [isPermissionDrawerOpen, setIsPermissionDrawerOpen] = useState(false)
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const clipboard = useClipboard()
  const permissionTitle = 'View / set permissions'

  const copyToClipboard = (text: string) => {
    clipboard.copy(text)
    message.success(`API key copied`)
  }

  const getExternalSources = async () => {
    setIsLoading(true)

    try {
      const externalSources = await API.getExternalSources()
      adminExternalSourceStore.setMappedExternalSources(externalSources)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while loading external sources')
    }

    setIsLoading(false)
  }

  const createExternalSource = async (externalSourceObject: ICreateExternalSource) => {
    try {
      const newExternalSource = await API.createExternalSource(externalSourceObject)
      message.success(`${newExternalSource.name} was successfully created`)

      await getExternalSources()
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while creating an external source')
    }
  }

  const updateExternalSource = async (externalSourceObject: ICreateExternalSource) => {
    try {
      if (!adminExternalSourceStore.selectedExternalSource) {
        return message.error('Select an external source first')
      }
      const externalSourceId = adminExternalSourceStore.selectedExternalSource.id
      const newExternalSource = await API.updateExternalSourceById(
        externalSourceId,
        externalSourceObject,
      )

      message.success(`${newExternalSource.name} was successfully updated`)

      await getExternalSources()
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while updating an external source')
    }
  }

  const getAllExternalPermissions = async () => {
    const allExternalPermissions = await API.getExternalPermissions()
    adminExternalSourceStore.setAvailablePermissions(allExternalPermissions)
  }

  return (
    <div className='p-4'>
      <PageHeader
        style={{
          border: '1px solid rgb(235, 237, 240)',
        }}
        className='mb-4'
        onBack={() => browserHistory.goBack()}
        title='External Sources'
        subTitle='View and create external sources'
      />
      <Title>Create new external source</Title>
      <ExternalSourceForm
        labelCol={{ span: 7 }}
        formSpans={[12, 12]}
        adminExternalSourceStore={adminExternalSourceStore}
        shouldResetFields
        onSubmit={createExternalSource}
      />

      <Title>All External sources</Title>
      {isLoading ? (
        <Card loading={isLoading}></Card>
      ) : adminExternalSourceStore.externalSources.length === 0 ? (
        <Empty />
      ) : (
        <Row gutter={16} type='flex'>
          {adminExternalSourceStore.externalSources.map((externalSource) => {
            const { id, name, categoryName, apiKey, hexColor, isMarketplace } = externalSource

            return (
              <Col key={id} xxl={6} xl={6} lg={8} md={12} sm={24} xs={24}>
                <Card
                  title={
                    <div>
                      <div>{name}</div>
                      {hexColor === '' ? (
                        <Tag>No color selected</Tag>
                      ) : (
                        <Tag color={hexColor}>{hexColor}</Tag>
                      )}

                      {categoryName && <Tag>{categoryName}</Tag>}
                    </div>
                  }
                  className='mb-2'
                  extra={
                    <Tooltip title='Click to copy the API key'>
                      <Icon
                        type='copy'
                        key={`${id}-copy`}
                        onClick={() => copyToClipboard(apiKey)}
                      />
                    </Tooltip>
                  }
                >
                  <pre>
                    Name: {name}
                    <br />
                    API key: {apiKey}
                    <br />
                    Marketplace enabled: {isMarketplace.toString()}
                  </pre>

                  <BlazeButton
                    buttonProps={{
                      className: 'mr-2',
                      onClick: () => {
                        adminExternalSourceStore.setSelectedExternalSource(externalSource)
                        setIsPermissionDrawerOpen(true)
                      },
                    }}
                    titleProps={{
                      title: permissionTitle,
                    }}
                  />

                  <BlazeButton
                    buttonProps={{
                      onClick: () => {
                        adminExternalSourceStore.setSelectedExternalSource(externalSource)
                        setIsEditDrawerOpen(true)
                      },
                    }}
                    titleProps={{
                      title: 'Edit',
                    }}
                  />
                </Card>
              </Col>
            )
          })}
        </Row>
      )}

      {adminExternalSourceStore.selectedExternalSource !== undefined && (
        <PermissionDrawer
          title={permissionTitle}
          isDrawerOpen={isPermissionDrawerOpen}
          setIsDrawerOpen={setIsPermissionDrawerOpen}
          adminExternalSourceStore={adminExternalSourceStore}
        />
      )}

      {isEditDrawerOpen && (
        <Drawer
          title={`Edit${
            adminExternalSourceStore.selectedExternalSource
              ? ` - ${adminExternalSourceStore.selectedExternalSource.name}`
              : ''
          }`}
          maskClosable
          placement='right'
          closable={false}
          width={350}
          onClose={() => {
            setIsEditDrawerOpen(false)
            adminExternalSourceStore.setSelectedExternalSource()
          }}
          visible={isEditDrawerOpen}
        >
          <ExternalSourceForm
            existingExternalSource={adminExternalSourceStore.selectedExternalSource}
            labelCol={{ span: 9 }}
            formSpans={[24]}
            onSubmit={updateExternalSource}
          />
        </Drawer>
      )}
    </div>
  )
}
