import { IReactionDisposer, observable, action, when } from 'mobx'
import { Subscription } from 'rxjs'
import { appsyncApi } from '../../../appsync/graphql/api'
import { AppSyncEventService } from './AppSyncEventService'
import {
  IAppSyncCallbacks,
  ISubscriptionCallbacks,
} from '../../../appsync/graphql/interfaces/subscription'
import { IEventService } from './interfaces'
import {
  BlazepayPaymentModels,
  BlazepayAPI,
  BlazepayEnums,
  PaymentLineModels,
  paymentLineAPI,
} from '@getgreenline/payments'
import { v4 } from 'uuid'
import {
  API,
  TransactionContract,
  CreateTransaction,
  ITransaction,
  TransactionStatus,
  CreatePayment,
  ICreatePaymentLine,
  PaymentLineType,
  ITransactionResponseContract,
} from '@getgreenline/homi-shared'
import { captureError } from '../../../utilities/logging'
import { parseErrorMsg } from '../../../utilities/helpers'
import { paymentConverter } from '../converters/paymentConverter'
import { ProductModels } from '@getgreenline/products'

export class PaymentEventService extends AppSyncEventService implements IEventService {
  subscription?: Subscription
  private disposer?: IReactionDisposer

  @observable
  blazeTransaction?: BlazepayPaymentModels.IPaymentData<BlazepayEnums.RequestType.PAYMENTS>

  @observable
  waitingForResponse = true

  constructor(private merchantId: string, private terminalId: string) {
    super()
  }

  private appSyncCallback(): IAppSyncCallbacks<BlazepayPaymentModels.IPaymentSubscriptionContract> {
    return {
      next: ({ data }) => {
        this.setTransaction(data.updatedPayment.data)
      },
      error: (error) => {
        captureError(parseErrorMsg(error) || 'payment_event.error', error, false)
      },
    }
  }

  subscribe<T>(props: ISubscriptionCallbacks<T>) {
    this.resetTransaction()
    this.reactToAppSyncConnectionChange()
    this.subscription = appsyncApi
      .subscribeFor(this.teardown)
      .paymentEvents(this.merchantId, this.terminalId, this.appSyncCallback())

    return props.after().finally(() => {
      this.subscription?.unsubscribe() // always unsubscribe after the transaction is done
    })
  }

  private teardown = () => {
    this.disposer?.()
    this.resetTransaction()
  }

  private async initiateTransaction(
    provider: BlazepayEnums.Provider,
    offlinePaymentId: string,
    transaction: ITransaction,
    currentUserId: string,
  ) {
    if (transaction.metadata) {
      const parsedMetadata = JSON.parse(transaction.metadata)
      const existingTransaction = await BlazepayAPI.getSaleTransaction({
        merchantId: this.merchantId,
        providerName: parsedMetadata.attributes.provider,
        transactionId: parsedMetadata.id,
      }).catch((error) => {
        if (error.response.status === 404) return undefined
      })

      if (existingTransaction) this.setTransaction(existingTransaction.data)
    } else {
      const payload = paymentConverter.serializeForTransaction(
        offlinePaymentId,
        transaction,
        this.terminalId,
        currentUserId,
      )

      const initialBlazeTransaction = await BlazepayAPI.processSaleTransaction({
        idempotencyKey: v4(),
        merchantId: this.merchantId,
        providerName: provider,
        payload,
      })

      /**
       * store the initiated sale in metadata so when a budtender refreshes the page during refund, we can still use this to continue the sale process
       */
      const { companyId } = super.parseMerchantId(this.merchantId)
      await API.updateTransactionById(companyId, transaction.id, {
        ...new TransactionContract(transaction),
        metadata: JSON.stringify(initialBlazeTransaction.data),
      })

      this.setTransaction(initialBlazeTransaction.data)
    }
  }

  async processSaleTransaction(
    payment: CreatePayment,
    provider: BlazepayEnums.Provider,
    transaction: ITransaction,
    currentUserId: string,
  ) {
    return this.subscribe({
      after: async () => {
        const offlinePaymentId = payment.offlinePaymentId || payment.setOfflinePaymentId(v4())

        await this.initiateTransaction(provider, offlinePaymentId, transaction, currentUserId)

        await when(() => this.waitingForResponse === false)

        if (!this.blazeTransaction) {
          throw new Error('Failed to process payment')
        }

        const newTransaction = this.blazeTransaction

        BlazepayPaymentModels.handlePaymentStatus(newTransaction)

        const updatedTransactionWithTip = await this.handleTip(newTransaction, transaction)

        const isTransactionFullyApproved = updatedTransactionWithTip
          ? newTransaction.attributes.chargedAmount === updatedTransactionWithTip.amount
          : BlazepayPaymentModels.checkIfFullyApproved(newTransaction, transaction)

        const processedTransaction = isTransactionFullyApproved
          ? await this.onSuccessfulTransaction(newTransaction, transaction)
          : await this.onPartialTransaction(newTransaction, transaction)

        return processedTransaction
      },
    })
  }

  private async onSuccessfulTransaction(
    blazeTransaction: BlazepayPaymentModels.IPaymentEventOrRestData,
    transaction: ITransaction,
  ) {
    const { companyId, locationId } = super.parseMerchantId(this.merchantId)

    return API.completeTransaction(companyId, locationId, transaction.paymentId, transaction.id, {
      terminalInvoiceId: blazeTransaction.attributes.referenceId || null,
      metadata: JSON.stringify(blazeTransaction),
    })
  }

  private async onPartialTransaction(
    blazeTransaction: BlazepayPaymentModels.IPaymentEventOrRestData,
    transaction: ITransaction,
  ) {
    const { companyId, locationId } = super.parseMerchantId(this.merchantId)
    const approvedAmountInCents = blazeTransaction.attributes.chargedAmount || 0

    const completedPartialTransaction = new CreateTransaction(transaction.paymentTypeId)
    completedPartialTransaction.setAmount(approvedAmountInCents)
    completedPartialTransaction.setStatus(TransactionStatus.COMPLETED)
    completedPartialTransaction.setTerminalInvoiceId(
      blazeTransaction.attributes.referenceId || null,
    )
    completedPartialTransaction.setMetadata(JSON.stringify(blazeTransaction))

    return API.handlePartialApprovalTransaction(
      companyId,
      locationId,
      transaction.paymentId,
      transaction.id,
      [completedPartialTransaction],
    )
  }

  @action
  private setTransaction(data: BlazepayPaymentModels.IPaymentEventOrRestData) {
    const sanitizedData = super.deserializeData<
      BlazepayPaymentModels.IPaymentData<BlazepayEnums.RequestType.PAYMENTS>
    >(data)

    /**
     * only set the transaction if it doesn't exist or it exists and the ids match
     */
    if (!this.blazeTransaction || this.blazeTransaction.id === sanitizedData.id) {
      this.blazeTransaction = sanitizedData
      const finalStatus = BlazepayPaymentModels.finalPaymentStatus.includes(data.attributes.status)
      this.waitForResponse(!finalStatus)
    }
  }

  @action
  private waitForResponse(waitingForResponse: boolean) {
    this.waitingForResponse = waitingForResponse
  }

  private resetTransaction() {
    this.waitForResponse(true)
    this.blazeTransaction = undefined
  }

  private reactToAppSyncConnectionChange() {
    this.disposer = super.setDisposer(this.disposer, async (connectionState) => {
      await super.fetchRecentData(connectionState, this.keepDataUpToDate)
    })
  }

  keepDataUpToDate = async () => {
    if (this.blazeTransaction) {
      const recentTransaction = await BlazepayAPI.getSaleTransaction({
        merchantId: this.merchantId,
        providerName: this.blazeTransaction.attributes.provider,
        transactionId: this.blazeTransaction.id,
      }).catch((error) => {
        if (error.response.status === 404) return undefined
      })

      if (recentTransaction) {
        this.setTransaction(recentTransaction.data)
      }
    }
  }

  private async handleTip(
    blazepayTransaction: BlazepayPaymentModels.IPaymentEventOrRestData,
    transaction: ITransaction,
  ): Promise<ITransactionResponseContract | undefined> {
    if (!blazepayTransaction.attributes.tipAmount) return

    const { companyId, locationId } = super.parseMerchantId(this.merchantId)

    await this.createTipPaymentLine({
      companyId,
      locationId,
      paymentId: transaction.paymentId,
      tipAmount: blazepayTransaction.attributes.tipAmount,
    })

    return await this.updateTransactionAmount(
      companyId,
      transaction,
      blazepayTransaction.attributes.tipAmount,
    )
  }

  private async createTipPaymentLine({
    companyId,
    locationId,
    paymentId,
    tipAmount,
  }: {
    companyId: number
    locationId: number
    paymentId: string
    tipAmount: number
  }): Promise<ICreatePaymentLine> {
    const payload: PaymentLineModels.IPaymentLineContract = {
      type: PaymentLineType.PRODUCT,
      paymentId,
      productType: ProductModels.ProductType.TIP,
      inventorySubtracted: 1,
      price: tipAmount,
    }

    return paymentLineAPI.addPaymentLine({
      companyId,
      locationId,
      payload,
    })
  }

  private async updateTransactionAmount(
    companyId: number,
    transaction: ITransaction,
    tipAmount: number,
  ): Promise<ITransactionResponseContract> {
    const payload: TransactionContract = new TransactionContract(transaction)
    payload.amount += tipAmount

    return API.updateTransactionById(companyId, transaction.id, payload)
  }
}
