import * as React from 'react'
import { API } from '@getgreenline/homi-shared'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label } from 'recharts'
import { IGlobalStatus } from '@getgreenline/homi-shared/dist/API/Admin/AdminAPIContracts'

interface IState {
  globalStats: IGlobalStatus | undefined
}

export class AdminGlobal extends React.Component<{}, IState> {
  constructor(props: {}) {
    super(props)
    this.state = {
      globalStats: undefined,
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  refreshData = async () => {
    await this.setState({ globalStats: undefined })
    const globalStats = await API.getGlobalStats()
    this.setState({
      globalStats,
    })
  }

  render() {
    const { globalStats } = this.state

    if (!globalStats) {
      return <div>Loading...</div>
    }

    return (
      <div className='p-4'>
        <div className='d-flex flex-row justify-content-center mb-3'>
          <h2>Global Stats</h2>
          <button className='btn btn-lg btn-light text-muted ml-2' onClick={this.refreshData}>
            <span className='fa fa-refresh mr-2' />
            Refresh
          </button>
        </div>

        <div className='d-flex flex-row justify-content-center'>
          <div className='card border mb-3 mr-3' style={{ width: '20em' }}>
            <div className='card-header'>Active Clients</div>
            <div className='card-body text-secondary d-flex flex-center justify-content'>
              <h1 className='card-title text-center'>
                <b>{globalStats.numberOfActiveClients}</b>
              </h1>
            </div>
          </div>

          <div className='card border mb-3' style={{ width: '20em' }}>
            <div className='card-header'>Top selling bardcodes</div>
            <div className='card-body text-secondary'>
              {globalStats.topSellingBarcodesAllTimeFromLicensedStores.map((barcode, index) => (
                <h5 className='card-title text-center' key={index}>
                  {barcode}
                </h5>
              ))}
            </div>
          </div>
        </div>

        <div className='d-flex flex-row flex-wrap justify-content-around'>
          <AreaChart
            width={800}
            height={300}
            data={globalStats.numberOfSalesFromLicensedStores}
            margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
          >
            <XAxis dataKey='date' />
            <YAxis>
              <Label value='# of sales' offset={0} position='insideLeft' angle={-90} />
            </YAxis>
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip />
            <Legend />
            <Area type='monotone' dataKey='numberOfSales' stroke='#8884d8' activeDot={{ r: 8 }} />
          </AreaChart>

          <AreaChart
            width={800}
            height={300}
            data={globalStats.salesInDollarsFromLicensedStores}
            margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
          >
            <XAxis dataKey='date' />
            <YAxis>
              <Label value='sales in $' offset={0} position='insideLeft' angle={-90} />
            </YAxis>
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip />
            <Legend />
            <Area type='monotone' dataKey='salesTotal' stroke='#8884d8' activeDot={{ r: 8 }} />
          </AreaChart>

          <AreaChart
            width={800}
            height={300}
            data={globalStats.numberOfCustomersFromLicensedStores}
            margin={{ top: 5, right: 30, left: 30, bottom: 5 }}
          >
            <XAxis dataKey='date' />
            <YAxis>
              <Label value='# of customers' offset={0} position='insideLeft' angle={-90} />
            </YAxis>
            <CartesianGrid strokeDasharray='3 3' />
            <Tooltip />
            <Legend />
            <Area
              type='monotone'
              dataKey='numberOfCustomers'
              stroke='#8884d8'
              activeDot={{ r: 8 }}
            />
          </AreaChart>
        </div>
      </div>
    )
  }
}
