import React, { useState } from 'react'
import { Select, Input, Empty, message, Spin } from 'antd'
import { debounce } from 'lodash'
import { provinceProductsApi, ProvinceProductModels } from '@getgreenline/products'
import { ProvinceProductsList } from './ProvinceProductsList'
import { ProvinceProductsHeader } from './ProvinceProductsHeader'
import { CompaniesModels } from '@getgreenline/companies'

export function ProvinceProductsSearch() {
  const [isLoading, setIsLoading] = useState(false)
  const [province, setProvince] = useState(CompaniesModels.Province.AB)
  const [searchProducts, setSearchProducts] = useState<
    ProvinceProductModels.IProvinceProductContract[]
  >([])

  const getProducts = async (searchQuery: string) => {
    setIsLoading(true)

    try {
      const productsResponse = await provinceProductsApi.getProvinceProducts(province, searchQuery)

      setSearchProducts(productsResponse)
    } catch (err) {
      message.error('Failed to load')
    } finally {
      setIsLoading(false)
    }
  }

  const onInputChange = debounce(async (value: string) => {
    if (value.length === 0) {
      setSearchProducts([])
      return
    }

    await getProducts(value)
  }, 500)

  return (
    <>
      <ProvinceProductsHeader />
      <div className='row'>
        <div className='col-xs-12 col-lg-6 offset-lg-3'>
          <Input
            key='search'
            addonAfter={
              <Select<CompaniesModels.Province>
                className='select-after'
                defaultValue={CompaniesModels.Province.AB}
                onChange={(value) => setProvince(value)}
              >
                <Select.Option value={CompaniesModels.Province.AB}>
                  {CompaniesModels.Province.AB}
                </Select.Option>
                <Select.Option value={CompaniesModels.Province.BC}>
                  {CompaniesModels.Province.BC}
                </Select.Option>
                <Select.Option value={CompaniesModels.Province.ON}>
                  {CompaniesModels.Province.ON}
                </Select.Option>

                {Object.keys(CompaniesModels.Province).map((key) => {
                  const province: CompaniesModels.Province = CompaniesModels.Province[key]
                  const hasCatalogue = [
                    CompaniesModels.Province.AB,
                    CompaniesModels.Province.BC,
                    CompaniesModels.Province.ON,
                  ].includes(province)

                  if (hasCatalogue) return

                  return (
                    <Select.Option key={province} value={province}>
                      {province}
                    </Select.Option>
                  )
                })}
              </Select>
            }
            className='ml-3 mr-3'
            placeholder='Search by SKU, name, barcode, or vendor'
            onChange={(e) => onInputChange(e.target.value)}
          />

          {isLoading ? (
            <div className='mt-3 offset-lg-6'>
              <Spin />
            </div>
          ) : (
            <div className='mt-3'>
              {searchProducts.length > 0 ? (
                <div className='ml-3 mr-3'>
                  <ProvinceProductsList title='Search results' productsToAdd={searchProducts} />
                </div>
              ) : (
                <Empty />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
