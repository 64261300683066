import { isNumber } from 'lodash'
import { MetaDataReservedKeys } from '../ProductStore'

interface IMinPayloads {
  maxValue: number | null
  cannabinoidType: 'THC' | 'CBD'
  value: number | undefined
  handleChange: (key: MetaDataReservedKeys, value: number | undefined) => void
}

interface IMaxPayloads extends Omit<IMinPayloads, 'maxValue'> {
  minValue: number | null
}

export const ThcCbdInputFieldsHelper = {
  handleMinChange: ({ maxValue, cannabinoidType, value, handleChange }: IMinPayloads): void => {
    const minMetaKey =
      cannabinoidType === 'THC' ? MetaDataReservedKeys.MIN_THC : MetaDataReservedKeys.MIN_CBD
    const maxMetaKey =
      cannabinoidType === 'THC' ? MetaDataReservedKeys.MAX_THC : MetaDataReservedKeys.MAX_CBD

    if (!maxValue && isNumber(value)) {
      // if max is not set and min is set => set max to min
      handleChange(minMetaKey, value)
      handleChange(maxMetaKey, value)
    } else if (maxValue && isNumber(value) && maxValue < value) {
      // if min and max are set and min is greater than max => set max to min
      handleChange(minMetaKey, value)
      handleChange(maxMetaKey, value)
    } else if (isNumber(maxValue) && !isNumber(value)) {
      // if max is set and min is not set => set min to 0
      handleChange(minMetaKey, 0)
    } else {
      handleChange(minMetaKey, value)
    }
  },
  handleMaxChange: ({ minValue, cannabinoidType, value, handleChange }: IMaxPayloads): void => {
    const minMetaKey =
      cannabinoidType === 'THC' ? MetaDataReservedKeys.MIN_THC : MetaDataReservedKeys.MIN_CBD
    const maxMetaKey =
      cannabinoidType === 'THC' ? MetaDataReservedKeys.MAX_THC : MetaDataReservedKeys.MAX_CBD

    if (!isNumber(minValue)) {
      // if min is not number => set min to 0
      handleChange(maxMetaKey, value)
      handleChange(minMetaKey, 0)
    } else if (!value && isNumber(minValue)) {
      // if max and value are not set and min is set => set max to min
      handleChange(maxMetaKey, minValue)
    } else if (isNumber(value) && isNumber(minValue) && value < minValue) {
      // if min, max, and value are set and max is less than min => set max to min
      handleChange(maxMetaKey, minValue)
    } else {
      handleChange(maxMetaKey, value)
    }
  },
}
