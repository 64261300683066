import { API, ICompanyWithMetaDataContract, MetaDataType } from '@getgreenline/homi-shared'
import { Button, Descriptions, Layout, PageHeader, Skeleton, message } from 'antd'
import moment from 'moment'
import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { browserHistory, Link } from 'react-router'
import { BannerMessageDrawer } from './BannerMessageDrawer/BannerMessageDrawer'
import { CompanyMigrate } from './CompanyMigrate'
import { CompanyProgress } from './CompanyProgress'
import { CompanyUsers } from './CompanyUsers'
import Integrations from './Integrations/Integrations'
import { LocationsAndDevices } from './LocationsAndDevices'
import { MigrateCompanyToOrganization } from './MigrateCompanyToOrganisation'
import { ModifyUsers } from './ModifyUser'
import { SmsQuotaWidget } from './SmsQuotaWidget'
import { AuditRollbackWidget } from './AuditRollbackWidget/AuditRollbackWidget'
import { OcsSubmissionAttemptsTable } from './OCS/OcsSubmissionAttempts'
import { parseErrorMsg } from '../../../utilities/helpers'
import { CurrentCompanyStore } from '../../../stores/CurrentCompanyStore'

interface Props {
  update: (updateObject: any) => void
  refreshData: () => void
  onBack: () => void
  params: {
    companyId?: string
  }
}

interface InjectedProps {
  currentCompanyStore?: CurrentCompanyStore
}

interface State {
  company?: ICompanyWithMetaDataContract<MetaDataType>
  crsaHashKeyMap?: Map<string, string>
  showBannerMessageDrawer: boolean
}

@inject('currentCompanyStore')
@observer
export class CompanySection extends React.Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props)
    this.state = {
      showBannerMessageDrawer: false,
    }
  }

  async componentDidMount() {
    await this.loadCompany()
  }

  async componentDidUpdate(oldProps: Props) {
    if (oldProps.params.companyId !== this.props.params.companyId) {
      this.setState({ company: undefined })
      await this.loadCompany()
    }
  }

  async updateCompany(updateObject: any, isUpdatingMetaData = false) {
    const company = this.state.company
    if (company) {
      try {
        if (isUpdatingMetaData) {
          await API.updateCompanyWithMetaData(company.id, updateObject, true)
        } else {
          await API.updateAdminCompanies(company.id, updateObject)
        }

        await this.loadCompany()
        this.setState({
          showBannerMessageDrawer: false,
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  loadCompany = async () => {
    const { params } = this.props
    if (!params.companyId) throw new Error('No company ID')

    try {
      const company = await API.getCompanyById(Number(params.companyId), true)

      const crsaHashKeyMap = await this.props.currentCompanyStore?.getCrsaHashKeyMap(
        Number(params.companyId),
      )

      this.setState({
        company: company,
        crsaHashKeyMap,
      })
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while loading the company')
      console.error(error)
    }
  }

  get companyName() {
    const { company } = this.state

    if (company?.parentEntityId) {
      return `${company?.name} (Org: ${company?.parentEntityId})`
    }

    return company?.name
  }

  render() {
    const company = this.state.company

    if (!company) {
      return (
        <div className='p-4'>
          <Skeleton active />
        </div>
      )
    }

    return (
      <>
        <Layout.Header style={{ position: 'fixed', background: '#fff', padding: 0, zIndex: 1 }}>
          <PageHeader
            title={this.companyName}
            subTitle={company.province}
            onBack={() => browserHistory.push('/admin/companies')}
            extra={
              <>
                <Link to={`/admin/companies/${company.id}/subscriptions`}>
                  <Button key='webhook-subscriptions'>Webhook subscriptions</Button>
                </Link>
                <Link to={`/admin/companies/${company.id}/productImport`}>
                  <Button key='product-import' icon='download'>
                    Product CSV import
                  </Button>
                </Link>
                <Link to={`/admin/companies/${company.id}/productBulkUpdate`}>
                  <Button key='product-import' icon='download'>
                    Product CSV update
                  </Button>
                </Link>
                <Link to={`/admin/companies/${company.id}/customerImport`}>
                  <Button key='customer-import' icon='download'>
                    Customer CSV import
                  </Button>
                </Link>
                <Button
                  key='showBannerMessage'
                  onClick={() => this.setState({ showBannerMessageDrawer: true })}
                >
                  Banner Message
                </Button>
                <Button
                  key='login'
                  type='primary'
                  href={`/company/${company.id}/dashboard`}
                  target='_blank'
                >
                  Login
                </Button>
              </>
            }
          >
            <Descriptions size='small' column={3}>
              <Descriptions.Item label='ID'>{company.id}</Descriptions.Item>
              <Descriptions.Item label='Province'>{company.province}</Descriptions.Item>
              <Descriptions.Item label='Created'>
                {moment(company.createDate).format('MMM DD YYYY')}
              </Descriptions.Item>
            </Descriptions>
          </PageHeader>
        </Layout.Header>
        <Layout.Content
          style={{ padding: 24, marginTop: 80, background: '#fff', overflow: 'initial' }}
        >
          <br />
          <div className='row'>
            <div className='col-xs-12 col-lg-6'>
              <CompanyProgress companyId={company.id} />
              <br />
              <LocationsAndDevices companyId={company.id} />
              <br />
              <CompanyUsers companyId={company.id} />
            </div>

            <div className='col-xs-12 col-lg-6'>
              <Integrations
                company={company}
                updateCompany={(updateObject, isUpdatingMetaData) =>
                  this.updateCompany(updateObject, isUpdatingMetaData)
                }
              />
              <br />
              <CompanyMigrate companyId={company.id} />
              <br />
              <MigrateCompanyToOrganization company={company} loadCompany={this.loadCompany} />
              <br />
              <ModifyUsers />
              <br />
              <SmsQuotaWidget company={company} />
              <br />
              <AuditRollbackWidget company={company} />
              <br />
              {this.state.crsaHashKeyMap && (
                <OcsSubmissionAttemptsTable
                  company={company}
                  crsaHashKeyMap={this.state.crsaHashKeyMap}
                />
              )}
              <BannerMessageDrawer
                company={company}
                isVisible={this.state.showBannerMessageDrawer}
                updateCompany={(updateObject) => this.updateCompany(updateObject)}
                onClose={() => this.setState({ showBannerMessageDrawer: false })}
              />
            </div>
          </div>
        </Layout.Content>
      </>
    )
  }
}
