import { observable, computed } from 'mobx'
import { Country, Province } from '@getgreenline/homi-shared'

export class RegisterModel {
  @observable
  email = ''

  @observable
  companyName = ''

  @observable
  name = ''

  @observable
  password = ''

  @observable
  timezone?: string

  @observable
  province: Province = 'AB'

  @observable
  country: Country = 'Canada'
}
