import React, { useState, useEffect } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { IShift, PaymentTypeIds } from '@getgreenline/homi-shared'
import { Currency } from '../../../components/Currency'
import { CurrentCompanyStore } from '../../../stores/CurrentCompanyStore'
import {
  formatPaymentOptionNameByTypeId,
  getPaymentOptions,
} from '../../../utilities/paymentsHelper'
import { BlazepayEnums, BlazepayPaymentOptionModels } from '@getgreenline/payments'
import { parseErrorMsg } from '../../../utilities/helpers'
import { Spin, message } from 'antd-v4'

interface Props {
  shift: IShift
}

interface InjectedProps {
  currentCompanyStore?: CurrentCompanyStore
}

const ShiftPaymentLinesComponent = ({ shift, currentCompanyStore }: Props & InjectedProps) => {
  const [paymentOptions, setPaymentOptions] =
    useState<
      | Map<BlazepayEnums.Provider, BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData>
      | undefined
    >()
  const [isLoading, setIsLoading] = useState(false)

  const { payments } = shift

  const filteredPaymentTypeKeys = (Object.keys(payments) as (keyof typeof payments)[]).filter(
    (key) => key !== 'debitCard' && key !== 'creditCard',
  )

  useEffect(() => {
    fetchPaymentOptions()
  }, [])

  const fetchPaymentOptions = async () => {
    const companyId = currentCompanyStore?.company?.id
    const locationId = shift.locationId
    if (!locationId || !companyId) return

    try {
      setIsLoading(true)

      const paymentOptions = await getPaymentOptions(companyId, locationId)

      setPaymentOptions(paymentOptions)
    } catch (error) {
      console.error(error)
      message.error(parseErrorMsg(error) || 'An error occurred while fetching payment options')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <tr>
            <td>Total</td>
            <td style={{ textAlign: 'right' }}>
              <Currency centValue={payments.total} />
            </td>
          </tr>
          {filteredPaymentTypeKeys.map((paymentType) => {
            if (paymentType === 'total') {
              return
            }

            if (payments[paymentType] !== 0) {
              return (
                <tr key={paymentType}>
                  <td>
                    {formatPaymentOptionNameByTypeId(PaymentTypeIds[paymentType], paymentOptions)}
                  </td>

                  <td style={{ textAlign: 'right' }}>
                    <Currency centValue={payments[paymentType]} />
                  </td>
                </tr>
              )
            }
          })}
        </>
      )}
    </>
  )
}

export const ShiftPaymentLines = inject('currentCompanyStore')(observer(ShiftPaymentLinesComponent))
