import * as React from 'react'
import {
  API,
  PaymentFilter,
  ICategoryContract,
  INestedProduct,
  IBaseInvoice,
  IPayment,
  IListInventoryAudit,
  InvoiceStatus,
  TransferStatus,
  IBaseTransfer,
} from '@getgreenline/homi-shared'
import moment from 'moment'
import { productApi } from '@getgreenline/products'

interface Props {
  companyId: number
}

interface State {
  categories?: ICategoryContract[]
  products?: INestedProduct[]
  purchaseOrders?: IBaseInvoice[]
  payments?: IPayment[]
  audits?: IListInventoryAudit[]
  transfers?: IBaseTransfer[]
}

export class CompanyProgress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    this.refreshData()
  }

  async refreshData() {
    const { companyId } = this.props
    const categories = await API.getCategories(companyId)
    this.setState({ categories: categories })
    const { products } = await productApi.getProducts(companyId, { limit: 10 })
    this.setState({ products: products })
    const purchaseOrderResponse = await API.getInvoices(companyId, {
      offset: 0,
      limit: 1,
      status: 'completed' as InvoiceStatus,
    })
    this.setState({ purchaseOrders: purchaseOrderResponse.data })
    const paymentResponse = await API.getPayments(companyId, new PaymentFilter(companyId, 10, 0))
    this.setState({ payments: paymentResponse.payments })
    const auditsResponse = await API.getInventoryAudits(companyId, {
      offset: 0,
      limit: 1,
      status: 'completed',
    })
    this.setState({ audits: auditsResponse.data })
    const transfersResponse = await API.getTransfers(companyId, {
      offset: 0,
      limit: 1,
      statusFilter: 'completed' as TransferStatus,
      titleFilter: '',
      toLocationIdFilter: undefined,
      fromLocationIdFilter: undefined,
    })
    this.setState({ transfers: transfersResponse.transfers })
  }

  getCheckbox(status?: boolean) {
    let color = ''
    if (status === true) {
      color = 'text-success'
    }
    if (status === false) {
      color = 'text-danger'
    }

    return <span className={'fa fa-check-circle ' + color} />
  }

  render() {
    const { categories, products, purchaseOrders, payments, audits, transfers } = this.state

    const hasComplianceCategories =
      categories && categories.filter((c) => c.complianceCategoryId).length > 0
    const has10Products = products && products.length >= 10
    const hasPurchaseOrder = purchaseOrders && purchaseOrders.length > 0
    const has1Payment = payments && payments.length >= 1
    const hasAudit = audits && audits.length >= 1
    const hasTransfers = transfers && transfers.length >= 1

    let riskOfLeavingWarning: JSX.Element | undefined
    if (payments && payments.length >= 10) {
      const latestPaymentDate = payments[0].createDate
      const daysDiff = moment().diff(latestPaymentDate, 'days')
      if (daysDiff >= 3) {
        riskOfLeavingWarning = (
          <div className='alert alert-danger mt-1 mb-1'>
            No sales made in the past 3 days. At risk of leaving!
          </div>
        )
      }
    }

    return (
      <div className='background-shadow p-4'>
        <h5 className='text-muted'>
          <span className='mr-2'>Progress</span>
        </h5>
        <br />
        <table className='table table-hover table-sm'>
          <tbody>
            <tr>
              <td>
                <b>Added at least 1 compliance category</b>
                <div className='text-muted'>
                  {categories
                    ? categories.filter((c) => c.complianceCategoryId).length + ' categories'
                    : '-'}
                </div>
              </td>
              <td className='text-right'>{this.getCheckbox(hasComplianceCategories)}</td>
            </tr>
            <tr>
              <td>
                <b>Added at least 10 products</b>
                <div className='text-muted'>{products ? 'At least 10 products' : '-'}</div>
              </td>
              <td className='text-right'>{this.getCheckbox(has10Products)}</td>
            </tr>
            <tr>
              <td>
                <b>Added at least 1 completed purchase order</b>
                <div className='text-muted'>
                  {purchaseOrders && purchaseOrders.length >= 1
                    ? 'Latest date: ' +
                      moment(purchaseOrders[0].completeDate!).format('MMM DD YYYY h:mma')
                    : '-'}
                </div>
              </td>
              <td className='text-right'>{this.getCheckbox(hasPurchaseOrder)}</td>
            </tr>
            <tr>
              <td>
                <b>Made at least 1 sale</b>
                <div className='text-muted'>
                  {payments && payments.length >= 10
                    ? 'Latest date: ' + moment(payments[0].createDate).format('MMM DD YYYY h:mma')
                    : '-'}
                </div>
                {riskOfLeavingWarning}
              </td>
              <td className='text-right'>{this.getCheckbox(has1Payment)}</td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>Made at least 1 bulk audit</b>
                </div>
                {audits && audits.length >= 1 ? (
                  <div className='text-muted'>
                    Latest date: {moment(audits[0].completeDate!).format('MMM DD YYYY h:mma')}
                  </div>
                ) : (
                  '-'
                )}
              </td>
              <td className='text-right'>{this.getCheckbox(hasAudit)}</td>
            </tr>
            <tr>
              <td>
                <div>
                  <b>Made at least 1 transfer</b>
                </div>
                {transfers && transfers.length >= 1 ? (
                  <div className='text-muted'>
                    Latest date: {moment(transfers[0].completeDate!).format('MMM DD YYYY h:mma')}
                  </div>
                ) : (
                  '-'
                )}
              </td>
              <td className='text-right'>{this.getCheckbox(hasTransfers)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}
