import * as React from 'react'
import {
  CannabinoidMetaDataItem,
  CreateProduct,
  CreateChildProduct,
  ProductMetaDataItem,
} from '../ProductStore'
import { Checkbox, Divider, DatePicker } from 'antd'
import { BlazeButton } from '../../../../../components/CustomButtons/BlazeButton'
import { ILocation } from '@getgreenline/homi-shared'
import { inject, observer } from 'mobx-react'
import { TooltipWrapper } from '../../../DashboardInventory/View/ActiveInventoryTable/InventoryDisplay/TooltipWrapper'
import { LocationModels } from '@getgreenline/locations'
import { CannabinoidTerpeneDrawer } from './CannabinoidTerpeneDrawer'
import { LocationsSelect } from './LocationsSelect'
import { CurrentCompanyStore } from '../../../../../stores/CurrentCompanyStore'
import { ThcCbdInputFields } from './ThcCbdInputFields'
import moment from 'moment'
import { MetaDataModels } from '@getgreenline/products'
import { TerpeneButton } from './TerpeneButton'

interface Props {
  index: number
  disabled: boolean
  product: CreateProduct | CreateChildProduct
  cannabinoid: CannabinoidMetaDataItem
  orderedPermittedLocations: ILocation[]
  mappedGlobalLocations: Map<number, LocationModels.IGlobalLocationContract>
  currentCompanyStore?: CurrentCompanyStore
}

interface State {
  searchedLocationName?: string
  openDrawer: boolean
  drawerTab: string
}

@inject('currentCompanyStore')
@observer
export class MultiLocationCannabinoid extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      searchedLocationName: undefined,
      openDrawer: false,
      drawerTab: 'cannabinoid',
    }
  }

  closeDrawer = () => {
    this.setState({
      openDrawer: false,
    })
  }

  getPackagedDate = () => {
    const packagedDate = this.props.cannabinoid?.findMetaData(
      MetaDataModels.MetaDataReservedKeys.PACKAGE_DATE,
    )?.metaValue

    return packagedDate ? moment(packagedDate) : undefined
  }

  makeCannabinoidsName = () => {
    let thcString: string | null, cbdString: string | null
    const units = this.props.cannabinoid.unit ? this.props.cannabinoid.unit : ''

    if (this.props.cannabinoid.showMaxMin) {
      const minThc = this.props.cannabinoid.minTHC
      const maxThc = this.props.cannabinoid.maxTHC
      const minCbd = this.props.cannabinoid.minCBD
      const maxCbd = this.props.cannabinoid.maxCBD

      thcString =
        this.nullStringFormatter(minThc?.toString(), units) +
        ' - ' +
        this.nullStringFormatter(maxThc?.toString(), units)

      cbdString =
        this.nullStringFormatter(minCbd?.toString(), units) +
        ' - ' +
        this.nullStringFormatter(maxCbd?.toString(), units)
    } else {
      const thc = this.props.cannabinoid.thc
      const cbd = this.props.cannabinoid.cbd

      thcString = this.nullStringFormatter(thc?.toString(), units)
      cbdString = this.nullStringFormatter(cbd?.toString(), units)
    }

    return 'Cannabinoids: THC: ' + thcString + ', CBD: ' + cbdString
  }

  nullStringFormatter = (metaValue: string | null | undefined, unit: string) => {
    if (metaValue) {
      return metaValue + unit
    } else {
      return 'N/A'
    }
  }

  get locationNotPermitted() {
    const { orderedPermittedLocations, cannabinoid } = this.props

    const allLocationIds = orderedPermittedLocations.map((location) => location.id)
    return cannabinoid.locationIds.some((locationId) => !allLocationIds.includes(locationId))
  }

  render() {
    const {
      index,
      disabled,
      product,
      cannabinoid,
      mappedGlobalLocations,
      orderedPermittedLocations,
    } = this.props
    const { cannabinoidMetaData } = product

    return (
      <TooltipWrapper
        key={index}
        renderTooltip={this.locationNotPermitted}
        tooltipProps={{
          title: `You don't have permission to edit this data`,
        }}
      >
        {index !== 0 && <Divider className='mt-1 mb-3' />}
        {this.props.currentCompanyStore?.productDetailsTerpenesAndCannabinoids ? (
          <>
            <div className='d-flex flex-row flex-wrap'>
              <div>
                <label>{`Location (${cannabinoid.locationIds.length}/${mappedGlobalLocations.size})`}</label>
                <div className='mr-2' style={{ width: 220 }}>
                  <LocationsSelect
                    index={index}
                    disabled={disabled}
                    product={product}
                    cannabinoid={cannabinoid}
                    orderedPermittedLocations={orderedPermittedLocations}
                    mappedGlobalLocations={mappedGlobalLocations}
                    currentCompanyStore={this.props.currentCompanyStore}
                  ></LocationsSelect>
                </div>
              </div>

              <div className='align-self-end'>
                <label className='d-block'>Packaging Date</label>
                <DatePicker
                  className='mb-2'
                  onChange={(date: any, dateString: string) => {
                    const productMetaDataItem = cannabinoid.findMetaData(
                      MetaDataModels.MetaDataReservedKeys.PACKAGE_DATE,
                    )

                    if (productMetaDataItem) {
                      cannabinoid.removeMetaDataItem(productMetaDataItem)
                    }

                    cannabinoid.addMetaDataItem(
                      new ProductMetaDataItem(
                        MetaDataModels.MetaDataReservedKeys.PACKAGE_DATE,
                        dateString,
                      ),
                    )
                    product.changed = true
                  }}
                  value={this.getPackagedDate()}
                />
                <div className='d-block mr-2 mb-2'>
                  <BlazeButton
                    titleProps={{ title: this.makeCannabinoidsName() }}
                    buttonProps={{
                      onClick: () => {
                        this.setState({ drawerTab: 'cannabinoids', openDrawer: true })
                      },
                    }}
                  ></BlazeButton>
                </div>
                <div className='d-block mr-2 mb-2'>
                  <TerpeneButton
                    cannabinoid={cannabinoid}
                    disabled={disabled || this.locationNotPermitted}
                    onClick={() => {
                      this.setState({ drawerTab: 'terpenes', openDrawer: true })
                    }}
                  />
                </div>
              </div>

              <div className='d-flex mb-3' style={{ marginLeft: 'auto' }}>
                <BlazeButton
                  titleProps={{
                    title: 'Delete',
                  }}
                  buttonProps={{
                    icon: 'delete',
                    type: 'danger',
                    ghost: true,
                    disabled: disabled || this.locationNotPermitted,
                    onClick: () => {
                      cannabinoidMetaData.removeCannabinoid(index)
                      product.changed = true
                    },
                  }}
                />
              </div>
            </div>

            {this.state.openDrawer && (
              <CannabinoidTerpeneDrawer
                isOpen={this.state.openDrawer}
                onClose={this.closeDrawer}
                index={index}
                disabled={disabled || this.locationNotPermitted}
                product={product}
                cannabinoid={cannabinoid}
                orderedPermittedLocations={orderedPermittedLocations}
                mappedGlobalLocations={mappedGlobalLocations}
                initialTab={this.state.drawerTab}
                currentCompanyStore={this.props.currentCompanyStore}
              />
            )}
          </>
        ) : (
          <div className='d-flex flex-row flex-wrap'>
            <div className='mr-2 mb-3' style={{ width: 220 }}>
              <label>{`Location (${cannabinoid.locationIds.length}/${mappedGlobalLocations.size})`}</label>
              <LocationsSelect
                index={index}
                disabled={disabled}
                product={product}
                cannabinoid={cannabinoid}
                orderedPermittedLocations={orderedPermittedLocations}
                mappedGlobalLocations={mappedGlobalLocations}
                currentCompanyStore={this.props.currentCompanyStore}
              ></LocationsSelect>
            </div>
            <div className='mr-2 mb-3'>
              <label>Min/max</label>

              <div>
                <div className='d-flex justify-content-center align-items-center'>
                  <Checkbox
                    checked={cannabinoid.showMaxMin}
                    disabled={disabled || this.locationNotPermitted}
                    onChange={cannabinoid.toggleShowMaxMin}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex flex-row flex-wrap'>
              <ThcCbdInputFields
                showMaxMin={cannabinoid.showMaxMin}
                disabled={disabled || this.locationNotPermitted}
                className='mr-2 mb-3'
                onChange={cannabinoid.setValue}
                minTHC={cannabinoid.minTHC}
                thc={cannabinoid.thc}
                maxTHC={cannabinoid.maxTHC}
                minCBD={cannabinoid.minCBD}
                cbd={cannabinoid.cbd}
                maxCBD={cannabinoid.maxCBD}
                product={product}
              />
              <div className='d-flex align-items-end mb-3'>
                <BlazeButton
                  titleProps={{
                    title: 'Delete',
                  }}
                  buttonProps={{
                    icon: 'delete',
                    type: 'danger',
                    ghost: true,
                    disabled: disabled || this.locationNotPermitted,
                    onClick: () => {
                      cannabinoidMetaData.removeCannabinoid(index)
                      product.changed = true
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </TooltipWrapper>
    )
  }
}
