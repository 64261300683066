import * as React from 'react'
import { observer } from 'mobx-react'
import { RegisterModel } from './RegisterModel'
import { Province } from '@getgreenline/homi-shared'
import { Select } from 'antd'

interface Props {
  registerModel: RegisterModel
}

interface IProvinceOption {
  label: Province
  value: Province
}

@observer
export class Company extends React.Component<Props, {}> {
  componentDidMount() {
    this.focusInput()
  }

  focusInput() {
    const inputs = document.getElementsByTagName('input')
    if (inputs.length > 0) {
      inputs[0].focus()
    }
  }

  get provinceOptions(): IProvinceOption[] {
    return [
      { label: 'BC', value: 'BC' },
      { label: 'AB', value: 'AB' },
      { label: 'SK', value: 'SK' },
      { label: 'MB', value: 'MB' },
      { label: 'ON', value: 'ON' },
      { label: 'NU', value: 'NU' },
      { label: 'NT', value: 'NT' },
    ]
  }

  get timezoneOptions() {
    return [
      {
        label: 'Pacific',
        value: 'America/Vancouver',
      },
      {
        label: 'Mountain',
        value: 'America/Edmonton',
      },
      {
        label: 'Central',
        value: 'America/Regina',
      },
      {
        label: 'Eastern',
        value: 'America/Toronto',
      },
      {
        label: 'Atlantic',
        value: 'America/Halifax',
      },
    ]
  }

  render() {
    const registerModel = this.props.registerModel

    return (
      <div className='animated fadeInRight'>
        <h3>Store setup</h3>
        <p className='text-muted'>
          Let's get your store details. What is the name of your business?
        </p>
        <div className='form-group'>
          <input
            type='text'
            className='form-control form-control-lg'
            placeholder='Your store name'
            value={registerModel.companyName}
            onChange={(e) => (registerModel.companyName = e.target.value)}
          />
        </div>

        <div className='form-group'>
          <div>
            <label>Province</label>
          </div>

          <Select<Province>
            className='w-100'
            value={registerModel.province}
            allowClear={false}
            onChange={(province) => (registerModel.province = province)}
          >
            {this.provinceOptions.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className='form-group'>
          <div>
            <label>Timezone</label>
          </div>

          <Select<string>
            className='w-100'
            value={registerModel.timezone}
            placeholder='Timezone'
            allowClear={false}
            onChange={(timezone) => (registerModel.timezone = timezone)}
          >
            {this.timezoneOptions.map((option, index) => (
              <Select.Option key={index} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    )
  }
}
