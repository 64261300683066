import * as React from 'react'
import { API, ICompany, ILocation, IAdminExternalSource } from '@getgreenline/homi-shared'
import { Card, Empty, Row, PageHeader, message, Col } from 'antd-v4'
import { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import { SubscriptionCard } from './SubsciptionCard'
import SubscriptionSourceForm from './SubscriptionForm'
import { EditSubscriptionDrawer } from './EditSubsciptionDrawer'
import { ISubscription, ISubscriptionContract, WebhooksApi } from '@getgreenline/webhooks'
import { parseErrorMsg } from '../../../utilities/helpers'

interface Props {
  params: {
    companyId: string
  }
}

export const AdminSubscriptions: React.FC<Props> = ({ params }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [company, setCompany] = useState<ICompany | undefined>(undefined)
  const [locations, setCompanyLocations] = useState<ILocation[] | undefined>(undefined)
  const [externalSources, setExternalSources] =
    useState<IAdminExternalSource[] | undefined>(undefined)
  const [subscriptions, setSubscriptions] = useState<ISubscriptionContract[] | undefined>(undefined)
  const [isEditDrawerOpen, setEditDrawerOpen] = useState(false)
  const [subscriptionToEdit, setSubscriptionToEdit] =
    useState<ISubscriptionContract | undefined>(undefined)

  useEffect(() => {
    async function loadData() {
      try {
        setIsLoading(true)
        const company = await loadCompany()
        const locations = await loadLocations(company.id)
        const externalSources = await loadExternalSources()
        const subscriptions = await getSubscriptions(company.id)
        setCompany(company)
        setCompanyLocations(locations)
        setExternalSources(externalSources)
        setSubscriptions(subscriptions)
      } catch (error) {
        return message.error(parseErrorMsg(error) || 'Error loading subscriptions')
      } finally {
        setIsLoading(false)
      }
    }
    loadData()
  }, [])

  const loadCompany = async () => {
    const companyId = parseInt(params.companyId)
    const company = await API.getCompanyById(companyId, false)
    return company
  }

  const loadLocations = async (companyId: number) => {
    const locations = await API.getLocations(companyId, false)
    return locations
  }

  const loadExternalSources = async () => {
    const externalSources = await API.getExternalSources()
    return externalSources
  }

  const getSubscriptions = async (companyId: number) => {
    try {
      const subscriptions = await WebhooksApi.getSubscriptionsByCompanyId(companyId)
      return subscriptions
    } catch (err) {
      console.log(err)
    }
  }

  const deleteSubscription = async (subscription: ISubscriptionContract) => {
    try {
      const encodedUriComponent = encodeURIComponent(subscription.combineSortKey)
      if (!company?.id) return

      await WebhooksApi.deleteSubscription(encodedUriComponent, company.id)
      const subscriptions = await getSubscriptions(company.id)
      setSubscriptions(subscriptions)
      message.success('Subscription deleted')
    } catch (error) {
      message.error(parseErrorMsg(error) || 'Error deleting subscription')
    }
  }

  const createSubscription = async (subscription: ISubscription) => {
    try {
      if (!subscription.locationId) {
        delete subscription.locationId
      }
      if (!subscription.integratorSecret) {
        delete subscription.integratorSecret
      }
      await WebhooksApi.createSubscription(subscription)
      const subscriptions = await getSubscriptions(subscription.companyId)
      setSubscriptions(subscriptions)
      message.success('Subscription created')
    } catch (error) {
      message.error(parseErrorMsg(error) || 'Error creating subscription')
    }
  }

  const updateSubscription = async (subscription: ISubscription) => {
    if (!subscriptionToEdit) throw Error()
    const encodedCombineKey = encodeURIComponent(subscriptionToEdit.combineSortKey)

    try {
      if (!subscription.locationId) {
        delete subscription.locationId
      }
      if (!subscription.integratorSecret) {
        delete subscription.integratorSecret
      }
      await WebhooksApi.updateSubscription(subscription, encodedCombineKey)

      const updatedSubcriptions = await getSubscriptions(company!.id)
      setSubscriptions(updatedSubcriptions)
      setSubscriptionToEdit(undefined)
      message.success('Subscription updated')
    } catch (error) {
      message.error(parseErrorMsg(error) || 'Error updating subscription')
    }
  }

  return (
    <div className='p-4'>
      <PageHeader
        style={{
          border: '1px solid rgb(235, 237, 240)',
        }}
        className='mb-4'
        onBack={() => browserHistory.goBack()}
        title={company ? `${company.name} - Webhook subscriptions` : 'Loading...'}
        subTitle='View and create webhook subscriptions'
      />

      <Row gutter={16}>
        <Col span={12}>
          {locations && externalSources && (
            <SubscriptionSourceForm
              companyId={company?.id}
              locations={locations}
              externalSources={externalSources}
              onSubmit={createSubscription}
            />
          )}
        </Col>
      </Row>

      {isLoading ? (
        <Card loading={isLoading}></Card>
      ) : subscriptions?.length === 0 ? (
        <Empty />
      ) : (
        <>
          <Row gutter={16}>
            {subscriptions &&
              externalSources &&
              locations &&
              subscriptions.map((subscription) => (
                <SubscriptionCard
                  key={subscription.combineSortKey}
                  subscription={subscription}
                  externalSources={externalSources}
                  locations={locations}
                  onDelete={deleteSubscription}
                  setIsEditing={setEditDrawerOpen}
                  setSubscriptionToEdit={setSubscriptionToEdit}
                />
              ))}
          </Row>
          {company?.id &&
            locations &&
            externalSources &&
            isEditDrawerOpen &&
            subscriptionToEdit && (
              <EditSubscriptionDrawer
                companyId={company?.id}
                locations={locations}
                externalSources={externalSources}
                setIsDrawerOpen={setEditDrawerOpen}
                onSubmit={updateSubscription}
                subscription={subscriptionToEdit}
              />
            )}
        </>
      )}
    </div>
  )
}
