import { SelectProps } from 'antd-v4/lib/select'
import { Select, Spin, Button, Modal, Alert } from 'antd-v4'
import React, { useState, useMemo, useRef, useEffect } from 'react'

import debounce from 'lodash/debounce'
import { ICompany } from '@getgreenline/homi-shared'
import { OrganizationsAPI } from '@getgreenline/organizations'

import { CurrentUserStore } from '../../../stores/CurrentUserStore'
import { parseErrorMsg } from '../../../utilities/helpers'

interface Props {
  company: ICompany
  currentUserStore?: CurrentUserStore

  loadCompany: () => Promise<void>
}

interface State {
  org?: {
    label: string
    value: number
  }
  modalOpen: boolean
  isMigrating: boolean
  errorMessage?: string
  successMessage?: string
}

export interface DebounceSelectProps<ValueType>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
}

function DebounceSelect<ValueType extends { label: React.ReactNode; value: string | number }>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(true)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  useEffect(() => {
    fetchOptions('').then((options) => {
      setOptions(options)
      setFetching(false)
    })
  }, [])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select<ValueType>
      showSearch
      labelInValue
      allowClear={true}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      {...props}
      options={options}
    />
  )
}

export class MigrateCompanyToOrganization extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      modalOpen: false,
      org: undefined,
      isMigrating: false,
      errorMessage: undefined,
      successMessage: undefined,
    }
  }

  get isAlreadyMigrated() {
    const { company } = this.props

    return company.parentEntityId !== null
  }

  fetchOrganizations = async (search: string) => {
    const { data } = await OrganizationsAPI.get({
      limit: 20,
      offset: 0,
      searchQuery: search,
    })

    return data.map((org) => ({
      label: org.name,
      value: org.id,
    }))
  }

  migrate = async () => {
    const { company, loadCompany } = this.props
    const { org } = this.state

    if (!org) {
      return
    }

    this.setState({
      isMigrating: true,
    })

    try {
      await OrganizationsAPI.migrateCompany(org.value, company.id)

      await loadCompany()

      this.setState({
        successMessage: `${company.name} company has successfully been migrated to ${org?.label} organization.`,
      })
    } catch (error) {
      this.setState({
        errorMessage: parseErrorMsg(error),
      })
    } finally {
      this.setState({
        isMigrating: false,
      })
    }
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
      org: undefined,
      errorMessage: undefined,
      successMessage: undefined,
    })
  }

  renderModalContent = () => {
    const { company } = this.props
    const { org, errorMessage, successMessage } = this.state

    if (errorMessage || successMessage) {
      if (errorMessage) {
        return <Alert type='error' message={errorMessage} />
      }

      return <Alert type='success' message={successMessage} />
    }

    return (
      <Alert
        type='info'
        message={`You are about to migrate the company ${company.name} to organization ${org?.label}`}
      />
    )
  }

  render() {
    const { org, modalOpen, isMigrating, errorMessage, successMessage } = this.state

    if (this.isAlreadyMigrated && !successMessage) {
      return null
    }

    return (
      <div className='background-shadow p-4'>
        <h5 className='text-muted'>Migrate company to organization</h5>

        <label htmlFor='selectOrganization'>Select organization</label>
        <DebounceSelect
          value={org}
          id='selectOrganization'
          placeholder='Select an organization'
          fetchOptions={this.fetchOrganizations}
          onChange={(value) => this.setState({ org: value })}
          style={{ width: '100%', marginBottom: '10px' }}
        />

        <Button type='primary' disabled={!org} onClick={() => this.setState({ modalOpen: true })}>
          Migrate
        </Button>

        <Modal
          visible={modalOpen}
          title={`Migrate company to ${org?.label}`}
          okText='Yes, perform migration'
          onOk={this.migrate}
          okButtonProps={{
            loading: isMigrating,
            disabled: !!errorMessage || isMigrating || !!successMessage,
          }}
          cancelText={errorMessage || successMessage ? 'Close' : 'Cancel'}
          onCancel={this.closeModal}
        >
          {this.renderModalContent()}
        </Modal>
      </div>
    )
  }
}
