import * as React from 'react'
import { Form, Input, Icon, Button, message, Select, Row, Col } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import { IAdminExternalSource, ILocation } from '@getgreenline/homi-shared'
import { parseErrorMsg } from '../../../utilities/helpers'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import { ISubscription, ISubscriptionContract } from '@getgreenline/webhooks'
import { GreenlineEventTypes } from '@getgreenline/events'

interface Props extends FormComponentProps {
  externalSources: IAdminExternalSource[]
  locations: ILocation[]
  onSubmit: (subscription: ISubscription) => Promise<ISubscriptionContract | void>
  companyId: number | undefined
  existingSubscription?: ISubscriptionContract
}

const SubscriptionSourceForm = inject('currentCompanyStore')(
  observer((props: Props) => {
    const { form, onSubmit } = props

    const { getFieldDecorator } = form

    const eventTypes = Object.values(GreenlineEventTypes)

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      form.validateFields(async (err, values: ISubscription) => {
        if (err) {
          return
        }

        try {
          if (!props.companyId) throw Error('Company not found')

          onSubmit({
            integratorId: values.integratorId,
            eventType: values.eventType,
            locationId: values.locationId,
            integratorSecret: values.integratorSecret,
            url: values.url,
            companyId: props.companyId,
          })
          form.resetFields()
        } catch (error) {
          message.error(parseErrorMsg(error) || 'An error occurred while creating a subscription')
        }
      })
    }

    return (
      <Form onSubmit={handleSubmit} className='mb-5' labelCol={{ span: 7 }}>
        <Row>
          <Col className='pr-4'>
            <Form.Item
              name='eventType'
              labelAlign='left'
              label='Event type'
              rules={[{ required: true }]}
            >
              {getFieldDecorator('eventType', {
                initialValue: props.existingSubscription?.companyData.eventType,
                rules: [{ required: true }],
              })(
                <Select placeholder='Please select an option' allowClear>
                  {eventTypes.map((nameKey: string, index) => {
                    return (
                      <Select.Option key={index} value={nameKey}>
                        {nameKey}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item name='locationId' labelAlign='left' label='Location'>
              {getFieldDecorator('locationId', {
                initialValue: props.existingSubscription?.companyData.locationId,
              })(
                <Select placeholder='Please select an option' allowClear>
                  {props.locations.map((location, index) => {
                    return (
                      <Select.Option key={index} value={location.id}>
                        {location.name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item
              name='integratorId'
              labelAlign='left'
              label='Integrator'
              rules={[{ required: true }]}
            >
              {getFieldDecorator('integratorId', {
                initialValue: props.existingSubscription?.companyData.integratorId,
                rules: [{ required: true }],
              })(
                <Select placeholder='Please select an option' allowClear>
                  {props.externalSources.map((source, index) => {
                    return (
                      <Select.Option key={index} value={source.id}>
                        {source.name}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item labelAlign='left' name='url' label='URL'>
              {getFieldDecorator('url', {
                initialValue: props.existingSubscription?.companyData.url,
                rules: [{ required: true, message: 'Please input a webhook URL' }],
              })(
                <Input
                  prefix={<Icon type='url' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='URL'
                />,
              )}
            </Form.Item>

            <Form.Item
              labelAlign='left'
              name='integratorSecret'
              label='Integrator secret'
              labelCol={{ span: 24 }}
            >
              {getFieldDecorator('integratorSecret', {
                initialValue: props.existingSubscription?.companyData.integratorSecret,
                rules: [{ required: false, message: 'Please input a secret' }],
              })(
                <Input
                  prefix={<Icon type='text' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='IntegratorSecret'
                />,
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Button className='ml-2' type='primary' htmlType='submit'>
            Submit
          </Button>
        </Row>
      </Form>
    )
  }),
)

export default Form.create<Props>({ name: 'subscription_source_form' })(SubscriptionSourceForm)
