import * as React from 'react'
import { Dropdown, Menu, Button } from 'antd'
import { Link } from 'react-router'
import { BlazeButton } from '../../CustomButtons/BlazeButton'

export interface HelpMenu {
  href: string
  target?: string
  title: string | JSX.Element
}

interface Props {
  buttonTitle?: string
  helpLinkMenus: HelpMenu[] | HelpMenu
  style?: React.CSSProperties
}

const generateAnchor = (item: HelpMenu, removeDefaultStyle?: boolean) => (
  <Link
    to={item.href}
    target={item.target || '_blank'}
    style={removeDefaultStyle ? { color: 'inherit' } : undefined}
  >
    {item.title}
  </Link>
)

export const HelpLinkMenu = (props: Props) => {
  const { buttonTitle, helpLinkMenus, style } = props

  return (
    <>
      {Array.isArray(helpLinkMenus) ? (
        <Dropdown
          overlay={
            <Menu>
              {helpLinkMenus.map((menu, index) => (
                <Menu.Item key={index}>{generateAnchor(menu)}</Menu.Item>
              ))}
            </Menu>
          }
        >
          <Button
            className={`${style || 'border-0'}`}
            style={style ? { ...style, boxShadow: 'none' } : { boxShadow: 'none' }}
            icon='question-circle'
          >
            {buttonTitle || 'Help'}
          </Button>
        </Dropdown>
      ) : (
        <Button
          className={`${style || 'border-0'}`}
          style={style ? { ...style, boxShadow: 'none' } : { boxShadow: 'none' }}
          icon='question-circle'
          href={helpLinkMenus.href}
          target={helpLinkMenus.target || '_blank '}
        >
          {buttonTitle || 'Help'}
        </Button>
      )}
    </>
  )
}
