import {
  IExternalPermissionDefinition,
  IAdminExternalSource,
  UpdateExternalSource,
  IUpdateExternalSourcePermission,
  IUpdateExternalPermission,
} from '@getgreenline/homi-shared'
import { observable, action, computed } from 'mobx'

export class AvailablePermission {
  @observable
  indexOrder: number

  @observable
  permission: IExternalPermissionDefinition

  initialIndexOrder: number

  @observable
  checked = false

  constructor(permission: IExternalPermissionDefinition) {
    this.permission = permission
    this.initialIndexOrder = permission.indexOrder
    this.indexOrder = permission.indexOrder
  }

  @action
  setChecked = (checked: boolean) => {
    this.checked = checked
  }
}

export class AdminExternalSourceStore {
  @observable
  mappedExternalSources = new Map<number, IAdminExternalSource>()

  @observable
  selectedExternalSource?: IAdminExternalSource

  @observable
  mappedPermissions = new Map<number, IExternalPermissionDefinition>()

  @observable
  availablePermissions?: AvailablePermission[]

  @action
  setMappedExternalSources = (externalSources: IAdminExternalSource[]) => {
    this.mappedExternalSources.clear() // Reset

    externalSources.forEach((externalSource) =>
      this.mappedExternalSources.set(externalSource.id, externalSource),
    )
  }

  @action
  setSelectedExternalSource = (selectedExternalSource?: IAdminExternalSource) => {
    this.selectedExternalSource = selectedExternalSource

    this.setMappedPermissions(selectedExternalSource?.permissions)
  }

  @action
  setAvailablePermissions = (availablePermissions?: IExternalPermissionDefinition[]) => {
    this.availablePermissions = availablePermissions
      ? availablePermissions.map(
          (availablePermission) => new AvailablePermission(availablePermission),
        )
      : undefined
  }

  @action
  toggleAllPermissionChecks = () => {
    if (this.availablePermissions) {
      const checked = !this.areAllItemsChecked
      this.availablePermissions.forEach((availablePermission) =>
        availablePermission.setChecked(checked),
      )
    }
  }

  @action
  private setMappedPermissions = (permissions?: IExternalPermissionDefinition[]): void => {
    this.mappedPermissions.clear() // Reset `mappedPermissions`

    if (permissions) {
      permissions.forEach((permission) => this.mappedPermissions.set(permission.id, permission))
    }

    if (this.availablePermissions) {
      this.availablePermissions.forEach((allPermission) => {
        const targetPermission = this.mappedPermissions.get(allPermission.permission.id)
        allPermission.setChecked(!!targetPermission)
      })
    }
  }

  @computed
  get externalSources(): IAdminExternalSource[] {
    return [...this.mappedExternalSources.values()]
  }

  @computed
  get areAllItemsChecked(): boolean {
    if (!this.availablePermissions || !this.checkedPermissions) {
      return false
    }

    return this.availablePermissions.length === this.checkedPermissions.length
  }

  @computed
  get checkedPermissions(): AvailablePermission[] | undefined {
    if (!this.availablePermissions) {
      return undefined
    }

    return this.availablePermissions.filter((allPermission) => allPermission.checked)
  }

  @computed
  get newPermissionObject(): IUpdateExternalPermission[] | undefined {
    if (!this.checkedPermissions) {
      return undefined
    }

    return this.checkedPermissions.map((checkedPermission) => ({
      id: checkedPermission.permission.id,
    }))
  }

  @computed
  get updatedPermissionOrderObject(): IUpdateExternalSourcePermission | undefined {
    if (!this.availablePermissions) {
      return undefined
    }

    return {
      externalSourcePermissions: this.availablePermissions.map((permissionInNewOrder, index) => {
        return {
          ...permissionInNewOrder.permission,
          indexOrder: index,
        }
      }),
    }
  }

  @computed
  get updatedExternalSourceObject(): UpdateExternalSource | undefined {
    if (!this.checkedPermissions) {
      return undefined
    }

    return {
      permissions: this.checkedPermissions.map((checkedPermission) => ({
        id: checkedPermission.permission.id,
      })),
    }
  }
}
