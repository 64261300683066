import * as React from 'react'
import { Form, Input, Button, Row, Col } from 'antd-v4'
import { OrganizationsModels } from '@getgreenline/organizations'
import { FormProps } from 'antd-v4/lib/form'
import { Icon } from 'antd'

interface Props<T extends OrganizationsModels.ICreateOrganization> extends FormProps<T> {
  existingOrganization?: OrganizationsModels.IOrganization
  shouldResetFields: boolean
  onSubmit: (externalSourceObject: T) => void
}

type TOrgForm = <T extends OrganizationsModels.ICreateOrganization>(
  props: Props<T>,
) => React.ReactElement

export const OrganizationForm: TOrgForm = <T extends OrganizationsModels.ICreateOrganization>(
  props: Props<T>,
) => {
  const { onSubmit, existingOrganization, shouldResetFields } = props

  const [form] = Form.useForm()

  const validateMessages = {
    required: '${label} is required!',
  }

  // Fighting the types here as can't seem to access the generic
  const onFinish = (values: any) => {
    if (shouldResetFields) form.resetFields()
    onSubmit(values)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className='mb-5'
      validateMessages={validateMessages}
      name='organization'
    >
      <Row>
        <Col span={24} className='pr-4'>
          <Form.Item
            name='name'
            label='Name'
            rules={[{ required: true }]}
            initialValue={existingOrganization?.name}
          >
            <Input prefix={<Icon type='home' style={{ color: 'rgba(0,0,0,.5)' }} />} />
          </Form.Item>
          <Form.Item name='id' hidden initialValue={existingOrganization?.id}></Form.Item>
        </Col>
      </Row>

      <Row>
        <Button className='ml-2' type='primary' htmlType='submit'>
          Submit
        </Button>
      </Row>
    </Form>
  )
}
