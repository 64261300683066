import { CreateTransaction, IPayment, ITransaction } from '@getgreenline/homi-shared'
import {
  BlazepayEnums,
  BlazepayRefundModels,
  WorldpayGlobalModels,
  WorldpayTransactionModels,
} from '@getgreenline/payments'
import { isWorldpayTransaction } from '../../../utilities/helpers'
import { PaymentServiceStore } from '../../../stores/PaymentServiceStore/PaymentServiceStore'
import { isEcomPaymentServiceTransaction } from '../Sale/ConfirmModal/PaymentOptions/PaymentOptionHelper'

export const checkPaymentHasPartialTransaction = (payment: IPayment) => {
  const { transactions } = payment

  return transactions.some((transaction) => {
    if (!(isWorldpayTransaction(transaction) && transaction.metadata)) {
      return false
    }

    const worldpayResponse = WorldpayTransactionModels.Helper.getWorldpayMetaDataFields(transaction)

    const isPartialApproval =
      worldpayResponse._processor.expressResponseCode ===
      WorldpayGlobalModels.Enums.ExpressResponseCodes.PARTIAL_APPROVAL

    return isPartialApproval
  })
}

const getOriginalPaymentServiceMetadata = async ({
  transaction,
  paymentServiceStore,
}: {
  transaction: ITransaction
  paymentServiceStore: PaymentServiceStore
}) => {
  const { merchantId } = paymentServiceStore
  const transactionInstance = new CreateTransaction(transaction.paymentTypeId)
  transactionInstance.setMetadata(transaction.metadata)

  const metadata = await BlazepayRefundModels.getUpToDateMetadata(
    merchantId,
    transactionInstance,
    paymentServiceStore.paymentOption.ecomPaymentOptionsArray,
  )

  return metadata
}

const getOriginalPaymentServiceProvider = ({
  transaction,
  paymentServiceStore,
}: {
  transaction: ITransaction
  paymentServiceStore: PaymentServiceStore
}): BlazepayEnums.Provider => {
  if (!isEcomPaymentServiceTransaction(transaction)) {
    throw new Error('Transaction is not an ECOM payment service transaction')
  }

  const ecomPaymentOptions = [...paymentServiceStore.paymentOption.ecomPaymentOptions.values()]
  const parsedMetadata = JSON.parse(transaction.metadata || '')

  const matchedPaymentOption = ecomPaymentOptions.find(
    (paymentOption) => paymentOption.id === parsedMetadata.paymentOptionId,
  )

  if (!matchedPaymentOption) {
    throw new Error('Payment service option is not found')
  }

  return matchedPaymentOption.attributes.provider
}

export const correctForEcomPaymentServicePayment = async ({
  transaction,
  paymentServiceStore,
}: {
  transaction: ITransaction
  paymentServiceStore: PaymentServiceStore
}): Promise<{ provider: BlazepayEnums.Provider; transaction: ITransaction }> => {
  const metadata = await getOriginalPaymentServiceMetadata({ transaction, paymentServiceStore })
  const provider = getOriginalPaymentServiceProvider({ transaction, paymentServiceStore })

  return {
    provider,
    transaction: {
      ...transaction,
      metadata,
    },
  }
}
