import dayjsImport from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjsImport.extend(customParseFormat)
dayjsImport.extend(isSameOrAfter)
dayjsImport.extend(relativeTime)
dayjsImport.extend(utc)
dayjsImport.extend(advancedFormat)
dayjsImport.extend(localizedFormat)

export const dayjs = dayjsImport
