import React, { useState } from 'react'
import { IDynamoDBRecord } from '@getgreenline/ocs'

export interface IPaginationOptions {
  pageSize: number
  currentPage?: number
  shouldForceRefresh?: boolean
}

export interface IQueryOptions {
  totalPages: number
  totalResults: number
  exclusiveStartKeySk: string | undefined
}

export const parseSkFromLastEvaluatedKey = (
  key: string | Record<string, string> | undefined,
): string | undefined => {
  if (!key) return

  if (typeof key === 'object' && 'sk' in key) {
    return key['sk']
  }

  if (typeof key === 'string') {
    const skKeyStartString = 'sk={S='
    const skKeyEndString = '}}'

    if (!key.includes(skKeyStartString) || !key.includes(skKeyEndString)) return

    const skStartIndex = key.indexOf(skKeyStartString) + skKeyStartString.length
    const skEndIndex = key.lastIndexOf(skKeyEndString)

    return key.substring(skStartIndex, skEndIndex)
  }
}

export const usePaginatedQuery = <T>(
  query: (options: IQueryOptions) => Promise<IDynamoDBRecord<T>>,
) => {
  const [exclusiveStartKeySk, setExclusiveStartKeySk] = useState<string | undefined>()
  const [totalResults, setTotalResults] = useState(0)
  const [totalPages, setTotalPages] = useState(0)

  const paginatedQuery = async ({
    currentPage,
    pageSize,
    shouldForceRefresh,
  }: IPaginationOptions): Promise<IDynamoDBRecord<T> | void> => {
    const options = {
      exclusiveStartKeySk: shouldForceRefresh ? undefined : exclusiveStartKeySk,
      totalResults: shouldForceRefresh ? 0 : totalResults,
      totalPages: shouldForceRefresh ? 0 : totalPages,
    }
    const currentPageNumber = currentPage || 1
    if (currentPageNumber < options.totalPages) return
    if (!options.exclusiveStartKeySk && options.totalPages !== 0) return
    const res = await query(options)
    if (!res) return
    options.exclusiveStartKeySk = parseSkFromLastEvaluatedKey(res.lastEvaluatedKey)
    options.totalResults = options.totalResults + res.count
    options.totalPages = Math.ceil(options.totalResults / pageSize)
    setExclusiveStartKeySk(options.exclusiveStartKeySk)
    setTotalResults(options.totalResults)
    setTotalPages(options.totalPages)
    return res
  }

  return paginatedQuery
}
