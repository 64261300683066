import React, { Component, PropsWithChildren } from 'react'
import { Tooltip } from 'antd'
import { TooltipProps } from 'antd/lib/tooltip'

interface Props {
  renderTooltip: boolean
  tooltipProps: TooltipProps
}

export class TooltipWrapper extends Component<PropsWithChildren<Props>> {
  render() {
    return this.props.renderTooltip ? (
      <Tooltip {...this.props.tooltipProps}>
        <div>{this.props.children}</div>
      </Tooltip>
    ) : (
      this.props.children
    )
  }
}
