import * as React from 'react'
import { GrRegex } from '../../../utilities/helpers'
import { Alert, Space, Button, Drawer } from 'antd-v4'
import { ICSVProductImport } from './AdminProductImport'
import { ICSVProductUpdate } from './AdminProductUpdate'

interface Props<T extends ICSVProductImport | ICSVProductUpdate> {
  csvProducts: T[]
  submit: () => void
}

export const SpecialCharacterProducts = <T extends ICSVProductImport | ICSVProductUpdate>(
  props: Props<T>,
) => {
  const [visible, setVisible] = React.useState(false)

  const csvProductsWithSpecialCharacters = props.csvProducts
    .map((product, index) => {
      const stringProduct = GrRegex.replaceLatin1Space(JSON.stringify(product))
      const specialCharacters = stringProduct.match(GrRegex.unicodeRegex)

      if (specialCharacters === null) return null

      return {
        rowNumber: index + 2, // +2 because the first row is the header
        name: product.name || '',
        specialCharacters,
      }
    })
    .filter((p) => p !== null)

  return (
    <div>
      {csvProductsWithSpecialCharacters.length > 0 && (
        <Alert
          showIcon
          style={{ marginBottom: 20 }}
          message={
            <div className='d-flex justify-content-center align-items-center'>
              <span className='mr-2'>
                Remove special characters from {csvProductsWithSpecialCharacters.length} products
              </span>

              <Space>
                <Button size='small' type='ghost' onClick={() => setVisible(true)}>
                  View
                </Button>
              </Space>
            </div>
          }
          type='error'
        />
      )}

      <button
        className='btn btn-success'
        disabled={csvProductsWithSpecialCharacters.length > 0}
        onClick={() => props.submit()}
      >
        Import products
      </button>

      <Drawer
        title='Products with special characters'
        width={600}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <pre className='bg-light'>{JSON.stringify(csvProductsWithSpecialCharacters, null, 2)}</pre>
      </Drawer>
    </div>
  )
}
