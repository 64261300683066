import React, { ReactElement, useEffect, useState } from 'react'
import {
  ICompanyDefinition,
  API,
  ILocation,
  HeadsetFilter,
  axios,
  ICompany,
} from '@getgreenline/homi-shared'
import { Checkbox, Select, Button } from 'antd-v4'
const { Option } = Select
import { message, Alert, Input } from 'antd'
import { observer } from 'mobx-react-lite'
import 'antd-v4/lib/select/style/index.css'
import HeadsetLocation from './HeadsetLocation'

interface Props {
  company: ICompany
  headsetFilter: HeadsetFilter
}

export const AdminHeadsetSync = observer(({ company, headsetFilter }: Props): ReactElement => {
  const { syncEmployees, syncInventory, syncCustomers, syncPayments, syncPurchaseOrders } =
    headsetFilter

  const [locations, setLocations] = useState<ILocation[]>([])
  const [synced, setSynced] = useState(false)

  const getLocations = async () => {
    const locationsReponse = await API.getLocations(company.id, false)
    setLocations(locationsReponse)
  }

  useEffect(() => {
    getLocations()
  }, [])

  const sync = async () => {
    try {
      await axios.post(`/api/v1/admin/company/${company.id}/syncHeadset`, headsetFilter)
      message.success('Syncing - will take a while to run')
      setSynced(true)
    } catch (error) {
      message.error('Unable to sync')
      console.error(error)
    }
  }

  return (
    <div>
      {locations.map((location) => (
        <HeadsetLocation key={location.id} location={location} company={company} />
      ))}
      <div>
        <Checkbox
          onChange={() => headsetFilter.setSyncEmployees(!syncEmployees)}
          checked={syncEmployees}
        >
          Employees
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={() => headsetFilter.setSyncInventory(!syncInventory)}
          checked={syncInventory}
        >
          Inventory
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={() => headsetFilter.setSyncPurchaseOrders(!syncPurchaseOrders)}
          checked={syncPurchaseOrders}
        >
          Purchase orders
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={() => headsetFilter.setSyncCustomers(!syncCustomers)}
          checked={syncCustomers}
        >
          Customers
        </Checkbox>
      </div>
      <div>
        <Checkbox
          onChange={() => headsetFilter.setSyncPayments(!syncPayments)}
          checked={syncPayments}
        >
          Payments
        </Checkbox>
      </div>
      <Select<number[]>
        mode='multiple'
        className='mt-2'
        allowClear
        placeholder='Select locations'
        style={{ width: '100%' }}
        onChange={(value) => headsetFilter.setSelectedLocationIds(value)}
      >
        {locations.map((location) => (
          <Option placeholder='All locations' value={location.id} key={location.id}>
            {location.name}: {location.id}
          </Option>
        ))}
      </Select>
      {synced && (
        <Alert
          showIcon
          type='warning'
          className='mb-3'
          message='Warning'
          description={"This has been synced, it will take a while. Please don't sync again "}
        />
      )}
      <Button className='mt-2' disabled={synced} onClick={sync} title='Sync Headset'>
        Sync Headset
      </Button>
    </div>
  )
})
