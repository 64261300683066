import * as React from 'react'
import * as Tooltip from 'react-tooltip'
import { inject, observer } from 'mobx-react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { POSStore } from '../../../stores/POSStore'
import { CreatePayment } from '@getgreenline/homi-shared'
import { EmployeeBadge } from './EmployeeBadge'
import { FormError } from '../../../components/FormError'
import { LoadingAnimation } from '../../../components/LoadingAnimation'
import { BlazeAlert } from '../../../components/BlazeAlert/BlazeAlert'

interface Props {
  onHide: () => void
  posStore?: POSStore
}

interface State {
  showEmployeePopover: boolean
  passcodeInput: string
  isSaving: boolean
  errorMessage?: string
}

@inject('posStore')
@observer
export class EmployeeSelectModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showEmployeePopover: false,
      isSaving: false,
      passcodeInput: '',
    }
  }

  componentDidMount() {
    this.props.posStore!.getCurrentShift()
  }

  clockIn() {
    this.setState({ isSaving: true, errorMessage: undefined })
    this.props
      .posStore!.trackHoursWithPasscode(this.state.passcodeInput)
      .then(() => {
        this.setState({ isSaving: false })
        this.props.onHide()
      })
      .catch((error) => {
        this.setState({ isSaving: false })
        this.setState({ errorMessage: error.response.data.message || 'There was an error' })
      })
  }

  clockOut() {
    this.setState({ isSaving: true, errorMessage: undefined })
    this.props
      .posStore!.clockOut(this.state.passcodeInput)
      .then(() => {
        this.setState({ isSaving: false })
        this.props.onHide()
      })
      .catch((error) => {
        this.setState({ isSaving: false })
        this.setState({ errorMessage: error.response.data.message || 'There was an error' })
      })
  }

  startBreak() {
    this.setState({ isSaving: true, errorMessage: undefined })
    this.props
      .posStore!.startBreak(this.state.passcodeInput)
      .then(() => {
        this.setState({ isSaving: false })
        this.props.onHide()
      })
      .catch((error) => {
        this.setState({ isSaving: false })
        this.setState({ errorMessage: error.response.data.message || 'There was an error' })
      })
  }

  clearCurrentUser() {
    this.props.posStore!.clearCurrentUser()
  }

  render() {
    const employeeHourStatus = this.props.posStore!.employeeHourStatus || []
    const shift = this.props.posStore!.currentShift

    return (
      <Modal isOpen={true} toggle={() => this.props.onHide()} size='lg'>
        <ModalHeader toggle={() => this.props.onHide()}>Select employee</ModalHeader>
        <ModalBody>
          <div className='row'>
            <div className='col-md-6'>
              <div className='mb-2'>
                <small className='blaze-text-muted'>Current employee</small>
              </div>
              <div className='d-flex'>
                {shift && shift.currentUserId && shift.currentUserName ? (
                  <>
                    <EmployeeBadge
                      employee={{
                        id: shift.currentUserId,
                        name: shift.currentUserName,
                      }}
                      status='success'
                    />
                    <div className='ml-2'>
                      <div>
                        <a onClick={() => this.clearCurrentUser()}>Clear current employee</a>
                      </div>
                      <div className='blaze-text-muted'>Your hours will still track</div>
                    </div>
                  </>
                ) : (
                  <span>No employee selected</span>
                )}
              </div>
              <hr />
              <div className='mb-2'>
                <small className='blaze-text-muted'>All employees</small>
              </div>
              <div className='d-flex'>
                {employeeHourStatus.map((employee) => {
                  return (
                    <EmployeeBadge
                      key={employee.employeeId}
                      employee={{ id: employee.employeeId, name: employee.employeeName }}
                      status={employee.tracking ? 'success' : 'danger'}
                    />
                  )
                })}
              </div>
            </div>

            <div className='col-md-6'>
              <div className='form-group'>
                <input
                  style={{ height: 0, width: 0, border: 0 }}
                  type='password'
                  name='preventAutoComplete'
                />
                <input
                  className='form-control form-control-lg mb-2'
                  placeholder='Passcode'
                  type='password'
                  value={this.state.passcodeInput}
                  onChange={(e) => this.setState({ passcodeInput: e.target.value })}
                />
                {this.state.isSaving ? (
                  <LoadingAnimation />
                ) : (
                  <div className='d-flex'>
                    <button className='btn btn-lg btn-primary w-100' onClick={() => this.clockIn()}>
                      Clock in
                    </button>
                    <button
                      className='btn btn-lg btn-warning w-100 ml-2 mr-2'
                      onClick={() => this.startBreak()}
                    >
                      Break
                    </button>
                    <button className='btn btn-lg btn-danger w-100' onClick={() => this.clockOut()}>
                      Clock out
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          {this.state.errorMessage && (
            <BlazeAlert type='error' showIcon>
              {this.state.errorMessage}
            </BlazeAlert>
          )}
        </ModalBody>
      </Modal>
    )
  }
}
