import { productApi } from '@getgreenline/products'
import { BrowserEventBus, PaymentPageModels } from '@getgreenline/mfe'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { useEffect } from 'react'
import { ICompany } from '@getgreenline/homi-shared'

interface Props {
  currentCompanyStore?: CurrentCompanyStore
}

export const useEvents = ({ currentCompanyStore }: Props) => {
  if (!currentCompanyStore) return
  const eventBus = new BrowserEventBus('blaze-pay')

  useEffect(() => {
    const removePaymentServiceUpdatedListener =
      eventBus.subscribe<PaymentPageModels.IPaymentServiceUpdatedEventContract>(
        PaymentPageModels.PaymentPageEventKeys.PAYMENT_SERVICE_UPDATED,
        async ({ detail }) => {
          if (!detail?.paymentService.enabled) return
          const companyId = buildCompanyId(currentCompanyStore?.company, detail?.paymentService)
          if (!companyId) return

          await productApi.createSystemFeesProducts(companyId)
        },
      )

    return () => {
      removePaymentServiceUpdatedListener()
    }
  }, [])
}

const buildCompanyId = (
  company?: ICompany,
  paymentService?: PaymentPageModels.IPaymentServiceConfiguration,
) => {
  const merchantCompanyId: string | undefined = (paymentService?.merchantId || '').split('-')[1]
  return merchantCompanyId ? parseInt(merchantCompanyId) : company?.id
}
