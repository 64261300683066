import React, { useEffect } from 'react'
import { Form, Input, Button, message } from 'antd-v4'
import { LocalStorage } from '../../../utilities/LocalStorage'

export const AdminDevelopmentSettings = () => {
  const [form] = Form.useForm()

  useEffect(() => {
    const apiUrl = LocalStorage.getOverrideAPIUrl()
    if (apiUrl) {
      form.setFieldsValue({ apiUrl })
    }
  }, [])

  const onFinish = (values: { apiUrl: string }) => {
    LocalStorage.setOverrideAPIUrl(values.apiUrl)
    message.success('API URL updated')
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
    message.error('Failed to update API URL')
  }

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
    >
      <Form.Item label='API URL' name='apiUrl'>
        <Input placeholder={process.env.REACT_APP_ROOT_API_URL} allowClear />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
