export const updatePaginationKeysBasedOnPageMovement = (
  keys: string[] | undefined,
  pageMovement: number,
  nextKey: string | null,
) => {
  const isUserMovingToPreviousPage = keys && pageMovement < 0
  const isUserMovingToNextPage = keys && pageMovement > 0
  if (!keys) {
    return undefined
  }
  if (isUserMovingToPreviousPage) {
    return keys.slice(0, -1)
  }
  if (isUserMovingToNextPage && nextKey) {
    return [...keys, nextKey]
  }
  if (isUserMovingToNextPage && !nextKey) {
    return keys
  }
}

// Can't send directly the lastEvaluatedKey as the exclusiveStartKey while using API Gateway mapping templates
// Need to deconstruct using the parseSkFromLastEvaluatedKey function, and recreate the full exclusiveStartKey in api
export const parseSkFromLastEvaluatedKey = (lastEvaluatedKey: string) => {
  const startIndex = lastEvaluatedKey.indexOf('type#')
  const endIndex = lastEvaluatedKey.lastIndexOf('}') - 1

  return lastEvaluatedKey.substring(startIndex, endIndex)
}
