import React from 'react'
import { Button } from 'antd-v4'

export const AdminDevelopmentTools = () => {
  return (
    <div style={{ marginLeft: 50 }}>
      <Button
        onClick={() => {
          throw new Error('Test error')
        }}
        danger
      >
        Test uncaught error
      </Button>
    </div>
  )
}
