import { observable, action, computed } from 'mobx'
import {
  IUserResponse,
  Province,
  IEntityUser,
  UserPermission,
  PermissionNames,
  IUserPreference,
  IUserPreferenceUpdate,
  API,
  axios,
  ILocation,
} from '@getgreenline/homi-shared'
import * as auth from '../utilities/auth'
import Endpoints from '../constants/Endpoints'
import { Track } from '../Track'
import { setRootAuthToken } from '../utilities/environment'
import { browserHistory } from 'react-router'
import { Routes } from '../constants/Routes'
import { CurrentCompanyStore } from './CurrentCompanyStore'
import { CompaniesModels } from '@getgreenline/companies'

export class CurrentUserStore {
  @observable currentUser?: IUserResponse
  @observable userPreferences?: IUserPreference[]
  @observable myEntityUsers?: IEntityUser[]
  @observable userPermissions?: UserPermission[]

  getLocationsWithPermission = (locations: ILocation[], permission: PermissionNames) => {
    if (!this.userPermissions) return []
    return locations.filter((location) => this.hasPermissionAtEntity(permission, location.id))
  }

  @action
  getCurrentHumanUser = async () => {
    const authToken = window.localStorage.getItem('authToken')

    if (!authToken) {
      return Promise.resolve()
    }

    setRootAuthToken(authToken)

    try {
      const user = await API.getMe()
      this.currentUser = user
      return user
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.log('Invalid user authToken. Removing from localStorage.')
        window.localStorage.removeItem('authToken')
      }
    }
  }

  @action
  getMyPermissions() {
    return API.getMyPermissions(this.currentUser!.id).then((userPermissions) => {
      this.userPermissions = userPermissions
      return userPermissions
    })
  }

  hasPermissionAtEntity(permissionName: PermissionNames, entityId: number) {
    // Used for admin mode to allow access to all areas
    if (this.userPermissions && this.isAdmin) {
      return true
    }

    if (!this.userPermissions) {
      return false
    } else if (entityId === undefined) {
      const foundPermission = this.userPermissions.find(
        (userPermission) => userPermission.permission.name === permissionName,
      )
      return !!foundPermission
    } else {
      const foundPermission = this.userPermissions.find(
        (userPermission) =>
          userPermission.permission.name === permissionName &&
          (userPermission.entityId === entityId || userPermission.parentEntityId === entityId),
      )
      return !!foundPermission
    }
  }

  canViewOCSCompliance(entity: CurrentCompanyStore) {
    if (!entity.company) {
      return false
    }

    const isCompanyProvinceON = entity.company.province === CompaniesModels.Province.ON
    const isOCSFeatureFlagEnabled = entity.canUseOCSCompliance

    const userCanViewOCSCompliance =
      this.hasPermissionAtEntity(
        PermissionNames.CAN_MANAGE_COMPLIANCE_REPORTING,
        entity.company.id,
      ) &&
      isCompanyProvinceON &&
      isOCSFeatureFlagEnabled

    return userCanViewOCSCompliance
  }

  @action
  login(email: string, password: string) {
    return API.login(email, password).then((userObject) => {
      Track.track('dashboard:user_login')
      window.localStorage.setItem('authToken', userObject.authToken)
      return userObject
    })
  }

  @action
  register(
    name: string,
    email: string,
    password: string,
    companyName: string,
    country: string,
    timezone: string,
    province: Province,
  ) {
    return API.register(name, email, password, companyName, country, timezone, province)
  }

  @action
  getUserPreferences() {
    return API.getUserPreferences().then((userPreferences) => {
      this.userPreferences = userPreferences
      return userPreferences
    })
  }

  @action
  updateUser(updateObject: any) {
    return axios.patch(Endpoints.PATCH_ME, updateObject).then((response) => {
      const updatedUser = response.data as IUserResponse
      this.currentUser = updatedUser
      return updatedUser
    })
  }

  @action
  updateUserPreference(preferenceId: number, updateObject: IUserPreferenceUpdate) {
    return API.updateUserPreference(
      preferenceId,
      updateObject.booleanValue,
      updateObject.stringValue,
    ).then(() => {
      this.getUserPreferences()
    })
  }

  verifyEmail(token: string) {
    return axios.post(Endpoints.VERIFY_EMAIL(token))
  }

  public static getStatus = auth.getUserStatus

  public static resendInvite = auth.resendInvite

  static forgotPassword(email: string) {
    return axios.post(Endpoints.FORGOT_PASSWORD, {
      email: email,
    })
  }

  static forgotPasswordV2 = auth.forgotPasswordV2

  static resetPassword(token: string, password: string) {
    return axios.post(Endpoints.RESET_PASSWORD, {
      token: token,
      password: password,
    })
  }

  static resetPasswordV2 = auth.resetPasswordV2

  static confirmSignup = auth.confirmSignup

  @action
  async logout() {
    window.localStorage.removeItem('authToken')
    this.currentUser = undefined
    this.userPreferences = undefined
    try {
      await API.logout()
      Track.track('dashboard:user_logout')
      Track.clear()
    } catch (error) {
      console.error(error)
    }
    window.location.href = '/'
  }

  @action
  async logoutV2() {
    try {
      await auth.signOut()
      window.localStorage.removeItem('authToken')
      this.currentUser = undefined
      this.userPreferences = undefined
      Track.track('dashboard:user_logout')
      Track.clear()
    } catch (error) {
      console.error(error)
    }
    browserHistory.push(Routes.LOGIN_V2)
  }

  @computed
  get isAdmin() {
    return this.currentUser && this.currentUser.isAdmin
  }

  @computed
  get entityAssignmentIds() {
    return (
      this.currentUser &&
      this.currentUser.entityAssignments.map((entityAssignment) => entityAssignment.entity.id)
    )
  }
}
