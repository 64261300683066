import React, { useState } from 'react'
import { Dropdown, Menu, message } from 'antd-v4'
import moment from 'moment'
import { ProvinceProductsHeader } from './ProvinceProductsHeader'
import { parseAndDownloadCSV } from '../../../utilities/helpers'
import './ProvinceProductsExport.scss'
import { CompaniesModels } from '@getgreenline/companies'
import { provinceProductsApi } from '@getgreenline/products'
import {
  createABExportProducts,
  createBCExportProducts,
  createONAndOtherProvinceExportProducts,
} from '../../../utilities/provinceProductHelpers'
import { IProvinceProductExport } from '../../../constants/ProvinceProducts'

export function ProvinceProductsExport() {
  const [isLoading, setIsLoading] = useState(false)
  const [province, setProvince] = useState<CompaniesModels.Province | undefined>()
  const exportCSV = async () => {
    if (!province) return message.error('Please select a province')

    setIsLoading(true)

    try {
      const provinceProducts = await provinceProductsApi.getProvinceProductsFromSignedUrl(province)

      let formattedProvinceProducts: IProvinceProductExport[] = []

      if (province === CompaniesModels.Province.AB) {
        formattedProvinceProducts = createABExportProducts(provinceProducts)
      } else if (province === CompaniesModels.Province.BC) {
        formattedProvinceProducts = createBCExportProducts(provinceProducts)
      } else {
        formattedProvinceProducts = createONAndOtherProvinceExportProducts(provinceProducts)
      }

      parseAndDownloadCSV(
        `${province}-province-products-${moment().format('YYYY-MM-DD')}`,
        formattedProvinceProducts,
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <ProvinceProductsHeader />
      <div className='province-products-export'>
        <Dropdown.Button
          size='large'
          trigger={['click']}
          placement='bottomCenter'
          disabled={isLoading}
          onClick={exportCSV}
          overlay={
            <Menu
              style={{ height: 230, overflowY: 'scroll' }}
              onClick={(e) => setProvince(e.key as CompaniesModels.Province)}
            >
              <Menu.Item key={CompaniesModels.Province.AB}>{CompaniesModels.Province.AB}</Menu.Item>
              <Menu.Item key={CompaniesModels.Province.BC}>{CompaniesModels.Province.BC}</Menu.Item>
              <Menu.Item key={CompaniesModels.Province.ON}>{CompaniesModels.Province.ON}</Menu.Item>
              {Object.keys(CompaniesModels.Province).map((key) => {
                const province: CompaniesModels.Province = CompaniesModels.Province[key]
                const hasCatalogue = [
                  CompaniesModels.Province.AB,
                  CompaniesModels.Province.BC,
                  CompaniesModels.Province.ON,
                ].includes(province)

                if (hasCatalogue) return

                return <Menu.Item key={province}>{province}</Menu.Item>
              })}
            </Menu>
          }
          buttonsRender={([leftButton, rightButton]) => [
            leftButton,
            React.cloneElement(rightButton as any, { loading: isLoading }),
          ]}
        >
          {province ? `Export ${province}` : 'Select province'}
        </Dropdown.Button>
      </div>
    </>
  )
}
