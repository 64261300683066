import { observable, computed, action, ObservableMap } from 'mobx'
import {
  ICompany,
  IPaginatedResponse,
  ICategoryContract,
  IBaseCategory,
  IComplianceCategoryContract,
  IProductSearchFilter,
  CreateTaxGroup,
  UpdateTaxGroup,
  API,
  Supplier,
} from '@getgreenline/homi-shared'

import { throttle } from 'lodash'
import { ProductFilter } from '../../containers/Dashboard/DashboardProducts/ProductListing/ProductFilter'
import {
  productApi,
  discountApi,
  TaxModels,
  taxApi,
  DiscountModels,
  ProductModels,
} from '@getgreenline/products'
import { CategoriesModels } from '@getgreenline/categories'
import { ExternalLoyaltyModels } from '@getgreenline/external-loyalty'
import { DiscountsPermissions } from '../../utilities/DiscountHelpers'

export class ProductStore {
  @observable company: ICompany
  @observable suppliers?: Supplier[]
  @observable categories?: ICategoryContract[]
  @observable complianceCategories?: IComplianceCategoryContract[]
  @observable discounts?: DiscountModels.IDiscount[]
  @observable paginatedDiscounts?: IPaginatedResponse<DiscountModels.IDiscount>
  @observable nestedProducts?: ProductModels.INestedProduct[]
  @observable inventoryProducts?: ProductModels.IBaseProduct[]
  @observable inactiveNestedProducts?: ProductModels.INestedProduct[]
  @observable deletedNestedProducts?: ProductModels.INestedProduct[]
  @observable productFilter?: ProductFilter
  @observable bundledDiscounts: DiscountModels.IDiscount[] = []
  @observable externalDiscounts = observable.map<string, ExternalLoyaltyModels.IRewardContract>()

  constructor(company: ICompany) {
    this.company = company
  }

  @computed
  get flattenedProducts() {
    if (!this.nestedProducts) {
      return undefined
    }
    return this.flattenProducts(this.nestedProducts)
  }

  flattenProducts(nestedProducts: ProductModels.INestedProduct[]) {
    const flattenedProducts: Array<ProductModels.IBaseProduct & { parentSKU: string | null }> = []
    nestedProducts.forEach((product) => {
      flattenedProducts.push({ parentSKU: null, ...product })
      product.childProducts.forEach((childProduct) => {
        flattenedProducts.push({ parentSKU: product.sku, ...childProduct })
      })
    })

    return flattenedProducts
  }

  public static flattenProductsV2(
    nestedProducts: ProductModels.INestedProduct[],
  ): ProductModels.IChildProduct[] {
    return nestedProducts.reduce((acc, { childProducts, ...product }) => {
      acc.push(...childProducts, product)
      return acc
    }, [] as ProductModels.IChildProduct[])
  }

  @computed
  get sellableProducts() {
    if (!this.nestedProducts) {
      return undefined
    }

    return this.getSellableProducts(this.nestedProducts)
  }

  getSellableProducts(
    nestedProducts: ProductModels.INestedProduct[],
  ): ProductModels.IBaseProduct[] {
    const sellableProducts: ProductModels.IBaseProduct[] = []
    nestedProducts.forEach((product) => {
      if (product.isMasterProduct) {
        product.childProducts.forEach((childProduct) => {
          sellableProducts.push(childProduct)
        })
      } else {
        sellableProducts.push(product)
      }
    })
    return sellableProducts
  }

  @observable taxGroups?: TaxModels.TaxGroup[]

  @computed
  get flattenedCategories() {
    const categories = this.categories || []
    const flattenedCategories: IBaseCategory[] = []
    categories.forEach((category) => {
      flattenedCategories.push(category)
      category.childCategories.forEach((childCategory) => {
        const clonedChildCategory: IBaseCategory = JSON.parse(JSON.stringify(childCategory))
        flattenedCategories.push(clonedChildCategory)
      })
    })
    return flattenedCategories
  }

  @computed
  get childCategories() {
    const categories = this.categories || []
    const childCategories: IBaseCategory[] = []
    categories.forEach((category) => {
      category.childCategories.forEach((childCategory) => {
        const clonedChildCategory: IBaseCategory = JSON.parse(JSON.stringify(childCategory))
        childCategories.push(clonedChildCategory)
      })
    })
    return childCategories
  }
  @action
  async getSuppliers() {
    const suppliers = await API.getSuppliers(this.company.id)
    this.suppliers = suppliers
    return suppliers
  }

  @action
  async getCategories() {
    const categories = await API.getCategories(this.company.id)
    this.categories = categories
    return categories
  }

  @action
  async addCategory(createObject: any) {
    const category = await API.addCategory(this.company.id, createObject)
    this.getCategories()
    return category
  }

  @action
  async updateCategory(categoryId: string, createObject: any) {
    const category = await API.updateCategory(this.company.id, categoryId, createObject)
    this.getCategories()
    return category
  }

  @action
  async updateCategories(updateObject: IBaseCategory[]) {
    const updatedCategories = await API.updateCategories(this.company.id, updateObject)

    await this.getCategories()

    return updatedCategories
  }

  @action
  async updateCategoryOrder(ordering: Array<{ categoryId: string; index: number }>) {
    await API.updateCategoryOrder(this.company.id, ordering)
    this.getCategories()
  }

  @action
  async deleteCategory(categoryId: string, newCategoryId?: string) {
    await API.deleteCategory(this.company.id, categoryId, newCategoryId)
    this.getCategories()
  }

  @action
  async mergeCategory(categoryId: string, newParentCategoryId: string) {
    await API.mergeCategory(this.company.id, categoryId, newParentCategoryId)
    this.getCategories()
  }

  @action
  async getComplianceCategories() {
    const complianceCategories = await API.getComplianceCategories(this.company.id)
    this.complianceCategories = complianceCategories
    return complianceCategories
  }

  // Default product search filter
  productSearchFilters: IProductSearchFilter = {
    searchQuery: '',
    selectedSupplierId: undefined,
    selectedCategoryIds: [],
    offset: 0,
    limit: 50,
  }

  @action
  async getProducts(searchFilters: IProductSearchFilter) {
    this.productSearchFilters = searchFilters
    const paginatedProducts = await productApi.getProducts(
      this.company.id,
      this.productSearchFilters,
    )
    this.setProducts(paginatedProducts.products)
    return paginatedProducts
  }

  @action
  setProducts(products: ProductModels.INestedProduct[]) {
    this.nestedProducts = products
    this.inventoryProducts = this.flattenProducts(this.nestedProducts).filter(
      (p) => p.trackInventory && p.isActive,
    )
  }

  @action
  async getInactiveProducts(searchFilters?: IProductSearchFilter) {
    const paginatedProducts = await productApi.getProducts(this.company.id, searchFilters)
    this.inactiveNestedProducts = paginatedProducts.products
    return paginatedProducts
  }

  @action
  async getDeletedProducts(searchFilters?: IProductSearchFilter) {
    const paginatedProducts = await productApi.getProducts(this.company.id, searchFilters)
    this.deletedNestedProducts = paginatedProducts.products
    return paginatedProducts
  }

  @action
  resetProducts() {
    this.nestedProducts = undefined
    this.inactiveNestedProducts = undefined
    this.deletedNestedProducts = undefined
  }

  async getProductById(productId: string) {
    const product = await API.getProductById(this.company.id, productId)
    return product
  }

  @action
  async addProduct(companyId: number, createObject: any) {
    const product = await API.addProduct(companyId, createObject)
    return product
  }

  @action
  async editProduct(
    companyId: number,
    productId: string,
    createObject: any,
    canUseCannabinoidPerLot = false,
  ) {
    const product = await productApi.updateProduct(
      companyId,
      productId,
      createObject,
      canUseCannabinoidPerLot,
    )
    return product
  }

  @action
  async deleteProduct(companyId: number, productId: string) {
    const product = await API.deleteProduct(companyId, productId)
    const newProducts = this.nestedProducts?.filter((p) => p.id !== productId)
    this.setProducts(newProducts || [])
    return product
  }

  @action
  async getTaxGroups() {
    const taxGroups = await taxApi.getTaxGroups(this.company.id)
    this.taxGroups = taxGroups
    return taxGroups
  }

  @action
  async addTaxGroup(createObject: CreateTaxGroup) {
    const taxGroup = await taxApi.addTaxGroup(this.company.id, createObject)
    this.getTaxGroups()
    return taxGroup
  }

  @action
  async editTaxGroup(updateObject: UpdateTaxGroup) {
    const taxGroup = await taxApi.updateTaxGroup(this.company.id, updateObject.id, updateObject)
    this.getTaxGroups()
    return taxGroup
  }

  @action
  async deleteTaxGroup(taxGroupId: number) {
    const taxGroup = await taxApi.deleteTaxGroup(this.company.id, taxGroupId)
    this.getTaxGroups()
    return taxGroup
  }

  @action
  async getDiscounts({
    searchFilter,
    permissions,
  }: {
    searchFilter?: DiscountModels.IDiscountFilter
    permissions: DiscountsPermissions
  }) {
    const discounts = await discountApi.getDiscountsV2WithoutPagination(
      this.company.id,
      searchFilter,
    )
    const noLoyaltyIsEnabled = !permissions.canAccessLoyalty && !permissions.canUseExternalLoyalty
    const canUseExternalLoyalty = !!permissions?.canUseExternalLoyalty
    const updatedDiscounts =
      noLoyaltyIsEnabled || canUseExternalLoyalty
        ? this.overwriteDiscountsLoyaltyCosts(discounts)
        : discounts
    this.discounts = updatedDiscounts
    return updatedDiscounts || []
  }

  @action
  async getPaginatedDiscounts(searchFilter?: DiscountModels.IPaginatedDiscountFilter) {
    const discounts = await discountApi.getDiscountsV2(this.company.id, searchFilter)

    this.paginatedDiscounts = discounts
    return discounts
  }

  @action
  async addDiscount(newDiscount: DiscountModels.CreateOrEditDiscount) {
    const discount = await discountApi.addDiscountV2(this.company.id, newDiscount)
    return discount
  }

  @action
  async editDiscount(discount: DiscountModels.CreateOrEditDiscount) {
    if (!discount.id) {
      throw new Error('"discount.id" is required')
    }

    const updatedDiscount = await discountApi.updateDiscountV2(this.company.id, {
      id: discount.id,
      ...discount,
    })

    return updatedDiscount
  }

  @action
  async deleteDiscount({
    discountId,
    searchFilter,
    permissions,
  }: {
    discountId: number
    searchFilter?: DiscountModels.IPaginatedDiscountFilter
    permissions: DiscountsPermissions
  }) {
    await discountApi.deleteDiscount(this.company.id, discountId)

    if (searchFilter) {
      this.getPaginatedDiscounts(searchFilter)
    } else {
      this.getDiscounts({ permissions })
    }
  }

  @computed
  get mappedCategories(): Map<string, ICategoryContract> {
    return new Map((this.categories || []).map((c) => [c.id, c]))
  }

  @computed
  get mappedFlattenedCategories(): Map<string, IBaseCategory> {
    return new Map((this.flattenedCategories || []).map((c) => [c.id, c]))
  }

  @computed
  get filteredCategories(): ICategoryContract[] {
    return (
      this.categories?.filter(
        (category) => category.categoryType !== CategoriesModels.CategoryType.SYSTEM_FEES,
      ) ?? []
    )
  }

  @computed
  get filteredFlatCategories(): CategoriesModels.IBaseCategory[] {
    return this.flattenedCategories.filter(
      (category) => category.categoryType !== CategoriesModels.CategoryType.SYSTEM_FEES,
    )
  }

  @action
  setBundledDiscounts = (
    bundledDiscounts: DiscountModels.IDiscount[],
  ): DiscountModels.IDiscount[] => {
    this.bundledDiscounts = bundledDiscounts
    return bundledDiscounts
  }

  @action
  setExternalDiscounts = (
    externalDiscounts: ObservableMap<string, ExternalLoyaltyModels.IRewardContract>,
  ) => {
    this.externalDiscounts = externalDiscounts
  }

  @action
  updateExternalDiscountByDiscountId = (
    discountId: string,
    externalDiscount: ExternalLoyaltyModels.IRewardContract,
  ) => {
    this.externalDiscounts.set(discountId, externalDiscount)
  }

  @action
  overwriteDiscountsLoyaltyCosts(
    discounts: DiscountModels.IDiscount[],
  ): DiscountModels.IDiscount[] {
    const overwritenDiscounts = discounts.map((discount) => {
      if (discount.isLoyalty) {
        return {
          ...discount,
          isLoyalty: false,
          loyaltyCost: 0,
        }
      }
      const matchingExternalDiscount = this.externalDiscounts.get(discount.id.toString())
      return {
        ...discount,
        loyaltyCost: matchingExternalDiscount?.points ?? discount.loyaltyCost ?? 0,
      }
    })
    return overwritenDiscounts
  }
}
