import * as React from 'react'
import Button, { ButtonProps } from 'antd/lib/button'
import { Tooltip, Icon } from 'antd'
import { ThemeType } from 'antd/lib/icon'

export type TBlazeButtonType =
  | 'retail'
  | 'dispatch'
  | 'blazepay'
  | 'manufacturing'
  | 'grow'
  | 'distro'
  | 'danger'
  | 'black'

interface Props {
  blazeButtonType?: TBlazeButtonType
  buttonProps: ButtonProps
  tooltipText?: string
  titleProps?: {
    title: string | JSX.Element
    widthBreakPoint?: number
  }
  iconProps?: {
    icon: string
    theme?: ThemeType
  }
}

const addBlazeButtonTypeClassName = (props: Props) => {
  const { blazeButtonType, buttonProps } = props
  if (!blazeButtonType) return buttonProps.className

  let buttonClassName = `blz-btn-${blazeButtonType} ${buttonProps.className || ''}`
  if (buttonProps.type === 'ghost') buttonClassName += ` ant-btn-background-ghost`
  return buttonClassName.trim()
}

const StyledButton = (props: Props) => {
  const { buttonProps, titleProps, iconProps } = props

  /**
   * unfortunately ant design adds padding even when "title" is not present.
   * leave it out completely if title is undefined.
   */
  const className = addBlazeButtonTypeClassName(props)
  const iconTheme = iconProps?.theme || 'outlined'

  return titleProps?.title ? (
    <Button {...buttonProps} className={className}>
      {iconProps && <Icon type={iconProps.icon} theme={iconTheme} />}
      {titleProps?.title && titleProps.title}
    </Button>
  ) : (
    <Button {...buttonProps} className={className}>
      {iconProps && <Icon type={iconProps.icon} theme={iconTheme} />}
    </Button>
  )
}

const ButtonWithTooltip = ({
  props,
  title,
}: {
  props: Props
  title: string | JSX.Element
  tooltipPlacement?: string
}) => {
  return (
    <Tooltip title={title} placement='bottom'>
      {/* 
        do not remove <div>
        The StyledButton custom-component is wrapped with <div> so that Tooltip can hold a reference to it to render the message
        // reference: https://stackoverflow.com/questions/57527896/material-ui-tooltip-doesnt-display-on-custom-component-despite-spreading-props
      */}
      <div>
        <StyledButton {...props} />
      </div>
    </Tooltip>
  )
}

const ButtonWithBreakPoint = ({
  props,
  title,
  width,
  widthBreakPoint,
}: {
  props: Props
  title: string | JSX.Element
  width: number
  widthBreakPoint: number
}): JSX.Element => {
  return width <= widthBreakPoint ? (
    <ButtonWithTooltip
      props={{
        ...props,
        titleProps: undefined,
      }}
      title={title}
    />
  ) : (
    <StyledButton {...props} />
  )
}

export const BlazeButton = (props: Props) => {
  const { titleProps } = props
  const [width, setWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    return function cleanup() {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  return (
    <>
      {titleProps?.widthBreakPoint ? (
        <ButtonWithBreakPoint
          props={props}
          width={width}
          widthBreakPoint={titleProps.widthBreakPoint}
          title={titleProps.title}
        />
      ) : props.tooltipText ? (
        <ButtonWithTooltip props={props} title={props.tooltipText} />
      ) : (
        <StyledButton {...props} />
      )}
    </>
  )
}
