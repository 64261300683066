import * as React from 'react'
import moment from 'moment'
import CSVReader from 'react-csv-reader'
import { API, CreateCustomer, ICompany } from '@getgreenline/homi-shared'
import { AppMainView, AppView } from '../../../components/AppView'
import { browserHistory } from 'react-router'
import { Alert, Icon, PageHeader, Progress, Skeleton } from 'antd'

interface Props {
  params: {
    companyId: string
  }
}

interface State {
  company?: ICompany
  data?: any[]
  isImporting: boolean
  importedRows: number
  skippedRows: any[]
}

function getIndex(array: string[], value: string): number | null {
  const index = array.map((e) => (e || '').toUpperCase()).indexOf(value.toUpperCase())
  if (index === -1) {
    // Not found. In JS, the result is -1.
    return null
  } else {
    return index
  }
}

function cleanString(value: string | null | undefined): string | null {
  if (!value) {
    return null
  } else {
    return value.trim()
  }
}

export class AdminCustomerImport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isImporting: false,
      importedRows: 0,
      skippedRows: [],
    }
  }

  componentDidMount() {
    this.loadCompany()
  }

  async loadCompany() {
    const { params } = this.props
    const companyId = parseInt(params.companyId)
    const company = await API.getCompanyById(companyId, false)
    this.setState({
      company: company,
    })
  }

  onFileLoad(data: any) {
    if (data.length < 1) {
      return
    }
    this.setState({ data: data })
  }

  getMappedCustomers() {
    const { data } = this.state

    if (!data) {
      return []
    }

    const header: string[] = data[0]
    const dataWithoutHeader = data.slice(1, data.length)

    const mappedCustomers = dataWithoutHeader
      .filter((row) => {
        const nameIndex = getIndex(header, 'Name')
        return nameIndex !== null && row[nameIndex]
      })
      .map((row) => {
        // Required fields
        const nameIndex = getIndex(header, 'Name')

        // Optional fields
        const emailIndex = getIndex(header, 'Email')
        const phoneIndex = getIndex(header, 'Phone')
        const cardIdIndex = getIndex(header, 'Card ID')
        const birthdayIndex = getIndex(header, 'Birthday')
        const genderIndex = getIndex(header, 'Gender')
        const cityIndex = getIndex(header, 'City')
        const addressIndex = getIndex(header, 'Address')
        const postalCodeIndex = getIndex(header, 'Postal Code')
        const notesIndex = getIndex(header, 'Notes')

        const customerData = {
          name: nameIndex !== null ? row[nameIndex] : null,
          email: emailIndex !== null ? cleanString(row[emailIndex]) : null,
          phone: phoneIndex !== null ? cleanString(row[phoneIndex]) : null,
          cardId: cardIdIndex !== null ? cleanString(row[cardIdIndex]) : null,
          birthday: birthdayIndex !== null ? cleanString(row[birthdayIndex]) : null,
          gender: genderIndex !== null ? cleanString(row[genderIndex]) : null,
          city: cityIndex !== null ? cleanString(row[cityIndex]) : null,
          address: addressIndex !== null ? cleanString(row[addressIndex]) : null,
          postalCode: postalCodeIndex !== null ? cleanString(row[postalCodeIndex]) : null,
          notes: notesIndex !== null ? cleanString(row[notesIndex]) : null,
        }

        // Clean data
        if (customerData.phone) {
          customerData.phone = customerData.phone
            .trim()
            .replace('-', '')
            .replace('-', '')
            .replace(' ', '')
            .replace(' ', '')
        }

        return customerData
      })

    return mappedCustomers
  }

  async submit() {
    const { company } = this.state
    if (company && window.confirm('Are you sure? Please stay on this page while importing.')) {
      const mappedCustomers = this.getMappedCustomers()

      this.setState({ isImporting: true })

      for (const mappedCustomer of mappedCustomers) {
        const newCustomer = new CreateCustomer()
        newCustomer.name = mappedCustomer.name
        newCustomer.email = mappedCustomer.email
        newCustomer.phone = mappedCustomer.phone
        ;(newCustomer.birthday = mappedCustomer.birthday
          ? moment(mappedCustomer.birthday).toDate()
          : null),
          (newCustomer.city = mappedCustomer.city)
        newCustomer.address = mappedCustomer.address
        newCustomer.postalCode = mappedCustomer.postalCode
        newCustomer.notes = mappedCustomer.notes

        try {
          const createdCustomer = await API.createCustomer(company.id, newCustomer)
          this.setState({ importedRows: this.state.importedRows + 1 })
        } catch (error) {
          console.error(error)
          this.setState({
            skippedRows: [...this.state.skippedRows, newCustomer],
          })
        }
      }
    }
  }

  private headerMappingDisplay(header: string[], columnName: string) {
    const index = getIndex(header, columnName)
    return (
      <div key={columnName}>
        <Icon
          type='check-circle'
          theme='twoTone'
          twoToneColor={index === null ? '#eb2f96' : '#52c41a'}
        />
        <span className='ml-2'>{columnName}</span>
      </div>
    )
  }

  render() {
    const { company } = this.state

    if (!company) {
      return (
        <AppView column>
          <AppMainView>
            <Skeleton active />
          </AppMainView>
        </AppView>
      )
    }

    const { data, importedRows, skippedRows } = this.state

    const mappedCustomers = this.getMappedCustomers()

    let headerMapping
    if (data) {
      const header: string[] = data[0]

      headerMapping = (
        <>
          {this.headerMappingDisplay(header, 'Name')}
          {this.headerMappingDisplay(header, 'Email')}
          {this.headerMappingDisplay(header, 'Phone')}
          {this.headerMappingDisplay(header, 'Card ID')}
          {this.headerMappingDisplay(header, 'Birthday')}
          {this.headerMappingDisplay(header, 'Gender')}
          {this.headerMappingDisplay(header, 'City')}
          {this.headerMappingDisplay(header, 'Address')}
          {this.headerMappingDisplay(header, 'Postal Code')}
          {this.headerMappingDisplay(header, 'Notes')}
        </>
      )
    }

    let nameWarnings
    // When saving CSV files in Excel, large numbers may be automatically converted to scientific notation
    const badNames = mappedCustomers.filter((p) => !p.name)
    if (badNames.length > 0) {
      nameWarnings = (
        <Alert
          type='warning'
          message='Name warnings'
          style={{ marginBottom: 10 }}
          description={
            <>
              <b>Please check the name of the following rows:</b>
              <ul>
                {badNames.map((b, index) => (
                  <li key={index}>{`Name: ${b.name}, Phone: ${b.phone}, Email: ${b.email}`}</li>
                ))}
              </ul>
            </>
          }
        />
      )
    }

    return (
      <AppView column>
        <PageHeader
          title={company ? `${company.name} - Customer CSV import` : 'Loading...'}
          subTitle={company ? company.province : ''}
          onBack={() =>
            browserHistory.push(company ? `/admin/companies/${company.id}` : `/admin/companies`)
          }
        />

        <AppMainView>
          <div className='container'>
            <Alert
              type='info'
              message='CSV template'
              description={
                <>
                  <p>
                    Importing products requires the following columns to be available
                    (case-sensitive):
                  </p>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p>
                        <b>Required CSV columns</b>
                      </p>
                      <ul>
                        <li>
                          Name <span className='text-danger'>*required</span>
                        </li>
                        <ul>
                          <li>First and last name combined</li>
                        </ul>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <b>Optional CSV columns</b>
                      </p>
                      <ul>
                        <li>Email</li>
                        <ul>
                          <li>Correct format required</li>
                        </ul>
                        <li>Phone</li>
                        <li>Card ID</li>
                        <ul>
                          <li>If you use a custom identifer</li>
                        </ul>
                        <li>Birthday</li>
                        <ul>
                          <li>Format: YYYY/MM/DD</li>
                        </ul>
                        <li>Gender</li>
                        <ul>
                          <li>male/female/other (case-sensitive)</li>
                        </ul>
                        <li>City</li>
                        <li>Address</li>
                        <li>Postal Code</li>
                        <li>Notes</li>
                      </ul>
                    </div>
                  </div>
                </>
              }
            />

            <br />

            {!data && (
              <CSVReader
                cssClass='csv-input'
                onFileLoaded={(data: any) => {
                  this.onFileLoad(data)
                }}
                onError={(error: any) => {
                  console.error('error')
                }}
                inputId='csv-id'
              />
            )}

            {data && this.state.isImporting && (
              <div>
                <Progress
                  percent={Math.round(
                    ((importedRows + skippedRows.length) / mappedCustomers.length) * 100,
                  )}
                />
                <div className='text-success'>Imported customers: {importedRows}</div>
                <div className='text-danger'>Skipped/errored customers: {skippedRows.length}</div>
                <div>Total customers: {mappedCustomers.length}</div>
                <br />
                <div>Skipped/errored customers log:</div>
                <div className='bg-light' style={{ maxHeight: 250, overflowY: 'scroll' }}>
                  <pre>{JSON.stringify(this.state.skippedRows, null, 2)}</pre>
                </div>
              </div>
            )}

            {data && !this.state.isImporting && (
              <div>
                <b>Number of customers:</b>
                <div>{mappedCustomers.length}</div>
                <br />

                {nameWarnings}

                <div className='row'>
                  <div className='col-md-6'>
                    {headerMapping}
                    <br />
                    <div>
                      <button className='btn btn-success' onClick={() => this.submit()}>
                        Import customers
                      </button>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div>Preview (first 10 results)</div>

                    <div className='bg-light' style={{ maxHeight: 800, overflowY: 'scroll' }}>
                      <pre>{JSON.stringify(mappedCustomers.slice(0, 10), null, 2)}</pre>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <br />
          </div>
        </AppMainView>
      </AppView>
    )
  }
}
