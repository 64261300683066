import React, { Component, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import { Button, Icon, Select, Radio } from 'antd'
import { IPagination } from '@getgreenline/homi-shared'

interface Prop<T> {
  onClick: (offset: number, limit: number) => void
  showFirstPage?: boolean
  data: T[]
  pagination?: IPagination
  paginationPosition?: TPaginationPosition
  perPageOptions?: number[]
  hidePerPageOptions?: boolean
  numberOfRows?: number
}

interface State {
  limit: number
  offset: number
}

enum PAGINATION_ACTION {
  RESET = 'reset',
  PREV = 'previousPage',
  NEXT = 'nextPage',
  REFRESH = 'refresh',
}

export const DEFAULT_PER_PAGE_OPTIONS = [20, 30, 50, 100]

export const DEFAULT_PAGINATION_OFFSET = 0

type TPaginationPosition = 'top' | 'bottom' | 'both'

type CustomTablePaginationProps<T> = PropsWithChildren<Prop<T>>

@observer
export class CustomTablePagination<T> extends Component<CustomTablePaginationProps<T>, State> {
  state = {
    limit: this.pageOptions[0],
    offset: DEFAULT_PAGINATION_OFFSET,
  }

  componentDidUpdate() {
    const { pagination } = this.props
    // This allows a way for the parent component to force reset the internal pagination state.
    // An example is when a new paginated record is deleted.

    if (
      pagination &&
      (pagination.limit !== this.state.limit || pagination.offset !== this.state.offset)
    ) {
      this.setState({
        limit: pagination.limit,
        offset: pagination.offset,
      })
    }
  }

  handlePaginationClick = (paginationAction: PAGINATION_ACTION) => {
    const { limit, offset } = this.state
    let newOffset = offset

    switch (paginationAction) {
      case PAGINATION_ACTION.REFRESH:
        newOffset = offset
        break
      case PAGINATION_ACTION.RESET:
        newOffset = 0
        break
      case PAGINATION_ACTION.PREV:
        newOffset -= limit
        break
      case PAGINATION_ACTION.NEXT:
        newOffset += limit
        break
    }

    newOffset = Math.max(0, newOffset)

    this.setState({
      offset: newOffset,
    })

    this.props.onClick(newOffset, limit)
  }

  onLimitChanged(limit: number) {
    this.setState(
      {
        limit,
      },
      () => this.props.onClick(this.state.offset, limit),
    )
  }

  get showFirstPage() {
    return this.props.showFirstPage && this.state.offset !== 0
  }

  get isLastPage() {
    const { data, numberOfRows } = this.props
    const { limit } = this.state

    if (numberOfRows) {
      const lastPageNumber = Math.ceil(numberOfRows / limit)
      if (lastPageNumber === this.currentPage) {
        return true
      }
    }
    return data.length === 0 || data.length < limit
  }

  get currentPage() {
    const { offset, limit } = this.state

    return Math.ceil(offset / limit) + 1
  }

  renderPagination(isBottom: boolean) {
    const cssMargin = this.renderTopPagination ? { marginBottom: 16 } : { marginTop: 16 }

    return (
      <div
        className={`d-flex justify-content-end ${isBottom ? 'intercom-pb-1' : ''}`}
        style={cssMargin}
      >
        <Button.Group>
          {this.showFirstPage && (
            <Button onClick={() => this.handlePaginationClick(PAGINATION_ACTION.RESET)}>
              <Icon type='double-left' />
            </Button>
          )}

          <Button
            disabled={this.state.offset === 0}
            onClick={() => this.handlePaginationClick(PAGINATION_ACTION.PREV)}
          >
            <Icon type='left' />
          </Button>

          <Button>{this.currentPage}</Button>

          <Button
            disabled={this.isLastPage}
            onClick={() => this.handlePaginationClick(PAGINATION_ACTION.NEXT)}
          >
            <Icon type='right' />
          </Button>
        </Button.Group>
        {this.props.hidePerPageOptions ? null : (
          <Select<number>
            className='gr-filter-item gr-filter-gutter ml-2'
            value={this.state.limit}
            optionLabelProp='label'
            onChange={(value) => this.onLimitChanged(value)}
            style={{ minWidth: '145px', width: 'fit-content' }}
            dropdownMatchSelectWidth={false}
          >
            {this.pageOptions.map((option) => (
              <Select.Option key={option} value={option} label={`${option} per page`}>
                <Radio checked={this.state.limit === option}>{option} per page</Radio>
              </Select.Option>
            ))}
          </Select>
        )}
      </div>
    )
  }

  get pageOptions() {
    return this.props.perPageOptions || DEFAULT_PER_PAGE_OPTIONS
  }

  // Default paginationPosition is top
  get renderTopPagination() {
    return this.props.paginationPosition !== 'bottom'
  }

  get renderBottomPagination() {
    return this.props.paginationPosition && this.props.paginationPosition !== 'top'
  }

  render() {
    return (
      <>
        {this.renderTopPagination && this.renderPagination(false)}
        {this.props.children}
        {this.renderBottomPagination && this.renderPagination(true)}
      </>
    )
  }
}
