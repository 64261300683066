import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { ServerStore } from './stores/ServerStore'

interface StoreProps extends React.PropsWithChildren<unknown> {
  serverStore?: ServerStore
}

@inject('serverStore')
@observer
export class App extends React.Component<StoreProps, {}> {
  componentDidMount() {
    this.props.serverStore!.connectToServer()
  }

  render() {
    return <div>{this.props.children}</div>
  }
}
