import { IReactionDisposer, observable, action, ObservableMap } from 'mobx'
import { Subscription } from 'rxjs'
import { appsyncApi } from '../../../appsync/graphql/api'
import { AppSyncEventService } from './AppSyncEventService'
import {
  IAppSyncCallbacks,
  ISubscriptionCallbacks,
} from '../../../appsync/graphql/interfaces/subscription'
import { IEventService } from './interfaces'
import { BlazepayAPI, BlazepayConfigModels, BlazepayEnums } from '@getgreenline/payments'
import { captureError } from '../../../utilities/logging'
import { parseErrorMsg } from '../../../utilities/helpers'

export class ConfigEventService extends AppSyncEventService implements IEventService {
  subscription?: Subscription
  private disposer?: IReactionDisposer

  @observable configs =
    observable.map<BlazepayEnums.Provider, BlazepayConfigModels.IConfigurationEventOrRestData>()

  constructor(private merchantId: string, private syncPaymentOptions: VoidFunction) {
    super()
  }

  private appSyncCallback(): IAppSyncCallbacks<BlazepayConfigModels.IConfigSubscriptionResponseContract> {
    return {
      next: ({ data }) => {
        const eventConfig = data.updatedPaymentConfiguration.data
        this.mapResources(this.configs, eventConfig)
        this.syncPaymentOptions()
      },
      error: (error) => {
        captureError(parseErrorMsg(error) || 'payment_config_event.error', error, false)
      },
    }
  }

  subscribe<T>(props?: ISubscriptionCallbacks<T>) {
    this.reactToAppSyncConnectionChange()
    this.subscription = appsyncApi
      .subscribeFor(this.teardown)
      .configEvents(this.merchantId, this.appSyncCallback())

    return props?.after()
  }

  private teardown = () => {
    this.disposer?.()
  }

  private reactToAppSyncConnectionChange() {
    this.disposer = super.setDisposer(this.disposer, async (connectionState) => {
      await super.fetchRecentData(connectionState, this.keepDataUpToDate)
    })
  }

  private keepDataUpToDate = async () => {
    const configRes = await BlazepayAPI.getConfigs({ merchantId: this.merchantId })
    this.mapResources(this.configs, configRes.data)
    this.syncPaymentOptions()
  }

  @action
  private mapResources<T extends BlazepayConfigModels.IConfigurationEventOrRestData>(
    map: ObservableMap<BlazepayEnums.Provider, T>,
    data: T | T[],
  ) {
    const deserializedData = super.deserializeData<T>(data)

    if (Array.isArray(deserializedData)) {
      deserializedData.forEach((item) => this.mapSingleSource(map, item))
    } else {
      this.mapSingleSource(map, deserializedData)
    }
  }

  private mapSingleSource<T extends BlazepayConfigModels.IConfigurationEventOrRestData>(
    map: ObservableMap<BlazepayEnums.Provider, T>,
    item: T,
  ) {
    map.set(item.attributes.provider, item)
  }
}
