import * as React from 'react'
import { Upload, Icon } from 'antd'

import { UploadFile } from 'antd/lib/upload/interface'

interface Props {
  onDrop: (file: UploadFile) => void
}

export function ProvinceProductsUploadDropzone(props: Props) {
  return (
    <Upload.Dragger name='file' multiple={false} onChange={(info) => props.onDrop(info.file)}>
      <p className='ant-upload-drag-icon'>
        <Icon type='inbox' />
      </p>

      <div className='ant-upload-text'>
        <div>Click or drag file to upload</div>
      </div>
    </Upload.Dragger>
  )
}
