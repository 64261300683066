import React, { PropsWithChildren } from 'react'
import { Layout } from 'antd-v4'
import { AdminMenu } from './AdminMenu'

const { Header, Content } = Layout

export const AdminV2Layout = (props: PropsWithChildren<{}>) => {
  return (
    <Layout style={{ height: '100vh', overflow: 'auto' }}>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <AdminMenu />
      </Header>
      <Content className='site-layout' style={{ marginTop: 64 }}>
        {props.children}
      </Content>
    </Layout>
  )
}
