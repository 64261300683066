import { ICompany } from '@getgreenline/homi-shared'
import { Alert, Button, Form, Input, Row, Select, Spin } from 'antd-v4'
import { AlertProps } from 'antd-v4/lib/alert'
import { inject } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { CurrentUserStore } from '../../../../stores/CurrentUserStore'
import { LocationApi, LocationModels } from '@getgreenline/locations'
import { BlazeSelectFilter } from '../../../../components/CustomSelect/BlazeSelectFilter'
import { Moment } from 'moment'
import { parseErrorMsg } from '../../../../utilities/helpers'
import { DatePicker } from 'antd'
import { InventoryLogAPI, InventoryLogModels } from '@getgreenline/inventory'

interface IInventoryLogRollbackFormValues {
  locationId: number
  auditId: string
  newCreateDate: Moment
  companyId: number
}

interface IInjectedProps {
  currentUserStore?: CurrentUserStore
}

interface IProps extends IInjectedProps {
  company: ICompany
}

const _AuditRollbackWidget: FC<IProps> = ({ company, currentUserStore }: IProps) => {
  const [form] = Form.useForm()
  const [locations, setLocations] = useState<LocationModels.ILocationContract[]>()
  const [feedbackAlert, setFeedbackAlert] = useState<AlertProps | null>(null)
  const [isWaitingInitialFetch, setIsWaitingInitialFetch] = useState(true)
  const [isWaitingResponse, setIsWaitingResponse] = useState(false)

  useEffect(() => {
    const execute = async () => {
      try {
        const locations = await LocationApi.getLocations(company.id, false)
        setLocations(locations)
      } catch (error) {
        setFeedbackAlert({
          type: 'error',
          message: 'There was a problem getting locations for this company.',
        })
      }
      setIsWaitingInitialFetch(false)
    }
    execute()
  }, [company])

  const rollbackAudit = async (formValues: IInventoryLogRollbackFormValues) => {
    if (!company.id || !currentUserStore?.currentUser?.id) return
    setIsWaitingResponse(true)
    try {
      await InventoryLogAPI.rollbackInventoryLogs({
        ...formValues,
        newCreateDate: formValues.newCreateDate.toDate(),
        logType: InventoryLogModels.InventoryLogType.AUDIT,
        logTypeId: formValues.auditId,
      })
      setFeedbackAlert({
        type: 'success',
        message: 'Audit successfully rolled back',
      })
    } catch (error) {
      setFeedbackAlert({
        type: 'error',
        message: parseErrorMsg(error) || 'There was an issue rolling back this audit',
      })
    }
    setIsWaitingResponse(false)
  }

  return (
    <div className='background-shadow p-4'>
      <h5 className='text-muted'>Audit Rollback</h5>
      {feedbackAlert ? (
        <Alert
          {...feedbackAlert}
          style={{ margin: '16px 0 24px 0' }}
          showIcon
          closable
          onClose={() => setFeedbackAlert(null)}
        />
      ) : null}
      {isWaitingInitialFetch ? (
        <Spin />
      ) : (
        <Form id='audit-rollback' form={form} onFinish={rollbackAudit}>
          <Row style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Item
              name='locationId'
              label='Audit location'
              style={{ flex: 1 }}
              rules={[{ required: true, message: 'Please select which location the audit is at' }]}
            >
              <BlazeSelectFilter<number>
                className='gr-filter-item gr-filter-gutter'
                allowClear
                showSearch
                showArrow
                placeholder='Location'
                dropdownMatchSelectWidth={false}
              >
                {locations?.map((source, index) => (
                  <Select.Option key={index} value={source.id}>
                    {source.name}
                  </Select.Option>
                ))}
              </BlazeSelectFilter>
            </Form.Item>
            <Form.Item name='companyId' initialValue={company.id} style={{ display: 'none' }} />
          </Row>
          <Row style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Item
              name='auditId'
              label='Audit id'
              style={{ flex: 1, width: '50%', marginRight: '16px' }}
              rules={[
                {
                  required: true,
                  message: 'Please add the id for the audit you wish to roll back',
                },
              ]}
            >
              <Input type='string' />
            </Form.Item>
            <Form.Item
              name='newCreateDate'
              label='Date to roll back to'
              style={{ flex: 1, width: '50%', marginLeft: '16px' }}
              rules={[{ required: true, message: 'Please select a date to roll back to' }]}
            >
              <DatePicker
                className='mb-2'
                style={{ width: '100%', color: 'white' }}
                showTime={true}
              />
            </Form.Item>
          </Row>
          <Form.Item>
            <Button type='primary' htmlType='submit' loading={isWaitingResponse}>
              Rollback audit
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  )
}

export const AuditRollbackWidget = inject('currentUserStore')(_AuditRollbackWidget)
