import { ProvinceProductModels } from '@getgreenline/products'
import { IProvinceProductExport } from '../constants/ProvinceProducts'

export const createABExportProducts = (
  provinceProducts: ProvinceProductModels.IProvinceProductContract[],
): IProvinceProductExport[] => {
  const formattedProvinceProducts = provinceProducts.map((product) => {
    return {
      SKU: product.sku,
      Name: product.name,
      'Image URL': product.imageUrl,
      Description: product.description,
      Category: product.category,
      Format: product.format,
      Vendor: product.supplierName,
      Barcode: product.barcode,
      'Quantity Per Case': product.quantityPerCase,
      'Net Product Weight': product.weight,
      'Cannabis Weight': product.cannabisWeight,
      'Cannabis Volume': product.volume,
      'Show Min Max THC CBD': true,
      THC: product.thc,
      CBD: product.cbd,
      'Min THC': product.minThc,
      'Max THC': product.maxThc,
      'Min CBD': product.minCbd,
      'Max CBD': product.maxCbd,
      Unit: product.thcCbdUnit,
      'Plant Type': product.plantType,
    }
  })
  return formattedProvinceProducts
}

export const createBCExportProducts = (
  provinceProducts: ProvinceProductModels.IProvinceProductContract[],
): IProvinceProductExport[] => {
  const formattedProvinceProducts = provinceProducts.map((product) => {
    return {
      SKU: product.sku,
      Name: product.name,
      'Image URL': product.imageUrl,
      Description: product.description,
      Category: product.category,
      Format: product.format,
      Type: product.type,
      Vendor: product.supplierName,
      Barcode: product.barcode,
      'Quantity Per Case': product.quantityPerCase,
      'Net Product Weight': product.weight,
      'Cannabis Weight': product.cannabisWeight,
      'Cannabis Volume': product.volume,
      'Show Min Max THC CBD': true,
      THC: product.thc,
      CBD: product.cbd,
      'Min THC': product.minThc,
      'Max THC': product.maxThc,
      'Min CBD': product.minCbd,
      'Max CBD': product.maxCbd,
      Unit: product.thcCbdUnit,
      'Plant Type': product.plantType,
    }
  })
  return formattedProvinceProducts
}

export const createONAndOtherProvinceExportProducts = (
  provinceProducts: ProvinceProductModels.IProvinceProductContract[],
): IProvinceProductExport[] => {
  const formattedProvinceProducts = provinceProducts.map((product) => {
    return {
      SKU: product.sku,
      Name: product.name,
      'Image URL': product.imageUrl,
      Description: product.description,
      Category: product.category,
      Format: product.format,
      Type: product.type,
      Vendor: product.brand,
      Barcode: product.barcode,
      'Quantity Per Case': product.quantityPerCase,
      'Net Product Weight': product.weight,
      'Cannabis Weight': product.cannabisWeight,
      'Cannabis Volume': product.volume,
      'Show Min Max THC CBD': true,
      THC: product.thc,
      CBD: product.cbd,
      'Min THC': product.minThc,
      'Max THC': product.maxThc,
      'Min CBD': product.minCbd,
      'Max CBD': product.maxCbd,
      Unit: product.thcCbdUnit,
      'Plant Type': product.plantType,
    }
  })
  return formattedProvinceProducts
}
