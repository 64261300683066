import React, { Component, Dispatch, PropsWithChildren, SetStateAction } from 'react'
import { observer } from 'mobx-react'
import { Button, Icon, message, Radio, Select } from 'antd'
import { ICursorPagination } from '@getgreenline/shared'
import { isNil } from 'lodash'
import { parseErrorMsg } from '../utilities/helpers'
import { updatePaginationKeysBasedOnPageMovement } from '../utilities/dynamoDbPaginationHelpers'

interface Prop<T> {
  limit: number
  setLimit?: Dispatch<SetStateAction<number>>
  changePage: (cursor: string | null, lastEvaluatedKeys?: string[]) => void
  data: T[]
  setPaginationRows?: () => Promise<void>
  pagination: ICursorPagination | Record<string, never>
  paginationPosition?: TPaginationPosition
  paginationReset?: boolean
  setPaginationFirstPage?: Dispatch<SetStateAction<boolean>> | ((boolean: boolean) => void)
  hideRowOptions?: boolean
  lastEvaluatedKeys?: string[]
}

interface State {
  pageNumber: number
  isButtonDisabled: boolean
}

export enum TPaginationPosition {
  TOP = 'top',
  TOP_RIGHT = 'topRight',
  BOTTOM = 'bottom',
  BOTH = 'both',
}

type CustomTablePaginationProps<T> = PropsWithChildren<Prop<T>>

@observer
export class CustomTablePaginationV2<T> extends Component<CustomTablePaginationProps<T>, State> {
  constructor(props: Prop<T>) {
    super(props)

    this.state = {
      pageNumber: 1,
      isButtonDisabled: false,
    }
  }

  componentDidUpdate(prevProps: Prop<T>) {
    const { paginationReset, setPaginationFirstPage, limit } = this.props
    if (paginationReset) {
      this.setState({ pageNumber: 1 })
      setPaginationFirstPage && setPaginationFirstPage(false)
    }
    if (prevProps.limit !== limit) {
      if (this.props.setPaginationRows) {
        this.setState({ pageNumber: 1 })
        this.props.setPaginationRows()
      }
    }
  }

  async changePage(cursor: string | null, pageMovement: number) {
    try {
      this.setState({ pageNumber: this.state.pageNumber + pageMovement, isButtonDisabled: true })

      const updatedLastEvaluatedKeys = updatePaginationKeysBasedOnPageMovement(
        this.props.lastEvaluatedKeys,
        pageMovement,
        this.props.pagination.nextCursor,
      )
      await this.props.changePage(cursor, updatedLastEvaluatedKeys)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred when changing pages')
    } finally {
      this.setState({ isButtonDisabled: false })
    }
  }

  renderPagination(isBottom: boolean) {
    const cssMargin = this.renderTopPagination ? { marginBottom: 16 } : { marginTop: 16 }
    const { limit, setLimit, pagination, data, paginationPosition } = this.props
    const { nextCursor, prevCursor } = pagination

    let className = 'd-flex align-items-center'

    if (isBottom) {
      className = `${className} intercom-pb-1`
    }

    if (paginationPosition?.toLowerCase().includes('right')) {
      className = `${className} justify-content-end`
    } else {
      className = `${className} justify-content-center`
    }

    return (
      <div className={className} style={{ ...cssMargin }}>
        {this.props.hideRowOptions ? null : (
          <div>
            {data.length !== limit && (
              <span className='mr-2 font-weight-bold'>Showing {data.length}</span>
            )}
            <Select<number>
              className='gr-filter-item mr-2'
              value={limit}
              optionLabelProp='label'
              onChange={(value) => {
                if (setLimit) {
                  setLimit(value)
                }
              }}
            >
              <Select.Option key={20} value={20} label={'20 per page'}>
                <Radio checked={limit === 20}>20 per page</Radio>
              </Select.Option>
              <Select.Option key={30} value={30} label={'30 per page'}>
                <Radio checked={limit === 30}>30 per page</Radio>
              </Select.Option>
              <Select.Option key={40} value={40} label={'40 per page'}>
                <Radio checked={limit === 40}>40 per page</Radio>
              </Select.Option>
              <Select.Option key={50} value={50} label={'50 per page'}>
                <Radio checked={limit === 50}>50 per page</Radio>
              </Select.Option>
            </Select>
          </div>
        )}
        <Button.Group>
          <Button
            disabled={
              (isNil(prevCursor) && this.state.pageNumber === 1) || this.state.isButtonDisabled
            }
            onClick={async () => await this.changePage(prevCursor, -1)}
          >
            <Icon type='left' />
          </Button>
          <Button disabled>
            <span>{this.state.pageNumber}</span>
          </Button>
          <Button
            disabled={isNil(nextCursor) || this.state.isButtonDisabled}
            onClick={async () => await this.changePage(nextCursor, 1)}
          >
            <Icon type='right' />
          </Button>
        </Button.Group>
      </div>
    )
  }

  // Default paginationPosition is top
  get renderTopPagination() {
    const { paginationPosition } = this.props
    return paginationPosition && !paginationPosition.toLowerCase().includes('bottom')
  }

  get renderBottomPagination() {
    const { paginationPosition } = this.props
    return paginationPosition && !paginationPosition.toLowerCase().includes('top')
  }

  render() {
    return (
      <>
        {this.renderTopPagination && this.renderPagination(false)}
        {this.props.children}
        {this.renderBottomPagination && this.renderPagination(true)}
      </>
    )
  }
}
