import * as React from 'react'
import { useEffect, useState } from 'react'
import { browserHistory } from 'react-router'
import Title from 'antd/lib/typography/Title'
import { parseErrorMsg } from '../../../utilities/helpers'
import { OrganizationsAPI, OrganizationsModels } from '@getgreenline/organizations'
import { OrganizationForm } from './OrganizationForm'
import { OrganizationCard } from './OrganizationCard'
import {
  CustomTablePagination,
  DEFAULT_PAGINATION_OFFSET,
  DEFAULT_PER_PAGE_OPTIONS,
} from '../../../components/CustomTablePagination'
import { Col, Row, Card, Empty, PageHeader, message, Drawer } from 'antd-v4'

export const AdminOrganizations = () => {
  useEffect(() => {
    ;(async () => {
      await getOrganizations()
    })()
  }, [])

  const [organizations, setOrganizations] = useState<OrganizationsModels.IOrganization[]>([])
  const [editOrganization, setEditOrganization] =
    useState<OrganizationsModels.IOrganization | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const getOrganizations = async (
    offset: number = DEFAULT_PAGINATION_OFFSET,
    limit: number = DEFAULT_PER_PAGE_OPTIONS[0],
  ) => {
    setIsLoading(true)

    try {
      const orgResults = await OrganizationsAPI.get({
        offset,
        limit,
      })
      setOrganizations(orgResults.data)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while loading organizations')
    } finally {
      setIsLoading(false)
    }
  }

  const createOrganization = async (organization: OrganizationsModels.ICreateOrganization) => {
    try {
      const newOrganization = await OrganizationsAPI.create(organization)
      message.success(`${newOrganization.name} was successfully created`)

      await getOrganizations()
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while creating an external source')
    }
  }

  const handlePaginationClick = (offset: number, limit: number) => {
    getOrganizations(offset, limit)
  }

  const updateOrganization = async (organization: OrganizationsModels.IOrganization) => {
    try {
      const newOrganization = await OrganizationsAPI.update(organization.id, organization)
      setEditOrganization(null)
      message.success(`${newOrganization.name} was successfully updated`)

      await getOrganizations()
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while creating an external source')
    }
  }

  return (
    <div className='p-4'>
      <PageHeader
        style={{
          border: '1px solid rgb(235, 237, 240)',
        }}
        className='mb-4'
        onBack={() => browserHistory.goBack()}
        title='Organizations'
        subTitle='Manage Organizations'
      />
      <Title>Create Organization</Title>
      <OrganizationForm<OrganizationsModels.ICreateOrganization>
        shouldResetFields={true}
        onSubmit={createOrganization}
      />

      <Title>All Organizations</Title>
      {isLoading ? (
        <Card loading={isLoading}></Card>
      ) : organizations.length === 0 ? (
        <Empty />
      ) : (
        <CustomTablePagination
          onClick={handlePaginationClick}
          data={organizations}
          showFirstPage
          paginationPosition={'both'}
        >
          <Row gutter={16}>
            {organizations.map((organization) => (
              <Col span={4} key={organization.id}>
                <OrganizationCard
                  organization={organization}
                  selectOrganization={setEditOrganization}
                />
              </Col>
            ))}
          </Row>
        </CustomTablePagination>
      )}

      {editOrganization && (
        <Drawer
          title={`Edit ${editOrganization.name}`}
          maskClosable
          placement='right'
          closable={false}
          width={350}
          onClose={() => {
            setEditOrganization(null)
          }}
          visible={!!editOrganization}
        >
          <OrganizationForm<OrganizationsModels.IOrganization>
            existingOrganization={editOrganization}
            shouldResetFields={false}
            onSubmit={updateOrganization}
          />
        </Drawer>
      )}
    </div>
  )
}
