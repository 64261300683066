import * as React from 'react'
import classnames from 'classnames'
import './LoadingAnimation.scss'

export interface Props {
  noPadding?: boolean
}

export class LoadingAnimation extends React.Component<React.PropsWithChildren<Props>, {}> {
  render() {
    const { noPadding = false } = this.props

    return (
      <div
        className={classnames('text-center', { 'no-padding': noPadding })}
        id='loading-animation'
      >
        <img src={require('../styles/svg-loaders/tail-spin-retail-blue.svg').default} />
        <div style={{ minWidth: '100%' }}>{this.props.children}</div>
      </div>
    )
  }
}
