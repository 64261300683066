import React from 'react'
import { Col, Icon } from 'antd'
import { Card, Button } from 'antd-v4'
import { OrganizationsModels } from '@getgreenline/organizations'

interface Props {
  organization: OrganizationsModels.IOrganization
  selectOrganization: (organization: OrganizationsModels.IOrganization) => void
}

export const OrganizationCard = ({ organization, selectOrganization }: Props) => {
  return (
    <Card
      key={organization.id}
      title={organization.name}
      className='mb-3'
      extra={[
        <Button key={`${organization.id}-select`} onClick={() => selectOrganization(organization)}>
          <Icon type='edit' style={{ color: 'rgba(0,0,0,.25)' }} />
        </Button>,
      ]}
    >
      Id: {organization.id}
    </Card>
  )
}
