import * as React from 'react'

interface Props {
  id?: string
  employee: {
    id: string
    name: string
  }
  status: 'success' | 'danger'
  onClick?: () => void
}

export class EmployeeBadge extends React.Component<Props, {}> {
  render() {
    const employee = this.props.employee

    return (
      <div className='mr-2 mb-2 p-4 bg-light' style={{ width: 150, borderRadius: 5 }}>
        <div>{employee.name}</div>
        {this.props.status === 'success' ? (
          <div className='badge badge-pill badge-success'>Tracking hours</div>
        ) : (
          <div className='badge badge-pill badge-danger'>Not tracking</div>
        )}
      </div>
    )
  }
}
