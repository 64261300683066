import { inject, observer } from 'mobx-react'
import * as React from 'react'
import { CurrentCompanyStore } from '../stores/CurrentCompanyStore'
import { GrPrice } from '../utilities/helpers'

interface Props {
  centValue?: number | null
  onChange: (newValue: number) => void
  id?: string
  className?: string
  style?: React.CSSProperties
  name?: string
  disabled?: boolean
  onlyPositiveNumber?: boolean
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  maxValue?: number
  autoFocus?: boolean
  onPressEnter?: () => void
  usePoints?: boolean
}

interface InjectedProps {
  currentCompanyStore?: CurrentCompanyStore
}

interface State {
  wholeCentValue?: number | null
}

@inject('currentCompanyStore')
@observer
export class CurrencyInput extends React.Component<Props & InjectedProps, State> {
  constructor(props: Props & InjectedProps) {
    super(props)
    this.state = {
      wholeCentValue: this.props.centValue,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const { centValue: wholeCentValue } = nextProps
    if (wholeCentValue !== this.state.wholeCentValue) {
      this.setState({ wholeCentValue })
    }
  }

  parseCurrencyString = (newValue: string): number => {
    // Return a positive number
    const numberString = newValue.replace(/[^0-9]/g, '')
    return parseInt(numberString)
  }

  isPositiveNumber = (str: string): boolean => {
    const arrayMinusSigns = str.match(/-/g)
    const count = arrayMinusSigns ? arrayMinusSigns.length : 0
    let isPositive = count % 2 ? false : true

    if (this.props.onlyPositiveNumber) {
      isPositive = true
    }

    return isPositive
  }

  handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawInput = e.target.value
    // Remove all signs other than numbers
    let wholeCentValue = this.parseCurrencyString(rawInput)

    if (this.props.maxValue) wholeCentValue = Math.min(wholeCentValue, this.props.maxValue)

    // If the value should be negative, multiple the whole cent value by -1
    wholeCentValue = this.isPositiveNumber(rawInput) ? wholeCentValue : wholeCentValue * -1

    this.setState({ wholeCentValue })
    this.props.onChange(wholeCentValue)
  }

  onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!this.props.onPressEnter) return

    const enterKeyCode = 13
    const isEnterPressed = event.keyCode === enterKeyCode || event.which === enterKeyCode

    isEnterPressed && this.props.onPressEnter()
  }

  getDisplayedValue = () => {
    if (this.props.usePoints) {
      return this.state.wholeCentValue ? this.state.wholeCentValue : 0
    }
    return GrPrice.renderDollarString(this.state.wholeCentValue || null)
  }

  render() {
    const { id, name, className, disabled, onKeyPress, style, onPressEnter } = this.props
    return (
      <input
        id={id}
        name={name}
        className={className}
        style={{
          minWidth: 100,
          ...style,
        }}
        type='tel'
        value={this.getDisplayedValue()}
        disabled={disabled}
        onKeyPress={(e) => {
          onKeyPress?.(e)

          this.onPressEnter(e)
        }}
        onChange={this.handleOnChange}
        autoFocus={this.props.autoFocus}
      />
    )
  }
}
