import * as React from 'react'
import { browserHistory, InjectedRouter, PlainRoute, RouteComponentProps } from 'react-router'
import { FormError } from '../components/FormError'
import { Routes } from '../constants/Routes'
import Logo from '../images/logo-256.png'
import { LocalStorage } from '../utilities/LocalStorage'
import { resetMigrationPassword, signOut } from '../utilities/auth'
import { IUserResponse, API, IUpdateUserEntityAssignment } from '@getgreenline/homi-shared'
import { message, Row, Col } from 'antd-v4'
import useBreakpoint from 'antd-v4/lib/grid/hooks/useBreakpoint'
import { COLORS } from '../constants/Colors'
import Paragraph from 'antd-v4/lib/typography/Paragraph'
import { CheckOrX } from '../components/CheckOrX'
import { CognitoIdToken } from 'amazon-cognito-identity-js'

interface Props extends RouteComponentProps<{}, {}> {
  router: InjectedRouter
  route: PlainRoute
}

interface State {
  isLoading: boolean
  isSubmitted?: boolean
  errorMessage?: string
  email: string
  oldPassword?: string
  password?: string
  confirmPassword?: string
}

export class ResetPasswordUserMigration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isLoading: false,
      email: '',
      isSubmitted: false,
      errorMessage: undefined,
      oldPassword: '',
      password: '',
      confirmPassword: '',
    }
  }

  parseErrorMessage = (errorMessage: string) => {
    switch (errorMessage) {
      case 'Request failed with status code 403':
        return 'Old password is incorrect'
      case 'Request failed with status code 400':
        return "Your password doesn't meet the requirements."
      default:
        return errorMessage
    }
  }

  async submit() {
    this.setState({ isLoading: true })
    try {
      const { email, password, oldPassword } = this.state
      const user = LocalStorage.getUserMigratingToCognito()
      if (!oldPassword) throw new Error('Old password is required')
      if (!password) throw new Error('Password is required')
      if (!email || !user) {
        throw new Error('Something went wrong, please go to the login page and try again')
      }
      await resetMigrationPassword({ email, oldPassword, newPassword: password })
      await this.setLegacyPassword(user, password)
      await LocalStorage.clearUserMigrationToCognito()
      this.setState({ isSubmitted: true })
      browserHistory.push(Routes.LOGIN_V2)
    } catch (error) {
      const { message } = error as Error
      const errorMessage = this.parseErrorMessage(message)
      this.setState({
        errorMessage:
          errorMessage ||
          'There was an error submitting your request. Please verify your information and try again.',
      })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  async componentDidMount() {
    const email = LocalStorage.getUnauthenticatedEmail() || ''
    this.setState({ email })

    this.props.router.setRouteLeaveHook(this.props.route, this.routerWillLeave)
  }

  routerWillLeave = async () => {
    if (!this.state.isSubmitted) {
      await window.localStorage.clear()
    }
  }

  setLegacyPassword = async (user: IUserResponse, password: string) => {
    const assignedEntityAssignment = user.entityAssignments.find(
      (assignment) => assignment.type === 'assigned',
    )
    const companyId = assignedEntityAssignment?.entity.rootEntityId
    if (!companyId) throw new Error('Please return the the login page and sign in again')

    await API.editCompanyUser(companyId, user.id, {
      id: user.id,
      password: password,
      entityAssignments: user.entityAssignments as IUpdateUserEntityAssignment[],
    })
  }

  hasMinEightCharacters = (text: string) => {
    return text.length >= 8
  }

  hasOneSpecialCharacter = (text: string) => {
    return new RegExp(/\W/).test(text)
  }

  hasOneNumber = (text: string) => {
    return new RegExp(/\d/).test(text)
  }

  hasOneLowercaseLetter = (text: string) => {
    return new RegExp(/[a-z]/).test(text)
  }

  hasOneUppercaseLetter = (text: string) => {
    return new RegExp(/[A-Z]/).test(text)
  }

  hasOneUpperAndLowercaseLetter = (text: string) => {
    return this.hasOneLowercaseLetter(text) && this.hasOneUppercaseLetter(text)
  }

  render() {
    let saveButton: JSX.Element
    if (this.state.isLoading) {
      saveButton = (
        <button className='btn btn-lg btn-success w-100' disabled>
          Submitting...
        </button>
      )
    } else if (this.state.password! !== this.state.confirmPassword!) {
      saveButton = (
        <button className='btn btn-lg btn-success w-100' disabled>
          Passwords must match
        </button>
      )
    } else {
      saveButton = (
        <button
          className='btn btn-lg btn-success w-100'
          onClick={(e) => {
            e.preventDefault()
            this.submit()
          }}
        >
          Submit
        </button>
      )
    }

    return (
      <div>
        <div className='container pt-5'>
          <div>
            <a href={Routes.LOGIN_V2}>
              <img className='mr-3' src={Logo} style={{ width: '30px', height: '30px' }} />
              <span>GetGreenline</span>
            </a>
          </div>

          <br />

          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Col span={12} className='my-4'>
              <h1 className='display-4'>Reset Password</h1>
              <br />
              <form>
                <Row>
                  <Col span={11} style={{ marginRight: 13 }}>
                    <div className='form-group'>
                      <label>Old password</label>
                      <input
                        type='password'
                        className='form-control'
                        value={this.state.oldPassword}
                        onChange={(e) => this.setState({ oldPassword: e.target.value })}
                      />
                    </div>
                    <div className='form-group'>
                      <label>New password</label>
                      <input
                        type='password'
                        className='form-control'
                        value={this.state.password}
                        onChange={(e) => this.setState({ password: e.target.value })}
                      />
                    </div>
                    <div className='form-group'>
                      <label>Confirm new password</label>
                      <input
                        type='password'
                        className='form-control'
                        value={this.state.confirmPassword}
                        onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                      />
                    </div>
                  </Col>
                  <Col
                    span={11}
                    style={{
                      border: `1px solid ${COLORS.borderGrey}`,
                      borderRadius: 4,
                      background: COLORS.grey100,
                      marginLeft: 13,
                      marginBottom: 10,
                      padding: 10,
                    }}
                  >
                    <Paragraph>Password requires:</Paragraph>
                    <Paragraph>
                      <CheckOrX
                        condition={this.hasMinEightCharacters}
                        test={this.state.password || ''}
                      />
                      <span className='ml-2'>minimum 8 characters</span>
                    </Paragraph>
                    <Paragraph>
                      <CheckOrX
                        condition={this.hasOneSpecialCharacter}
                        test={this.state.password || ''}
                      />
                      <span className='ml-2'>1 special character</span>
                    </Paragraph>
                    <Paragraph>
                      <CheckOrX condition={this.hasOneNumber} test={this.state.password || ''} />
                      <span className='ml-2'>1 number</span>
                    </Paragraph>
                    <Paragraph>
                      <CheckOrX
                        condition={this.hasOneUpperAndLowercaseLetter}
                        test={this.state.password || ''}
                      />
                      <span className='ml-2'>1 capital and lowercase letter</span>
                    </Paragraph>
                  </Col>
                  <br />
                  {saveButton}
                  <br />
                  <FormError message={this.state.errorMessage} />
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}
