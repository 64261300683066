import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons'
import React from 'react'
import { COLORS } from '../constants/Colors'

interface Props<T> {
  condition: (test: T) => boolean
  test: T
}

export const CheckOrX = <T extends unknown>({ condition, test }: Props<T>) =>
  condition(test) ? (
    <CheckCircleTwoTone twoToneColor={COLORS.primaryGreen} />
  ) : (
    <CloseCircleTwoTone twoToneColor={COLORS.red600} />
  )
