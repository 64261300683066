import { EventsV2 } from '@getgreenline/events'
import { RouteKeys, IWebSocketQueryParams, WebSocketService } from '@getgreenline/websocket'
import { action, observable } from 'mobx'
import { Track } from '../Track'

interface IWebSocketStoreProps extends IWebSocketQueryParams {
  canUseWebSocket: boolean
  deviceId: string
}

export class WebSocketStore {
  @observable webSocket: WebSocketService | undefined

  constructor(params: IWebSocketStoreProps) {
    this.initWebSocket(params)
  }

  @action
  initWebSocket = ({
    deviceId,
    tenantId,
    retailerId,
    authorization,
    canUseWebSocket,
  }: IWebSocketStoreProps) => {
    const baseUrl = process.env.REACT_APP_WEBSOCKET_URL
    if (!baseUrl || !canUseWebSocket) return

    const webSocket = new WebSocketService({
      baseUrl,
      queryParams: {
        tenantId,
        retailerId,
        authorization,
      },
      options: {
        WebSocket: WebSocket,
        pingTimeout: 5 * 60 * 1000, // 5 minutes
        connectionTimeout: 15000,
        reconnectionDelayGrowFactor: 1.2,
        maxReconnectionDelay: 7000,
        minReconnectionDelay: 4000,
      },
      logger: Track.track,
      errorTracking: Track.track,
    })

    webSocket.addSubscriptionOnOpen(EventsV2.EventModels.EventTopics.WP_TRANSACTION, () => {
      webSocket.sendMessage({
        action: RouteKeys.SUBSCRIBE,
        topic: EventsV2.EventModels.EventTopics.WP_TRANSACTION,
        data: {
          tenantId: tenantId.toString(),
          retailerId: retailerId.toString(),
          deviceId,
        },
      })
    })

    webSocket.onOpen((event) => {
      console.log('ws opened')
    })

    webSocket.onClose((event) => {
      console.log('ws closed')
    })

    webSocket.onError((error) => {
      console.log('ws error')
    })

    this.webSocket = webSocket
  }
}
