import React from 'react'
import { PageHeader, Button } from 'antd'
import { browserHistory } from 'react-router'

export function GlobalProductsHeader() {
  return (
    <PageHeader
      title='Global Catalog'
      onBack={() => browserHistory.push('admin/v2')}
      extra={[
        <Button
          key='import'
          type='primary'
          icon='import'
          onClick={() => browserHistory.push('/admin/v2/globalCatalog/import')}
        >
          Import
        </Button>,
      ]}
    />
  )
}
