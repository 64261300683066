import * as React from 'react'
import moment from 'moment'
import { Currency } from '../../../components/Currency'
import {
  paymentNameFormatter,
  PaymentTypeIds,
  ICompany,
  IShift,
  IEmployeeHour,
} from '@getgreenline/homi-shared'
import { GrPrice } from '../../../utilities/helpers'
import { chain, Dictionary, flatten, groupBy } from 'lodash'
import { ShiftPaymentLines } from './ShiftPaymentLines'

interface Props {
  company: ICompany
  shift: IShift
  showInventorySold: boolean
}

const getTotalEmployeeHours = (employeeHours: IEmployeeHour[]) => {
  const chainedEmployeeHour: Dictionary<IEmployeeHour[]>[] = chain(employeeHours)
    .groupBy('employeeId')
    .map((employee) => groupBy(employee, 'type'))
    .value()

  const aggregateEmployeeHours = chainedEmployeeHour.map((emp) => {
    const workTime = emp.work
    const breakTime = emp.break

    const hours = []

    if (workTime && workTime.length > 0) {
      const workTimeTotal = workTime.reduce(
        (acc, curr) => {
          const currentWorkMinutes = curr.totalMinutes
            ? curr.totalMinutes
            : moment(curr.endTime || undefined).diff(curr.startTime, 'minutes')

          return {
            id: curr.id,
            type: curr.type,
            employeeId: curr.employeeId,
            employeeName: curr.employeeName,
            totalMinutes: acc.totalMinutes + currentWorkMinutes,
          }
        },
        {
          id: 0,
          type: 'work',
          employeeId: '',
          employeeName: '',
          totalMinutes: 0,
        },
      )

      hours.push(workTimeTotal)
    }

    if (breakTime && breakTime.length > 0) {
      const breakTimeTotal = breakTime.reduce(
        (acc, curr) => {
          const currentBreakTime = curr.totalMinutes
            ? curr.totalMinutes
            : moment(curr.endTime || undefined).diff(curr.startTime, 'minutes')

          return {
            id: curr.id,
            type: curr.type,
            employeeId: curr.employeeId,
            employeeName: curr.employeeName,
            totalMinutes: acc.totalMinutes + currentBreakTime,
          }
        },
        {
          id: 0,
          type: 'break',
          employeeId: '',
          employeeName: '',
          totalMinutes: 0,
        },
      )

      hours.push(breakTimeTotal)
    }

    return hours
  })

  return flatten(aggregateEmployeeHours)
}

export const ShiftReceipt = (props: Props) => {
  const { company, shift, showInventorySold } = props
  const cashCount = shift.cashCount

  const getCashCountTotal = () => {
    if (cashCount === null) {
      return 0
    }

    let total = 0

    Object.keys(cashCount).forEach((deno: string) => {
      const denominator = parseInt(deno, 10)
      const count = cashCount && cashCount[deno]

      if (count !== null) {
        total += denominator * count
      }
    })

    return total
  }

  const discrepancy = getCashCountTotal() - shift.endCash

  const totalEmployeeHours = getTotalEmployeeHours(shift.employeeHours)

  return (
    <>
      <iframe
        id='printing-frame'
        name='print_frame'
        src='about:blank'
        style={{ display: 'none' }}
      ></iframe>
      <div
        id='print-section'
        style={{ overflowY: 'hidden', paddingLeft: '10px', paddingRight: '10px' }}
      >
        <table
          style={{
            width: '100%',
            border: 'none',
            borderWidth: 0,
            borderCollapse: 'collapse',
            padding: '10px',
          }}
        >
          <tbody>
            <tr>
              <td colSpan={2}>
                <b style={{ fontSize: '1.5em', fontWeight: 600 }}>{`${company.name}`}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b style={{ marginRight: 5 }}>{shift.deviceName}</b>
                <b>{`(Shift ID: ${shift.id})`}</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div>
                  <br />
                </div>
              </td>
            </tr>
            <tr>
              <td>Shift start date</td>
              <td style={{ textAlign: 'right' }}>{moment(shift.createDate).format('LLL')}</td>
            </tr>
            <tr>
              <td>Print date</td>
              <td style={{ textAlign: 'right' }}>{moment().format('LLL')}</td>
            </tr>
            {shift.endDate ? (
              <tr>
                <td>Shift end date</td>
                <td style={{ textAlign: 'right' }}>{moment(shift.endDate).format('LLL')}</td>
              </tr>
            ) : undefined}
            <tr>
              <td>Shift starting float</td>
              <td style={{ textAlign: 'right' }}>
                <Currency centValue={shift.startCash} />
              </td>
            </tr>
            <tr>
              <td>Shift ending float</td>
              <td style={{ textAlign: 'right' }}>
                <Currency centValue={shift.endCash} />
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <div>
                  <hr />
                </div>
              </td>
            </tr>

            {cashCount === null ? (
              <tr>
                <td colSpan={2}>
                  <b>Float count skipped</b>
                </td>
              </tr>
            ) : (
              <>
                <tr>
                  <td>Counted float</td>
                  <td style={{ textAlign: 'right' }}>
                    {GrPrice.convertCentToDollar(getCashCountTotal())}
                  </td>
                </tr>

                <tr>
                  <td>Discrepancy</td>
                  <td style={{ textAlign: 'right' }}>{GrPrice.convertCentToDollar(discrepancy)}</td>
                </tr>

                <tr>
                  <td>Float break down</td>
                </tr>

                {Object.keys(cashCount).map((deno, index) => {
                  const denominator = parseInt(deno, 10)
                  const count = cashCount[deno]
                  const amount = count && denominator * count

                  return (
                    <tr key={index}>
                      <td>{GrPrice.convertCentToDollar(denominator)}</td>

                      <td style={{ textAlign: 'right' }}>
                        <span className='mr-2'>{count ? `x${count}` : '-'}</span>
                        {amount && GrPrice.convertCentToDollar(amount)}
                      </td>
                    </tr>
                  )
                })}
              </>
            )}
            <tr>
              <td colSpan={2}>
                <div>
                  <hr />
                </div>
              </td>
            </tr>

            <ShiftPaymentLines shift={shift} />

            <tr>
              <td colSpan={2}>
                <div>
                  <hr />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div>Cash movements</div>
              </td>
            </tr>
            {shift.logs
              .filter((log) =>
                ['cash drop', 'pay in', 'pay out', 'refund', 'no sale'].includes(log.type),
              )
              .map((log) => {
                return (
                  <tr key={log.id}>
                    <td>
                      {log.userName} - {log.type} - {moment(log.createDate).format('hh:mm a')} -{' '}
                      {log.note}
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Currency centValue={log.updateValue} />
                    </td>
                  </tr>
                )
              })}
            <tr>
              <td colSpan={2}>
                <div>
                  <hr />
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div>Employees</div>
              </td>
            </tr>
            {totalEmployeeHours.map((employeeHour) => {
              const workType = employeeHour.type
              const hour = Math.floor(employeeHour.totalMinutes / 60)
              const min = employeeHour.totalMinutes % 60

              const shiftLength = `${workType}: ${hour} hrs, ${min} mins`

              return (
                <tr key={employeeHour.id}>
                  <td>{employeeHour.employeeName}</td>
                  <td style={{ textAlign: 'right' }}>{shiftLength}</td>
                </tr>
              )
            })}
            <tr>
              <td colSpan={2}>
                <div>
                  <hr />
                </div>
              </td>
            </tr>
            {showInventorySold && (
              <>
                <tr>
                  <td colSpan={2}>
                    <div>Inventory sold</div>
                  </td>
                </tr>
                {shift.inventorySold.map((i) => {
                  return (
                    <tr key={i.productName}>
                      <td>{i.productName}</td>
                      <td style={{ textAlign: 'right' }}>
                        {i.quantity} {i.unit}s
                      </td>
                    </tr>
                  )
                })}
                <tr>
                  <td colSpan={2}>
                    <div>
                      <hr />
                    </div>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td>Print date</td>
              <td style={{ textAlign: 'right' }}>{moment().format('LLL')}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div>
                  <br />
                  <br />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
