import * as React from 'react'
import { Divider } from 'antd-v4'

interface Props {
  title: string
  className?: string
}

export const CustomDivider = (props: Props) => (
  <Divider orientation='left' plain className={`left-most-aligned-divider ${props.className}`}>
    <span
      style={{
        fontSize: 14,
        fontWeight: 'bold',
        color: '#000000a6',
      }}
    >
      {props.title}
    </span>
  </Divider>
)
