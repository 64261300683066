import * as React from 'react'
import { API } from '@getgreenline/homi-shared'
import { capitalizeFirstLetter, parseErrorMsg } from '../../utilities/helpers'
import { Button, Layout, Empty, Icon, Tag, message } from 'antd'
import { inject } from 'mobx-react'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import '../Dashboard/index.scss'
import { browserHistory } from 'react-router'

interface State {
  companies?: any[]
  mode: 'all' | 'paid' | 'free'
  selectedCompanyId?: number
  showProductsPage: boolean
}

interface Props {
  currentCompanyStore?: CurrentCompanyStore
}

@inject('currentCompanyStore')
export class AdminCompanies extends React.Component<React.PropsWithChildren<Props>, State> {
  constructor(props: {}) {
    super(props)
    this.state = {
      companies: [],
      mode: 'paid',
      showProductsPage: false,
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  async refreshData() {
    try {
      const companies = await API.getAdminCompanies()
      this.setState({ companies: companies.reverse() })
    } catch (error) {
      message.error(parseErrorMsg(error))
    }
  }

  renderModeButton = (mode: 'all' | 'free' | 'paid') => {
    return (
      <Button
        key={mode}
        type={this.state.mode === mode ? 'primary' : 'default'}
        onClick={() => this.setState({ mode })}
        style={{ marginRight: 5 }}
      >
        {capitalizeFirstLetter(mode)}
      </Button>
    )
  }

  render() {
    const companies = this.state.companies ? this.state.companies : []

    let companiesToDisplay: any[] = companies
    if (this.state.mode === 'paid') {
      companiesToDisplay = companies.filter((company) => company.isPaid)
    } else if (this.state.mode === 'free') {
      companiesToDisplay = companies.filter((company) => !company.isPaid)
    }

    let selectedCompany: any | undefined

    if (this.state.selectedCompanyId) {
      selectedCompany = companiesToDisplay.find(
        (company) => company.id === this.state.selectedCompanyId,
      )
    }

    return (
      <>
        <Layout>
          <Layout.Sider
            className='background-shadow'
            width={300}
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              background: '#fff',
              left: 0,
            }}
          >
            <div style={{ padding: 10 }}>
              <Button href='/admin/v2' style={{ marginBottom: 5 }}>
                Try Admin V2!
              </Button>
              <div className='d-flex row ml-1'>
                {this.renderModeButton('paid')}
                {this.renderModeButton('free')}
                <Button href='/admin/externalSources' style={{ marginRight: 5 }}>
                  External
                </Button>
                <Button href='/admin/provinceProducts' className='mt-2'>
                  Province products
                </Button>
                <Button href='/admin/globalCatalog' className='mt-2'>
                  Global Catalog
                </Button>
                <Button href='/admin/organizations' className='mt-2 ml-2'>
                  Organizations
                </Button>
              </div>
              <br />
              {companiesToDisplay.map((company) => {
                return (
                  <div
                    key={company.id}
                    className={`${!!company.bannerMessage && 'text-danger'}`}
                    style={{ paddingTop: 10, paddingBottom: 10, cursor: 'pointer' }}
                    onClick={async () => {
                      browserHistory.push(`/admin/companies/${company.id}`)
                      await this.setState({ selectedCompanyId: undefined })
                      await this.setState({ selectedCompanyId: company.id })
                    }}
                  >
                    <>
                      <div>
                        {!!company.bannerMessage && <Icon type='warning' />} {company.id}:{' '}
                        {company.name}
                      </div>
                      <div>
                        <Tag>{company.province || '-'}</Tag>
                        {company.auditCompleteMode === 'replace' ? (
                          <Tag color='orange'>Audit - replace</Tag>
                        ) : (
                          <Tag color='green'>Audit - relative</Tag>
                        )}
                        {company.inventoryCostOption === 'lastCost' ? (
                          <Tag color='red'>Last cost</Tag>
                        ) : (
                          <Tag color='green'>Weighted average</Tag>
                        )}
                      </div>
                    </>
                  </div>
                )
              })}
            </div>
          </Layout.Sider>
          <Layout style={{ marginLeft: 300, borderLeft: '1px solid', backgroundColor: 'white' }}>
            {this.props.children}
          </Layout>
        </Layout>
      </>
    )
  }
}
