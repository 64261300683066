// Colours referenced from http://www.material-ui.com/#/customization/colors
import { THEME_COLORS } from '@getgreenline/shared'

export const PRIMARY_COLORS = {
  // Brand colours
  primaryGreen: '#01FE01',
  primaryFaded: '#96D196',

  // Text
  primaryFont: '#212529',
}

export const BLUE = {
  actionButtonTextColor: '#3296F7',
  blue50: '#E3F2FD',
  blue100: '#BBDEFB',
  blue200: '#90CAF9',
  blue300: '#64B5F6',
  blue400: '#42A5F5',
  blue500: '#2196F3',
  blue600: '#1E88E5',
  blue700: '#1976D2',
  blue800: '#1565C0',
  blue900: '#0D47A1',
  defaultBlue: '#007AFF',
}

export const BLUEGREY = {
  bluegrey50: '#eceff1',
  bluegrey100: '#cfd8dc',
  bluegrey200: '#b0bec5',
  bluegrey300: '#90a4ae',
  bluegrey400: '#78909c',
  bluegrey500: '#607d8b',
  bluegrey600: '#546e7a',
  bluegrey700: '#455a64',
  bluegrey800: '#37474f',
  bluegrey900: '#263238',
}

export const GREYS = {
  white: '#ffffff',
  black: '#000000',
  transparent: 'rgba(0, 0, 0, 0)',
  fullBlack: 'rgba(0, 0, 0, 1)',
  darkBlack: 'rgba(0, 0, 0, 0.87)',
  lightBlack: 'rgba(0, 0, 0, 0.54)',
  bundledDiscountsBlack: '#1A1A1A',
  bundledDiscountsBadgeBackground: '#D9D9D9',
  minBlack: 'rgba(0, 0, 0, 0.26)',
  faintBlack: 'rgba(0, 0, 0, 0.12)',
  faintestBlack: 'rgba(0, 0, 0, 0.06)',
  fullWhite: 'rgba(255, 255, 255, 1)',
  darkWhite: 'rgba(255, 255, 255, 0.87)',
  lightWhite: 'rgba(255, 255, 255, 0.54)',
  backgroundGrey: 'rgba(0,0,0,.075)',

  grey50: '#FAFAFA',
  grey100: '#F5F5F5',
  grey300: '#E0E0E0',
  grey600: '#757575',
  grey: '#bbbbbb',
  lightGrey: '#dfdfdf',
  veryLightGrey: '#f1f1f1',
  borderGrey: '#c8c8c8',
  reactElementTextBlack: '#43484d',
  borderGreyC4: '#C4C4C4',
}

export const GREEN = {
  green50: '#E8F5E9',
  green100: '#C8E6C9',
  green200: '#A5D6A7',
  green300: '#81C784',
  green400: '#66BB6A',
  green500: '#4CAF50',
  green600: '#43A047',
  green700: '#388E3C',
  green800: '#2E7D32',
  green900: '#1B5E20',
}

export const LIGHTGREEN = {
  lightGreen50: '#f1f8e9',
  lightGreen100: '#dcedc8',
  lightGreen200: '#c5e1a5',
  lightGreen300: '#aed581',
  lightGreen400: '#9ccc65',
  lightGreen500: '#8bc34a',
  lightGreen600: '#7cb342',
  lightGreen700: '#689f38',
  lightGreen800: '#558b2f',
  lightGreen900: '#33691e',
  lightGreenA100: '#ccff90',
  lightGreenA200: '#b2ff59',
  lightGreenA400: '#76ff03',
  lightGreenA700: '#64dd17',
}

export const RED = {
  red50: '#ffebee',
  red100: '#ffcdd2',
  red200: '#ef9a9a',
  red300: '#e57373',
  red400: '#ef5350',
  red500: '#f44336',
  red600: '#e53935',
  red700: '#d32f2f',
  red800: '#c62828',
  red900: '#b71c1c',
  redA100: '#ff8a80',
  redA200: '#ff5252',
  redA400: '#ff1744',
  redA700: '#d50000',
}

export const PAYMENT_COLOURS = {
  cash: '#2e7d32',
  cashBackground: '#e8f5e9',
  credit: '#00838f',
  creditBackground: '#E0F7FA',
  debit: '#1565C0',
  debitBackground: '#e3f2fd',
  other: '#827717',
  otherBackground: '#F9FBE7',
  merrco: '#ff6c2c',
  merrcoBackground: '#ffc0a5',
  global: '#004780',
  globalBackground: '#A4BBCF',
  moneris: '#00B8C9',
  monerisBackground: '#1A3B58',
  birchmount: '#6A8F07',
  birchmountBackground: '#EFFDCB',
  worldpay: '#5E3797',
  worldpayBackground: '#B596E3',
}

const BOOTSTRAP_COLOURS = {
  muted: GREYS.lightBlack,
  btnLight: '#f8f9fa',
  amber: '#ffa000',
  warningBackground: '#fff3cd',
  warningBorder: '#ffeeba',
  warningTextColor: '#856404',
  warningIcon: '#ffc069',
  dangerBackground: '#F8D7DB',
  dangerBorder: '#f0120f',
  dangerTextColor: '#79282E',
}

export const CATEGORY_COLOURS = {
  SEED: '#F44336',
  PLANTS: '#00BCD4',
  DRIED: '#4CAF50',
  FRESH_CANNABIS: '#FF5722',
  EDIBLES_SOLIDS: '#607D8B',
  EDIBLES_NON_SOLIDS: '#03A9F4',
  EXTRACTS_INHALED: '#E91E63',
  EXTRACTS_INGESTED: '#FFC107',
  EXTRACTS_OTHERS: '#673AB7',
  TOPICALS: '#3F51B5',
  PRE_ROLLS: '#8BC34A',
  ACCESSORIES: '#9C27B0',
  BUNDLED_DISCOUNTS: '#D2D96D',
}

export const COLORS = {
  ...GREYS,
  ...GREEN,
  ...LIGHTGREEN,
  ...RED,
  ...PRIMARY_COLORS,
  ...BLUE,
  ...PAYMENT_COLOURS,
  ...BOOTSTRAP_COLOURS,
  ...BLUEGREY,
  ...THEME_COLORS,
}

export const GRAPH_COLORS = [
  BLUE.blue500,
  GREEN.green400,
  BOOTSTRAP_COLOURS.amber,
  RED.red600,
  RED.red300,
  BLUE.blue900,
  BLUEGREY.bluegrey500,
]
