import { THEME_COLORS } from '@getgreenline/shared'
import React from 'react'

type TBlazeAlert = 'success' | 'error' | 'warning' | 'info'

type TReactTooltip = {
  'data-tip'?: boolean
  'data-for'?: string
}

interface Props {
  type: TBlazeAlert
  showIcon?: boolean
  style?: React.CSSProperties
  iconProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> &
    TReactTooltip
}

const getIconPath = (type: TBlazeAlert): string => {
  switch (type) {
    case 'success':
      return require('../../images/blaze-icons/connected-indicator 2.svg').default
    case 'error':
      return require('../../../src/images/blaze-icons/not-configured-indicator 2.svg').default
    case 'warning':
      return require('../../../src/images/blaze-icons/disconnected-indicator 2.svg').default
    case 'info':
      return require('../../../src/images/blaze-icons/info-circle-fill 16px.svg').default
  }
}

export const BlazeAlert = ({
  type,
  showIcon,
  style,
  iconProps,
  children,
}: React.PropsWithChildren<Props>) => {
  const iconPath = showIcon ? getIconPath(type) : undefined

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: THEME_COLORS.BRAND.black,
        borderRadius: 8,
        padding: '12px 16px',
        color: THEME_COLORS.MONO.monoFf,
        ...style,
      }}
    >
      {iconPath && <img src={iconPath} style={{ marginRight: 8 }} {...iconProps} />}

      {children}
    </div>
  )
}
