import React, { ReactElement, useState } from 'react'
import {
  HeadsetFilter,
  ICompanyWithMetaDataContract,
  MetaDataType,
  MetaKeys,
} from '@getgreenline/homi-shared'
import { AdminHeadsetSync } from './AdminHeadsetSync'
import { Switch } from 'antd'

interface Props {
  company: ICompanyWithMetaDataContract<MetaDataType>
  updateCompany: (updateObject: any, isUpdatingMetaData?: boolean) => void
}

const Integrations = ({ company, updateCompany }: Props): ReactElement => {
  const { externalApiAuthToken, province, isBatchTracking, isPaid, isLicensed, metaData } = company
  const {
    canAccessWordPress,
    canAccessLoyalty,
    canAccessInventoryConversions,
    canAccessHeadset,
    canAccessLeafly,
    canAccessPricingGroups,
    canAccessQuickBooks,
  } = company.permissions

  const hasVirtualLocation = metaData[MetaKeys.hasVirtualLocation] ?? false

  const [syncHeadset, setSyncHeadset] = useState(false)

  return (
    <div className='background-shadow p-4'>
      <h5 className='text-muted'>Features and integrations</h5>
      <div className='text-muted'>API auth token: {externalApiAuthToken}</div>
      <br />
      {province === 'AB' && !isBatchTracking && (
        <div className='alert alert-danger'>
          All Alberta stores should have lot tracking enabled!
        </div>
      )}
      <div>
        <span
          className={'badge mr-2 mb-2 ' + (isPaid ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ isPaid: !isPaid })}
        >
          Paying
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (isLicensed ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ isLicensed: !isLicensed })}
        >
          Licensed
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (canAccessWordPress ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ canAccessWordPress: !canAccessWordPress })}
        >
          WordPress
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (canAccessLoyalty ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ canAccessLoyalty: !canAccessLoyalty })}
        >
          Loyalty
        </span>
        <span
          className={
            'badge mr-2 mb-2 ' + (canAccessInventoryConversions ? 'badge-success' : 'badge-light')
          }
          style={{ cursor: 'pointer' }}
          onClick={() =>
            updateCompany({ canAccessInventoryConversions: !canAccessInventoryConversions })
          }
        >
          Conversions
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (canAccessLeafly ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ canAccessLeafly: !canAccessLeafly })}
        >
          Leafly
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (canAccessQuickBooks ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ canAccessQuickBooks: !canAccessQuickBooks })}
        >
          QuickBooks
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (canAccessHeadset ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ canAccessHeadset: !canAccessHeadset })}
        >
          Headset
        </span>
        <span
          className={
            'badge mr-2 mb-2 ' + (canAccessPricingGroups ? 'badge-success' : 'badge-light')
          }
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ canAccessPricingGroups: !canAccessPricingGroups })}
        >
          Pricing Groups
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (isBatchTracking ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() => updateCompany({ isBatchTracking: !isBatchTracking })}
        >
          Lot tracking
        </span>
        <span
          className={'badge mr-2 mb-2 ' + (hasVirtualLocation ? 'badge-success' : 'badge-light')}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            updateCompany(
              {
                metaData: {
                  ...metaData,
                  [MetaKeys.hasVirtualLocation]: !hasVirtualLocation,
                },
              },
              true,
            )
          }
        >
          Has Virtual Locations
        </span>
      </div>
      {canAccessHeadset && (
        <div>
          <span className='mr-2'>Sync Headset options</span>
          <Switch checked={syncHeadset} onChange={setSyncHeadset} />
        </div>
      )}
      {syncHeadset && <AdminHeadsetSync headsetFilter={new HeadsetFilter()} company={company} />}
    </div>
  )
}

export default Integrations
