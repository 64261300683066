import * as React from 'react'
import { Drawer } from 'antd'
import { observer } from 'mobx-react-lite'
import { IAdminExternalSource, ILocation } from '@getgreenline/homi-shared'
import SubscriptionSourceForm from './SubscriptionForm'
import { ISubscription, ISubscriptionContract } from '@getgreenline/webhooks'

interface Props {
  companyId: number
  setIsDrawerOpen: (isDrawerOpen: boolean) => void
  externalSources: IAdminExternalSource[]
  locations: ILocation[]
  onSubmit: (subscription: ISubscription) => Promise<void>
  subscription: ISubscriptionContract
}

export const EditSubscriptionDrawer = observer((props: Props) => {
  const submitSubscription = async (subscription: ISubscription) => {
    await props.onSubmit(subscription)
  }

  return (
    <Drawer
      title={`Edit`}
      maskClosable
      placement='right'
      closable={false}
      width={350}
      onClose={() => {
        props.setIsDrawerOpen(false)
      }}
      visible={true}
    >
      <SubscriptionSourceForm
        companyId={props.companyId}
        locations={props.locations}
        externalSources={props.externalSources}
        onSubmit={submitSubscription}
        existingSubscription={props.subscription}
      />
    </Drawer>
  )
})
