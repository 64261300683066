import {
  IPaymentLine,
  IPaymentQueueLine,
  convertPaymentTypeIdToName,
  PaymentTypeIds,
  PaymentType,
  CreatePayment,
  CreateTransaction,
  IPayment,
  paymentNameFormatter,
} from '@getgreenline/homi-shared'
import { CreateBarcodeValidation } from '@getgreenline/homi-shared/dist/models/Payments/CreatePaymentLine'
import {
  convertCentsToLoyaltyPoints,
  convertLoyaltyPointsToCents,
  LoyaltyModels,
} from '@getgreenline/loyalties'
import { CurrentCompanyStore } from '../stores/CurrentCompanyStore'
import {
  BlazepayEnums,
  BlazepayPaymentOptionModels,
  BlazepayPaymentModels,
  BlazepayAPI,
} from '@getgreenline/payments'
import { message } from 'antd-v4'
import { parseErrorMsg } from './helpers'

export const getTotalProductsToValidate = <T extends IPaymentQueueLine | IPaymentLine>(
  barcodeValidation: CreateBarcodeValidation<T>,
): number => {
  return barcodeValidation.validationLines.filter(
    (item) => item.isValidationRequired && item.validationLine.productId,
  ).length
}

export const paymentHasExistingLoyaltyLine = (payment: CreatePayment) => {
  const loyaltyTransaction = payment.transactions.find(
    (transaction) => transaction.paymentTypeId === PaymentTypeIds.loyalty,
  )
  return loyaltyTransaction !== undefined
}

export const setMaxAllowableLoyaltyInCents = (
  payment: CreatePayment,
  inputLoyaltyInCents: number,
  currentCompanyStore?: CurrentCompanyStore,
) => {
  const creditBalance = payment.customer?.creditBalance || 0
  const loyaltyDiscountPoints = payment.loyaltyDiscountPoints || 0
  const customerLoyaltyBalance = creditBalance - loyaltyDiscountPoints
  const customerLoyaltyInCents = convertLoyaltyPointsToCents(
    customerLoyaltyBalance,
    currentCompanyStore?.loyaltyPointsMultiplier,
  )

  const lineSumExceptLoyalty = payment.transactions.reduce(
    (acc, trx) => (trx.paymentTypeId === PaymentTypeIds.loyalty ? acc : acc + trx.amount),
    0,
  )

  let totalMinusLoyalty = Math.min(payment.total - lineSumExceptLoyalty)
  if (totalMinusLoyalty < 0) {
    totalMinusLoyalty = 0
  }
  const eligibleLoyaltyInCents = Math.min(
    ...[inputLoyaltyInCents, customerLoyaltyInCents, totalMinusLoyalty],
  )
  return eligibleLoyaltyInCents
}

export const getLoyaltyAsCents = (
  creditBalance: number,
  currentCompanyStore: CurrentCompanyStore,
) => {
  const loyaltyInCents = convertLoyaltyPointsToCents(
    creditBalance,
    currentCompanyStore.loyaltyPointsMultiplier,
  )
  return loyaltyInCents
}

export const calculateMaxAllowableLoyaltyAmount = (
  payment: CreatePayment,
  inputLoyaltyAmount: number,
  currentCompanyStore?: CurrentCompanyStore,
) => {
  const creditBalance = payment.customer?.creditBalance || 0
  const loyaltyDiscountPoints = payment.loyaltyDiscountPoints || 0
  const customerCreditBalance = creditBalance - loyaltyDiscountPoints
  const customerLoyaltyInCents = convertLoyaltyPointsToCents(
    customerCreditBalance,
    currentCompanyStore?.loyaltyPointsMultiplier,
  )

  const lineSumExceptLoyalty = payment.transactions.reduce(
    (acc, trx) => (trx.paymentTypeId === PaymentTypeIds.loyalty ? acc : acc + trx.amount),
    0,
  )

  let totalMinusLoyalty = Math.min(payment.total - lineSumExceptLoyalty)

  if (totalMinusLoyalty < 0) {
    totalMinusLoyalty = 0
  }

  const eligibleLoyaltyAmount = Math.min(
    ...[inputLoyaltyAmount, customerLoyaltyInCents, totalMinusLoyalty],
  )

  return eligibleLoyaltyAmount
}

export const setLoyaltyCentsTransaction = (
  loyaltyInCents: number,
  transaction: CreateTransaction,
  loyaltyPointsMultiplier: number,
) => {
  const convertedLoyaltyPoints = convertCentsToLoyaltyPoints(
    loyaltyInCents,
    loyaltyPointsMultiplier,
  )

  const loyaltyTransactionMetadata: LoyaltyModels.ILoyaltyTransactionMetadata = {
    points: convertedLoyaltyPoints,
    loyaltyPointsMultiplier: loyaltyPointsMultiplier,
    centValue: loyaltyInCents,
  }

  transaction.setAmount(loyaltyInCents)
  transaction.setMetadata(JSON.stringify(loyaltyTransactionMetadata))
}

export const paymentTypeFilterOptions: Array<{ value: PaymentType; label: string }> = [
  { value: 'birchmount', label: convertPaymentTypeIdToName(PaymentTypeIds.birchmount) },
  { value: 'cash', label: convertPaymentTypeIdToName(PaymentTypeIds.cash) },
  { value: 'creditCard', label: convertPaymentTypeIdToName(PaymentTypeIds.credit) },
  { value: 'debitCard', label: convertPaymentTypeIdToName(PaymentTypeIds.debit) },
  { value: 'other', label: convertPaymentTypeIdToName(PaymentTypeIds.other) },
  { value: 'etransfer', label: convertPaymentTypeIdToName(PaymentTypeIds.etransfer) },
  { value: 'merrco', label: convertPaymentTypeIdToName(PaymentTypeIds.merrco) },
  { value: 'moneris', label: convertPaymentTypeIdToName(PaymentTypeIds.moneris) },
  { value: 'merrcoDataCandy', label: convertPaymentTypeIdToName(PaymentTypeIds.merrcoDataCandy) },
  { value: 'online', label: convertPaymentTypeIdToName(PaymentTypeIds.online) },
  { value: 'worldpay', label: convertPaymentTypeIdToName(PaymentTypeIds.worldpay) },
  { value: 'loyalty', label: convertPaymentTypeIdToName(PaymentTypeIds.loyalty) },
  { value: 'blazepay', label: convertPaymentTypeIdToName(PaymentTypeIds.blazepay) },
]

export const getActualRemainingAmount = (payment: CreatePayment) => {
  return payment.actualTotal - payment.totalPaid
}

export const formatPaymentOptionNames = (
  payment: IPayment,
  paymentOptions?: Map<
    BlazepayEnums.Provider,
    BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData
  >,
) => {
  return payment.transactions.reduce<{ [key: number]: string }>((acc, trans) => {
    const defaultPaymentName = paymentNameFormatter(trans.paymentTypeId)
    const provider = BlazepayPaymentModels.getProvider(trans.paymentTypeId)
    if (!provider) {
      return { ...acc, [trans.paymentTypeId]: defaultPaymentName }
    }

    const paymentOption = paymentOptions?.get(provider)
    if (!paymentOption) {
      return { ...acc, [trans.paymentTypeId]: defaultPaymentName }
    }

    const paymentOptionName = paymentOption.attributes.posDisplayName || defaultPaymentName
    return {
      ...acc,
      [trans.paymentTypeId]: paymentOptionName,
    }
  }, {})
}

export const formatPaymentOptionNameByTypeId = (
  paymentTypeId: PaymentTypeIds,
  paymentOptions?: Map<
    BlazepayEnums.Provider,
    BlazepayPaymentOptionModels.IPaymentOptionEventOrRestData
  >,
) => {
  const defaultPaymentName = paymentNameFormatter(paymentTypeId)
  const provider = BlazepayPaymentModels.getProvider(paymentTypeId)

  if (!provider) return defaultPaymentName

  const paymentOption = paymentOptions?.get(provider)

  if (!paymentOption) return defaultPaymentName

  return paymentOption.attributes.posDisplayName || defaultPaymentName
}

export const getPaymentOptions = async (companyId: number, locationId: number) => {
  try {
    const merchantId = `blc-${companyId}-${locationId}`

    const { data: paymentOptionsRes } = await BlazepayAPI.getPaymentOptions({
      merchantId,
      filter: {
        posAvailable: true,
        countryCode: BlazepayEnums.CountryCode.CA,
      },
    })

    const paymentOptions = new Map(
      paymentOptionsRes.map((paymentOption) => [paymentOption.attributes.provider, paymentOption]),
    )

    return paymentOptions
  } catch (error) {
    console.error(error)
    message.error(parseErrorMsg(error) || 'An error occurred while fetching payment options')
  }
}

export const getReceiptPaymentOptionNames = async ({
  companyId,
  locationId,
  payment,
  paymentOptionNames,
}: {
  companyId: number
  locationId: number
  payment: IPayment
  paymentOptionNames?: { [key: number]: string }
}): Promise<{ [key: number]: string } | undefined> => {
  if (paymentOptionNames || !payment.completeDate) {
    return paymentOptionNames
  }

  const paymentOptions = await getPaymentOptions(companyId, locationId)
  return formatPaymentOptionNames(payment, paymentOptions)
}
