import { IReactionDisposer, observable, ObservableMap, action } from 'mobx'
import { Subscription } from 'rxjs'
import { appsyncApi } from '../../../appsync/graphql/api'
import { AppSyncEventService } from './AppSyncEventService'
import {
  IAppSyncCallbacks,
  ISubscriptionCallbacks,
} from '../../../appsync/graphql/interfaces/subscription'
import { IEventService } from './interfaces'
import { BlazepayAPI, BlazepayEnums, BlazepayTerminalModels } from '@getgreenline/payments'
import { captureError } from '../../../utilities/logging'
import { parseErrorMsg } from '../../../utilities/helpers'

export class TerminalEventService extends AppSyncEventService implements IEventService {
  subscription?: Subscription
  private disposer?: IReactionDisposer

  @observable terminals =
    observable.map<BlazepayEnums.Provider, BlazepayTerminalModels.ITerminalEventOrRestData>()

  constructor(
    private merchantId: string,
    private terminalId: string,
    private syncPaymentOptions: VoidFunction,
  ) {
    super()
  }

  private appSyncCallback(): IAppSyncCallbacks<BlazepayTerminalModels.ITerminalSubscriptionResponseContract> {
    return {
      next: ({ data }) => {
        const eventTerminal = data.updatedTerminal.data
        this.mapResources(this.terminals, eventTerminal)
        this.syncPaymentOptions()
      },
      error: (error) => {
        captureError(parseErrorMsg(error) || 'terminal_event.error', error, false)
      },
    }
  }

  subscribe<T>(props?: ISubscriptionCallbacks<T>) {
    this.reactToAppSyncConnectionChange()
    this.subscription = appsyncApi
      .subscribeFor(this.teardown)
      .terminalEvents(this.merchantId, this.terminalId, this.appSyncCallback())

    return props?.after()
  }

  private teardown = () => {
    this.disposer?.()
  }

  private reactToAppSyncConnectionChange() {
    this.disposer = super.setDisposer(this.disposer, async (connectionState) => {
      await super.fetchRecentData(connectionState, this.keepDataUpToDate)
    })
  }

  private keepDataUpToDate = async () => {
    const terminalRes = await BlazepayAPI.getTerminals({
      merchantId: this.merchantId,
      filter: {
        deviceId: this.terminalId,
      },
    })
    this.mapResources(this.terminals, terminalRes.data)
    this.syncPaymentOptions()
  }

  @action
  private mapResources<T extends BlazepayTerminalModels.ITerminalEventOrRestData>(
    map: ObservableMap<BlazepayEnums.Provider, T>,
    data: T | T[],
  ) {
    const deserializedData = super.deserializeData<T>(data)

    if (Array.isArray(deserializedData)) {
      deserializedData.forEach((item) => this.mapSingleSource(map, item))
    } else {
      this.mapSingleSource(map, deserializedData)
    }
  }

  private mapSingleSource<T extends BlazepayTerminalModels.ITerminalEventOrRestData>(
    map: ObservableMap<BlazepayEnums.Provider, T>,
    item: T,
  ) {
    if (item.attributes.isDeleted === true) {
      map.delete(item.attributes.provider)
    } else {
      map.set(item.attributes.provider, item)
    }
  }
}
