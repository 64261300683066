import { ITransaction } from '@getgreenline/homi-shared'

export const paymentConverter = {
  serializeForTransaction: (
    offlinePaymentId: string,
    transaction: ITransaction,
    terminalId: string,
    currentUserId: string,
  ) => {
    return {
      deviceId: terminalId,
      offlinePaymentId,
      transactionAmount: transaction.amount,
      currentShiftId: currentUserId,
    }
  },
}
