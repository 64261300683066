import { appsyncApi } from '../../../appsync/graphql/api'
import {
  IAppSyncCallbacks,
  ISubscriptionCallbacks,
} from '../../../appsync/graphql/interfaces/subscription'
import { parseErrorMsg } from '../../../utilities/helpers'
import { captureError } from '../../../utilities/logging'
import { AppSyncEventService } from './AppSyncEventService'
import { IEventService } from './interfaces'
import { Subscription } from 'rxjs'
import {
  BlazepayAPI,
  BlazepayEnums,
  BlazepayPaymentModels,
  BlazepayRefundModels,
} from '@getgreenline/payments'
import { API, TransactionContract, ITransaction, CreatePayment } from '@getgreenline/homi-shared'
import { IReactionDisposer, observable, when } from 'mobx'
import { refundConverter } from '../converters/refundConverter'
import { v4 } from 'uuid'

export class RefundEventService extends AppSyncEventService implements IEventService {
  subscription?: Subscription
  private disposer?: IReactionDisposer

  @observable blazeRefund?: BlazepayRefundModels.IRefundData
  @observable waitingForResponse = true

  constructor(private merchantId: string, private terminalId: string) {
    super()
  }

  private appSyncCallback(): IAppSyncCallbacks<
    BlazepayPaymentModels.IPaymentV2SubscriptionContract<
      BlazepayEnums.RequestType.PAYMENTS | BlazepayEnums.RequestType.REFUNDS
    >
  > {
    return {
      next: ({ data }) => {
        const eventData = data.updatedPaymentV2.data
        if (eventData.attributes.resource.resourceType === BlazepayEnums.RequestType.REFUNDS) {
          const convertedRefundData = this.convertPaymentV2EventToRefund(eventData)
          this.setRefund(convertedRefundData)
        }
      },
      error: (error) => {
        captureError(parseErrorMsg(error) || 'refund_event.error', error, false)
      },
    }
  }

  subscribe<T>(props: ISubscriptionCallbacks<T>) {
    this.resetRefund()
    this.reactToAppSyncConnectionChange()

    this.subscription = appsyncApi
      .subscribeFor(this.teardown)
      .paymentV2Events(
        this.merchantId,
        this.terminalId,
        this.blazeRefund?.id,
        this.appSyncCallback(),
      )

    return props.after().finally(() => {
      this.subscription?.unsubscribe() // always unsubscribe after the refund is done
    })
  }

  private teardown = () => {
    this.disposer?.()
    this.resetRefund()
  }

  private async initiateRefund({
    paymentRes,
    refundRes,
    currentUserId,
    reason,
    provider,
    transaction,
  }: {
    paymentRes?: BlazepayPaymentModels.IPaymentData<
      BlazepayEnums.RequestType.PAYMENTS | BlazepayEnums.EventRequestType.PAYMENT_EVENTS
    >
    refundRes?: BlazepayRefundModels.IRefundData
    currentUserId: string
    reason: string | null
    provider: BlazepayEnums.Provider
    transaction: ITransaction
  }) {
    const { companyId } = super.parseMerchantId(this.merchantId)

    const payload = refundConverter.serializeForRefund({
      paymentRes,
      refundRes,
      deviceId: this.terminalId,
      currentUserId,
      reason,
      transaction,
    })

    const initiatedBlazeRefund = await BlazepayAPI.processRefundTransaction({
      idempotencyKey: v4(),
      merchantId: this.merchantId,
      providerName: provider,
      payload,
    })

    /**
     * store the initiated refund in metadata so when a budtender refreshes the page during refund, we can still use this to continue the refund process
     */
    await API.updateTransactionById(companyId, transaction.id, {
      ...new TransactionContract(transaction),
      metadata: JSON.stringify(initiatedBlazeRefund.data),
    })

    return initiatedBlazeRefund
  }

  private async preprocessRefund(
    refundPayment: CreatePayment,
    provider: BlazepayEnums.Provider,
    transaction: ITransaction,
    currentUserId: string,
  ) {
    if (!transaction.metadata) {
      throw new Error('Transaction metadata is not found')
    }

    const parsedMetadata = JSON.parse(transaction.metadata)

    switch (parsedMetadata.type) {
      case BlazepayEnums.RequestType.PAYMENTS:
      case BlazepayEnums.EventRequestType.PAYMENT_EVENTS: {
        const initiatedRefund = await this.initiateRefund({
          paymentRes: parsedMetadata,
          currentUserId,
          reason: refundPayment.notes,
          provider,
          transaction,
        })

        return initiatedRefund.data
      }
      case BlazepayEnums.RequestType.REFUNDS:
      case BlazepayEnums.EventRequestType.REFUND_EVENTS: {
        const { attributes, id } = parsedMetadata
        const existingRefundData = await this.fetchRefund(
          attributes.provider,
          attributes.paymentId,
          id,
        )

        if (existingRefundData) {
          const shouldRetry = [
            BlazepayRefundModels.RefundStatus.FAILED,
            BlazepayRefundModels.RefundStatus.UNKNOWN,
            existingRefundData.attributes.status,
          ]

          if (!shouldRetry) {
            return existingRefundData
          }

          const retriedBlazeRefund = await this.initiateRefund({
            refundRes: existingRefundData,
            currentUserId,
            reason: refundPayment.notes,
            provider,
            transaction,
          })

          return retriedBlazeRefund.data
        }

        const initialBlazeRefund = await this.initiateRefund({
          refundRes: existingRefundData,
          currentUserId,
          reason: refundPayment.notes,
          provider,
          transaction,
        })

        return initialBlazeRefund.data
      }
      default:
        throw new Error('Invalid refund method. Please select a different method.')
    }
  }

  async processRefundTransaction(
    refundPayment: CreatePayment,
    provider: BlazepayEnums.Provider,
    transaction: ITransaction,
    currentUserId: string,
  ) {
    return this.subscribe({
      after: async () => {
        const preprocessedRefundData = await this.preprocessRefund(
          refundPayment,
          provider,
          transaction,
          currentUserId,
        )
        this.setRefund(preprocessedRefundData)

        await when(() => this.waitingForResponse === false)

        if (!this.blazeRefund) {
          throw new Error('Failed to process refund')
        }

        BlazepayRefundModels.handleRefundStatus(this.blazeRefund)

        const newRefund = this.blazeRefund

        const { companyId, locationId } = super.parseMerchantId(this.merchantId)
        const refundTransaction = await API.refundTransaction(
          companyId,
          locationId,
          transaction.paymentId,
          transaction.id,
          {
            terminalInvoiceId: null,
            metadata: JSON.stringify(newRefund),
          },
        )

        return refundTransaction
      },
    })
  }

  private setRefund(data: BlazepayRefundModels.IRefundData) {
    const sanitizedData = super.deserializeData<BlazepayRefundModels.IRefundData>(data)

    /**
     * only set the transaction if it doesn't exist or it exists and the ids match
     */
    if (!this.blazeRefund || this.blazeRefund.id === sanitizedData.id) {
      this.blazeRefund = sanitizedData
      const finalStatus = BlazepayRefundModels.finalRefundStatus.includes(data.attributes.status)
      this.waitForResponse(!finalStatus)
    }
  }

  private convertPaymentV2EventToRefund = (
    paymentV2EventData: BlazepayPaymentModels.IPaymentV2EventData<BlazepayEnums.RequestType.REFUNDS>,
  ): BlazepayRefundModels.IRefundData => {
    const { extraContent } = paymentV2EventData.attributes.resource.providerInfo

    return {
      id: paymentV2EventData.id,
      type: paymentV2EventData.attributes.resource.resourceType,
      attributes: {
        merchantId: paymentV2EventData.attributes.merchantId,
        provider: paymentV2EventData.attributes.provider,
        employeeId: paymentV2EventData.attributes.resource.employeeId,
        requestedAmount: paymentV2EventData.attributes.resource.requestedAmount,
        refundedAmount: paymentV2EventData.attributes.resource.chargedAmount,
        status: paymentV2EventData.attributes.resource.status,
        refundType: paymentV2EventData.attributes.resource.type,
        failReason: paymentV2EventData.attributes.resource.statusReason,
        createdAt: paymentV2EventData.attributes.resource.auditable.createdAt,
        updatedAt: paymentV2EventData.attributes.resource.auditable.updatedAt,
        refundedAt: paymentV2EventData.attributes.resource.auditable.refundedAt,
        paymentId: paymentV2EventData.attributes.resource.originalPaymentId,
        externalPaymentId: '',
        extraContent:
          extraContent !== null && typeof extraContent === 'string'
            ? JSON.parse(extraContent)
            : null,
      },
    }
  }

  private waitForResponse(waitingForResponse: boolean) {
    this.waitingForResponse = waitingForResponse
  }

  private resetRefund() {
    this.waitForResponse(true)
    this.blazeRefund = undefined
  }

  private async fetchRefund(provider: BlazepayEnums.Provider, paymentId: string, refundId: string) {
    return BlazepayAPI.getRefundTransactions({
      merchantId: this.merchantId,
      providerName: provider,
      filter: { paymentId },
    }).then((res) => res.data.find((refund) => refund.id === refundId))
  }

  private reactToAppSyncConnectionChange() {
    this.disposer = super.setDisposer(this.disposer, async (connectionState) => {
      await super.fetchRecentData(connectionState, this.keepDataUpToDate)
    })
  }

  keepDataUpToDate = async () => {
    if (this.blazeRefund) {
      const { attributes, id } = this.blazeRefund
      const recentRefund = await this.fetchRefund(attributes.provider, attributes.paymentId, id)

      if (recentRefund) {
        this.setRefund(recentRefund)
      }
    }
  }
}
