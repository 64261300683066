import * as React from 'react'
import { Link } from 'react-router'
import { inject, observer } from 'mobx-react'
import { CurrentUserStore } from '../stores/CurrentUserStore'
import { FormError } from '../components/FormError'
import { Routes } from '../constants/Routes'

interface Props {
  currentUserStore?: CurrentUserStore
  location?: any
}

interface State {
  isVerified?: boolean
  errorMessage?: string
}

@inject('currentUserStore')
@observer
export class VerifyEmail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const token = this.props.location.query.token
    this.props
      .currentUserStore!.verifyEmail(token)
      .then(() => {
        this.setState({
          isVerified: true,
        })
      })
      .catch((error) => {
        this.setState({
          isVerified: false,
          errorMessage: error.response.data.message,
        })
      })
  }

  render() {
    let body: JSX.Element
    if (this.state.isVerified === undefined) {
      body = <div>Checking link...</div>
    } else if (this.state.isVerified) {
      body = (
        <div>
          <FormError status='success' message='Your email has been successfully verified' />
          <br />
          <p>
            Click <Link to={Routes.LOGIN}>here</Link> to log in
          </p>
        </div>
      )
    } else {
      body = (
        <div>
          <FormError message={this.state.errorMessage} />
        </div>
      )
    }

    return (
      <div className='container pt-3 pb-3'>
        <h1>Verify your email</h1>
        <hr />
        {body}
        <p>
          Click <Link to='/'>here</Link> to go back to the homepage
        </p>
      </div>
    )
  }
}
